/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-06 15:03:04
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, notification, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './accountManagement.module.css'
import { getEditNameSubmit } from './accountManagementSlice'

interface editUserNameModalProps {
  name?: any
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
  refresh: () => void
}
export const EditUserNameModal = ({
  name,
  id,
  visible,
  onCancel,
  onOk,
  refresh,
}: editUserNameModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  useEffect(() => {
    if (name) {
      form.setFieldsValue({
        name: name,
      })
    }
  }, [name])

  return (
    <Modal
      title='修改姓名'
      width={600}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          console.log(values, 'values')
          dispatch(
            getEditNameSubmit({
              ...detail,
              ...values,
              userId: id,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              refresh && refresh()
              form.resetFields()
            })
        }}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
      >
        <Form.Item
          label='姓名'
          name='name'
          style={{ marginRight: '55px' }}
          required
        >
          <Input style={{ width: '300px' }} maxLength={10} autoComplete='off' />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '40px',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
