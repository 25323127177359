/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 15:11:26
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 09:30:16
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { getWorkOrderStatus } from '../../../models/workOrder'

type ActionType = 'lookDetail' | 'lookPhoto' | 'delete'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '工单编号',
      dataIndex: 'id',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.id ? r.id : '-'
      },
    },
    {
      title: '审批部门',
      dataIndex: 'departName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.departName ? r.departName : '-'
      },
    },

    {
      title: '申请人',
      dataIndex: 'submiter',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.submiter ? r.submiter : '-'
      },
    },
    {
      title: '紧急程度',
      dataIndex: 'ermerge',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.ermerge ? r.ermerge : '-'
      },
    },
    {
      title: '需求主题',
      dataIndex: 'topics',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.topics ? r.topics : '-'
      },
    },
    {
      title: '需求日期',
      dataIndex: 'sheetTime',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.sheetTime
          ? moment(r?.sheetTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '需求位置',
      dataIndex: 'location',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.location ? r?.location : '-'
      },
    },
    {
      title: '需求描述',
      dataIndex: 'describes',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.describes ? r?.describes : '-'
      },
    },
    {
      title: '照片/视频',
      dataIndex: 'urls',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('lookPhoto', u)}
              style={{ marginRight: '4px' }}
            >
              查看
            </a>
          </>
        )
      },
    },
    {
      title: '提交时间',
      dataIndex: 'updateTime',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.updateTime
          ? moment(r?.updateTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.status?.toString() ? getWorkOrderStatus( r.status?.toString()) :"-"
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('lookDetail', t)
              }}
            >
              查看详情
            </a>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('delete', t)
              }}
            >
              删除
            </a>
          </>
        )
      },
    },
  ]
}
