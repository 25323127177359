import { DatePicker, Form, Modal, Row } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { DateTimeFormat } from '../../models/datetime'
import React from 'react'

export const TimeModal = (props: {
    visible: boolean
    onOk: () => void
    onCancel: () => void
    onValueChange: (v: any) => void
}) => {
    const { RangePicker } = DatePicker

    const [form] = Form.useForm()

    const [startTime, setStartTime] = useState<any>()

    const [endTime, setEndTime] = useState<any>()

    const [value, setValue] = useState<any>()
    return (
        <>
            <Modal
                width={400}
                centered
                title='自定义时间查询'
                visible={props.visible}
                onOk={() => {
                    props.onOk()
                    props.onValueChange({
                        startTime: startTime,
                        endTime: endTime,
                    })
                    form.resetFields()
                    // setValue(null)
                }}
                onCancel={() => {
                    props.onCancel()
                    form.resetFields()
                    // setValue(null)
                }}
            >
                <Form form={form} autoComplete='off'>
                    <Row gutter={11} wrap={false} style={{ height: '40px' }}>
                        <RangePicker
                            value={value}
                            onChange={(v) => {
                                console.log(v, 'query?.start & query?.end')

                                setStartTime(
                                    moment(v?.[0]).format(DateTimeFormat)
                                )
                                setEndTime(
                                    moment(v?.[1]).format(DateTimeFormat)
                                )
                                setValue(v)
                            }}
                        />
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
