/*
 * @Descripttion:
 * @version:
 * @Author: sujun
 * @email: 1373842098@qq.com
 * @Date: 2021-12-13 16:35:36
 * @LastEditors: sj
 * @LastEditTime: 2022-06-13 16:00:39
 */
import { ConfigProvider, notification } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Login } from '../features/login/Login'
import { OAuthorProxy } from '../features/login/OAuthorProxy'
import { DefaultLayout } from '../layouts/DefaultLayout'
import { ErrorCode, NoErrorState } from '../models/error'
import { DefaultTheme } from '../theme/Default'
import { ThemeContext } from '../theme/ThemeContext'
import './App.less'
import {
  selectCredentials,
  selectErrorState,
  setErrorState,
} from './applicationSlice'
import { ThirdReportSearch } from "../features/thirdReportSearch/thirdReportSearch";
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn')

function App(): ReactElement {
  const dispatch = useDispatch()

  const credentials = useSelector(selectCredentials)

  const errorState = useSelector(selectErrorState)

  useEffect(() => {
    if (errorState.code !== ErrorCode.Ok) {
      notification.error({ message: errorState.message, duration: 3 })
      dispatch(setErrorState(NoErrorState))
    }
  }, [dispatch, errorState])

  return (
    <ConfigProvider locale={zhCN}>
      <ThemeContext.Provider value={DefaultTheme}>
        <Switch>
          {/* 报告查询（小程序跳转第三方） */}
          <Route exact path={'/thirdReportSearch'}>
            <ThirdReportSearch />
          </Route>
          <Route path={['/login/callback', '/userCenter/callback', '/authorization/callback']}>
            <OAuthorProxy />
          </Route>
          <Route path='*'>
            {credentials ? <DefaultLayout /> : <Login qrcodeEnabled={true} />}
          </Route>

        </Switch>
      </ThemeContext.Provider>
    </ConfigProvider>
  )
}

export default App
