

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import EChartsReact from 'echarts-for-react'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'

export interface MultipleYLineProps {
  lineDataOne: []
  lineDataTwo: []
  lineDataThree: []
  lineDataFour: []
  lineDataFive: []
  style?: React.CSSProperties
}

export const MultipleYLine: React.FC<MultipleYLineProps> = ({
  lineDataOne,
  lineDataTwo,
  lineDataThree,
  lineDataFour,
  lineDataFive,
  style,
}): ReactElement => {
  const [MultipleYOption, setMultipleYOption] = useState<any>();
  const [mouthData] = useState([
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ]);
  const [weekData] = useState([
    "周一",
    "周二",
    "周三",
    "周四",
    "周五",
    "周六",
    "周日",
  ]);


  useEffect(() => {
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          console.log(params[0]);
          let str = ''
          for(let i = 0; i < params?.length; i++) {
            str += `${params[i]?.marker}<span style="padding: 0 8px">${params[i]?.seriesName}</span><span style="padding: 0 10px 0 0">${params[i]?.value}</span>${params[i]?.data?.percent}%<br/>`
          }
          return str 
        }
      },
      legend: {
        data: ['新增用户', '新增就诊人', '在线预约数', '义诊预约数', '在线客服咨询']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: lineDataOne?.map((v: any) => v.key)
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '新增用户',
          type: 'line',
          smooth: true,
          data: lineDataOne?.map((v: any) => { return { value: v.value, percent: v.percent }})
        },
        {
          name: '新增就诊人',
          type: 'line',
          smooth: true,
          data: lineDataTwo?.map((v: any) => { return { value: v.value, percent: v.percent } })
        },
        {
          name: '在线预约数',
          type: 'line',
          smooth: true,
          data: lineDataThree?.map((v: any) => { return { value: v.value, percent: v.percent } })
        },
        {
          name: '义诊预约数',
          type: 'line',
          smooth: true,
          data: lineDataFour?.map((v: any) => { return { value: v.value, percent: v.percent } })
        },
        {
          name: '在线客服咨询',
          type: 'line',
          smooth: true,
          data: lineDataFive?.map((v: any) => { return { value: v.value, percent: v.percent } })
        }
      ]
    };
    setMultipleYOption(option);
  }, [
    lineDataOne,
    lineDataTwo,
    lineDataThree,
    lineDataFour,
    lineDataFive
  ]);
  return (
    <>
      {MultipleYOption && (
        <EChartsReact option={MultipleYOption} style={style}></EChartsReact>
      )}
    </>
  );
}

