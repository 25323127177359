/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:44:28
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 16:51:03
 */

import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface HealthListPrams {
  current: number
  size: number
}

// 列表
export function healthList(body: HealthListPrams): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/queryList`,
    method: 'POST',
    body,
  }
}

export function healthHotList(body: HealthListPrams): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/visit`,
    method: 'POST',
    body,
  }
}

// 详情
export function healthDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/detail`,
    method: 'GET',
    params: { id },
  }
}

export interface HealthEditPrams {
  knowledgeImg?: string
  type?: string
  title?: string
  id?: string
  knowledgeVideo?: string
  content?: any
}

// 保存
export function healthEditSubmit(body: HealthEditPrams): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/saveOrUpdate`,
    method: 'POST',
    body,
  }
}

export interface changeStateParams {
  id?: any
  state?: any
}

export function changeStateSubmit(params: changeStateParams): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/changeStateSingle`,
    method: 'GET',
    params,
  }
}

export interface deleteParams {
  id?: any
}

export function deleteSubmit(params: deleteParams): Request {
  return {
    url: `${API_PREFIX}/adminHealthKnowledge/deleteLogicSingle`,
    method: 'GET',
    params,
  }
}
