/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 10:49:34
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../app/store'
import { sendAsync } from '../../../app/applicationSlice'
import * as DT from '../../../services/demandWorkOrder'

// 列表
export const getDemandWorkOrderList = createAsyncThunk<
  void,
  DT.DemandWorkOrderPrams,
  RootThunkApi<void>
>('clinic/getHealthList', async (params, api) => {
  return sendAsync(DT.demandWorkOrderList(params), api)
})

// 详情
export const getDemandWorkOrderDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getDemandWorkOrderDetail', async (params, api) => {
  return sendAsync(DT.demandWorkOrderDetail(params), api)
})

//删除
export const deleteListFn = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
  >('clinic/deleteList', async (params, api) => {
    return sendAsync(DT.deleteList(params), api)
})



