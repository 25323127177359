/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-18 11:53:50
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as service from '../../services/freePatients'
import { freePatientQueryParams } from '../../services/freePatients'
import * as DT from '../../services/freePatients'

interface FreePatientState {
  FreePatientData: []
  Total: 0
  Current: 1
  pageLoading: false
}

const initialState: FreePatientState = {
  FreePatientData: [],
  Total: 0,
  Current: 1,
  pageLoading: false,
}

const freePatientSlice = createSlice({
  name: 'freePatientSlice',
  initialState,
  reducers: {
    setData(state, action) {
      state.FreePatientData = action.payload
    },
    setTotal(state, action) {
      state.Total = action.payload
    },
    setCurrent(state, action) {
      state.Current = action.payload
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload
    },
  },
})

export const {
  setData,
  setTotal,
  setCurrent,
  setPageLoading,
} = freePatientSlice.actions

export const selectFreePatientData = (state: RootState) =>
  state.freePatients.FreePatientData

export const selectTotal = (state: RootState) => state.freePatients.Total

export const selectCurrent = (state: RootState) => state.freePatients.Current

export const selectPageLoading = (state: RootState) =>
  state.freePatients.pageLoading

export function getFreePatientList(params: freePatientQueryParams): RootThunk {
  return api(service.freePatientList(params), (data: any, dispatch) => {
    dispatch(setData(data.records))
    dispatch(setTotal(data.total))
    dispatch(setPageLoading(false))
  })
}

// 义诊详情
export const getFreePatientsDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientsDetail', async (params, api) => {
  return sendAsync(DT.freePatientsDetail(params), api)
})

// 义诊详情表格
export const getFreeDetailTable = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreeDetailTable', async (params, api) => {
  return sendAsync(DT.freeDetailTable(params), api)
})

// 义诊详情表格打印
export const getFreeDetailTablePrint = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/getFreeDetailTablePrint',
  async (params, api) => {
    return sendAsync(DT.freeDetailTablePrint(params), api)
  }
)

// 义诊详情表格
export const getFreePrintData = createAsyncThunk<
  void,
  DT.printParams,
  RootThunkApi<void>
>('clinic/getFreePrintData', async (params, api) => {
  return sendAsync(DT.printData(params), api)
})

// 义诊编辑详情
export const getFreePatientDetailModal = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientDetailModal', async (params, api) => {
  return sendAsync(DT.freePatientDetailModal(params), api)
})

// 发布
export const getFreePatientsPublish = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientsPublish', async (params, api) => {
  return sendAsync(DT.freePatientsPublish(params), api)
})

// 撤回
export const getFreePatientsRecall = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientsRecall', async (params, api) => {
  return sendAsync(DT.freePatientsRecall(params), api)
})

// 获取上午下午时间
export const getNum = createAsyncThunk<void, DT.numParams, RootThunkApi<void>>(
  'clinic/getNum',
  async (params, api) => {
    return sendAsync(DT.num(params), api)
  }
)

// 发送短信 、重发短信
export const getSenFreeTreatmentSms = createAsyncThunk<
  void,
  DT.senFreeTreatmentSmsParams,
  RootThunkApi<void>
>('clinic/getSenFreeTreatmentSms', async (params, api) => {
  return sendAsync(DT.senFreeTreatmentSms(params), api)
})

// 完成
export const getFreePatientsFinish = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientsFinish', async (params, api) => {
  return sendAsync(DT.freePatientsFinish(params), api)
})

// 完成义诊情况填写
export const getFreePatientsFinishSubmit = createAsyncThunk<
  void,
  DT.freePatientsFinishSubmitParams,
  RootThunkApi<void>
>('clinic/getFreePatientsFinishSubmit', async (params, api) => {
  return sendAsync(DT.freePatientsFinishSubmit(params), api)
})

// 义诊情况详情
export const getFreeDetail = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/getFreeDetail',
  async (params, api) => {
    return sendAsync(DT.freeDetail(params), api)
  }
)

// 删除
export const getFreePatientsDelete = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getFreePatientsDelete', async (params, api) => {
  return sendAsync(DT.freePatientsDelete(params), api)
})

// 提交
export const getFreePatientsSubmit = createAsyncThunk<
  void,
  DT.FreePatientsSubmitParams,
  RootThunkApi<void>
>('clinic/getFreePatientsDetail', async (params, api) => {
  return sendAsync(DT.freePatientsSubmit(params), api)
})

// 下载
export const getFreePatientDownLoad = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/getFreePatientDownLoad',
  async (params, api) => {
    return sendAsync(DT.freePatientDownLoad(params), api)
  }
)

// 义诊导出 
export const getPageExoprt = createAsyncThunk<
  void,
  any,
  RootThunkApi
  >('adminFreeTreatment/pageexport', async (params, api) => {
  return sendAsync(DT.getPageExportFn(params), api)
})
// 详情导出 
export const getDetailExport = createAsyncThunk<
  void,
  any,
  RootThunkApi
  >('adminFreeTreatment/detailexport', async (params, api) => {
    return sendAsync(DT.getDetailExportFn(params), api)
})
// 详情取消 
export const getRecallByApp = createAsyncThunk<
  void,
  number,
  RootThunkApi
  >('adminFreeTreatment/recallByApp', async (params, api) => {
    return sendAsync(DT.getRecallByAppFn(params), api)
})

export default freePatientSlice.reducer
