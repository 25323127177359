/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-09 20:47:13
 * @LastEditors: linxi
 * @LastEditTime: 2022-07-08 14:41:17
 */
import { CaretDownFilled } from "@ant-design/icons";
import { DatePicker, Form, Input, Select } from "antd";
import moment from "moment";
import React, { ReactElement, useContext } from "react";
import { DateTimeFormat } from "../../models/datetime";
import { ThemeContext } from "../../theme/ThemeContext";

export enum DateRangeType {
  Today = 0,
  Yesterday = 1,
  ThisWeek = 2,
  ThisMonth = 3,
  NextMonth = 4,
  ThisYear = 5,
  Range = 6,
  Auth = 7,
  Invaild = 8,
  RencentMunth = 9,
  RecentQuarter = 10,
  HalfAYear = 11,
  MoreYear = 12,
}

const DateRangeTypes = [
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.Range,
];


const DateRangeTypeNames = [
  "今天",
  "昨天",
  "本周",
  "本月",
  "下月",
  "今年",
  "自选",
  "全部",
  "已失效",
  "近一个月",
  "近三个月",
  "近六个月",
  "大于六个月",
];

export const RecentTimes = [
  DateRangeType.Auth,
  DateRangeType.Invaild,
  DateRangeType.RencentMunth,
  DateRangeType.RecentQuarter,
  DateRangeType.HalfAYear,
  DateRangeType.MoreYear,
];
export const RecentTime = [
  DateRangeType.Today,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.Range,
];

export const NotificationDateRangeTypes = [
  DateRangeType.Today,
  DateRangeType.ThisWeek,
  DateRangeType.ThisMonth,
  DateRangeType.ThisYear,
  DateRangeType.Range,
]

interface DateSelectProps {
  labelPrefix: string;
  namePrefix: string;
  placeholder?: string;
  value?: DateRangeType;
  onChange?: (value?: DateRangeType) => void;
  options?: DateRangeType[];
  initialValue?: DateRangeType;
  style?: React.CSSProperties;
  allowClear?: boolean;
}

export function getDateRange(
  type?: DateRangeType
): [string | undefined, string | undefined] {
  let head = undefined;
  let tail = undefined;
  switch (type) {
    case DateRangeType.Today:
      head = moment().startOf("day").format(DateTimeFormat);
      tail = moment().endOf("day").format(DateTimeFormat);
      break;
    case DateRangeType.Yesterday:
      head = moment().subtract(1, "day").startOf("day").format(DateTimeFormat);
      tail = moment().subtract(1, "day").endOf("day").format(DateTimeFormat);
      break;
    case DateRangeType.ThisWeek:
      head = moment().startOf("week").format(DateTimeFormat);
      tail = moment().endOf("week").format(DateTimeFormat);
      break;
    case DateRangeType.ThisMonth:
      head = moment().startOf("month").format(DateTimeFormat);
      tail = moment().endOf("month").format(DateTimeFormat);
      break;
    case DateRangeType.NextMonth:
      head = moment().add(1, "month").startOf("month").format(DateTimeFormat);
      tail = moment().add(1, "month").endOf("month").format(DateTimeFormat);
      break;
    case DateRangeType.ThisYear:
      head = moment().startOf("year").format(DateTimeFormat);
      tail = moment().endOf("year").format(DateTimeFormat);
      break;
    case DateRangeType.Auth:
      head = undefined;
      tail = undefined;
      break;
    case DateRangeType.Invaild:
      head = undefined;
      tail = moment().format(DateTimeFormat);
      break;
    case DateRangeType.RencentMunth:
      head = moment().format(DateTimeFormat);
      tail = moment().add(1, "month").endOf("month").format(DateTimeFormat);
      break;
    case DateRangeType.RecentQuarter:
      head = moment().format(DateTimeFormat);
      tail = moment().add(3, "month").endOf("month").format(DateTimeFormat);
      break;
    case DateRangeType.HalfAYear:
      head = moment().format(DateTimeFormat);
      tail = moment().add(6, "month").endOf("month").format(DateTimeFormat);
      break;
    case DateRangeType.MoreYear:
      head = moment().add(6, "month").endOf("month").format(DateTimeFormat);
      tail = undefined;
      break;
  }
  return [head, tail];
}

export const DateRangeFieldName = "_dateRange";

export const DateSelect = ({
  labelPrefix,
  namePrefix,
  placeholder,
  value,
  onChange,
  options = DateRangeTypes,
  initialValue,
  allowClear = true,
  ...othersProps
}: DateSelectProps): ReactElement => {
  const theme = useContext(ThemeContext);

  const headName = `${namePrefix}Head`;
  const tailName = `${namePrefix}Tail`;
  const select = (
    setFieldsValue: (values: Record<string, unknown>) => void,
    type?: DateRangeType
  ) => {
    const [head, tail] = getDateRange(type);
    setFieldsValue({
      [headName]: head,
      [tailName]: tail,
    });
    onChange && onChange(type);
  };

  return (
    <>
      <Form.Item style={{ display: "none" }} name={headName}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item style={{ display: "none" }} name={tailName}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(pv, cv) =>
          pv[DateRangeFieldName] !== cv[DateRangeFieldName]
        }
      >
        {({ setFieldsValue }) => (
          <Form.Item
            noStyle
            name={DateRangeFieldName}
            initialValue={initialValue}
          >
            <Select
              {...othersProps}
              value={value}
              placeholder={placeholder}
              allowClear={allowClear}
              suffixIcon={<CaretDownFilled style={{ color: theme.tc3 }} />}
              onChange={(v) => select(setFieldsValue, v)}
            >
              {options.map((t) => (
                <Select.Option key={t} value={t}>
                  {`${labelPrefix}${DateRangeTypeNames[t]}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle dependencies={[DateRangeFieldName]}>
        {({ getFieldValue, setFieldsValue }) =>
          getFieldValue(DateRangeFieldName) === DateRangeType.Range ? (
            <Form.Item noStyle>
              <DatePicker.RangePicker
                style={{ marginLeft: "8px" }}
                onChange={(ts) => {
                  setFieldsValue({
                    [headName]: ts?.[0]?.startOf("day")?.format(DateTimeFormat),
                    [tailName]: ts?.[1]?.endOf("day")?.format(DateTimeFormat),
                  });
                  onChange && onChange(value);
                }}
              />
            </Form.Item>
          ) : undefined
        }
      </Form.Item>
    </>
  );
};
