/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-14 14:22:15
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification, Tabs, TabsProps } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './demandWorkOrderTemplate.module.css'
import {
  EditOutlined,
  PlusCircleOutlined,
  RightOutlined,
} from '@ant-design/icons'
import { EditOrAddTagsModal } from './editOrAddTagsModal'
import { DownLineOutlined } from '../../../compnents/icons/Icons'
import { EditTimeLineTitleModal } from './editTimeLineTitleModal'
import { ChooseApproverModal } from './chooseApproverModal'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../../models/datetime'
import {
  getWorkTemplateChooseUserEdit,
  getWorkTemplateData,
  getWorkTemplateTimeLineData,
} from '../workOrderTemplateSlice'

export interface DemandWorkOrderTemplateListRef {
  tabKey?: any
}

interface DemandWorkOrderTemplateProps {
  tabKey?: any
}
const RealDemandWorkOrderTemplateList = (
  { tabKey }: DemandWorkOrderTemplateProps,
  ref: React.Ref<DemandWorkOrderTemplateListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [id, setId] = useState('')

  const [grantersId, setGrantersId] = useState<any>()

  const [name, setName] = useState('')

  const [detail, setDetail] = useState<any>()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [
    isTimeLineTitleModalVisible,
    setIsTimeLineTitleModalVisible,
  ] = useState(false)

  const [
    isChooseApproverModalVisible,
    setIsChooseApproverModalVisible,
  ] = useState(false)

  const [tabKeys, setTabKeys] = useState('0')

  const [data, setData] = useState<any>()

  const [timeLineData, setTimeLineData] = useState<any>()

  useEffect(() => {
    if (data?.length) {
      const datas = data?.filter((v: any) => {
        return v?.name == '信息化需求工单'
      })?.[0]
      setDetail(datas)
    }
  }, [data])

  console.log(detail, data, 'detail')

  const getPage = () => {
    dispatch(getWorkTemplateData({ current: 1, size: 10000 }))
      .then(unwrapResult)
      .then((v: any) => {
        setData(v?.records)
      })
  }

  useEffect(() => {
    if (tabKey) {
      getPage()
      setTabKeys('0')
    }
  }, [tabKey])

  const getLinePage = () => {
    dispatch(getWorkTemplateTimeLineData(detail?.id))
      .then(unwrapResult)
      .then((v) => {
        setTimeLineData(v)
      })
  }

  useEffect(() => {
    if (tabKeys == '1') {
      getLinePage()
    }
  }, [tabKeys, detail])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '15px  15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Tabs
          activeKey={tabKeys}
          onChange={(k) => setTabKeys(k)}
          style={{ height: '100%' }}
          className={styles.contents}
        >
          <Tabs.TabPane key='0' tab='工单设计'>
            <div className={styles.backBlockMain}>
              <div
                className={styles.backBlock}
                onClick={() => {
                  setName('紧急度')
                  setIsModalVisible(true)
                }}
              >
                <div>
                  <span className={styles.icons}>*</span>
                  <span className={styles.title}>紧急程度</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                  <span>
                    <RightOutlined style={{ color: '#999999' }} />
                  </span>
                </div>
              </div>
              <div
                className={styles.backBlock}
                onClick={() => {
                  setName('需求主题')
                  setIsModalVisible(true)
                }}
              >
                <div>
                  <span className={styles.icons}>*</span>
                  <span className={styles.title}>需求主题</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                  <span>
                    <RightOutlined style={{ color: '#999999' }} />
                  </span>
                </div>
              </div>
              <div className={styles.backBlock}>
                <div>
                  <span className={styles.icons}> </span>
                  <span className={styles.title}>需求日期</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                </div>
              </div>
              <div
                className={styles.backBlock}
                onClick={() => {
                  setName('需求位置')
                  setIsModalVisible(true)
                }}
              >
                <div>
                  <span className={styles.icons}> </span>
                  <span className={styles.title}>需求位置</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                  <span>
                    <RightOutlined style={{ color: '#999999' }} />
                  </span>
                </div>
              </div>
              <div className={styles.backBlock}>
                <div>
                  <span className={styles.icons}>*</span>
                  <span className={styles.title}>需求描述</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                </div>
              </div>
              <div className={styles.backBlock}>
                <div className={styles.backBlockPicTitle}>
                  <div className={styles.backBlockPicTitleLeft}>
                    <span className={styles.icons}>*</span>
                    <span className={styles.title}>上传照片/视频</span>
                  </div>
                  <div>
                    <PlusCircleOutlined
                      style={{
                        color: '#999999',
                        fontSize: '18px',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className={styles.backBlock}
                onClick={() => {
                  setName('处理结果反馈')
                  setIsModalVisible(true)
                }}
              >
                <div>
                  <span className={styles.icons}>*</span>
                  <span className={styles.title}>处理结果反馈</span>
                </div>
                <div className={styles.placeholderMains}>
                  <span className={styles.placeholderText}>请选择</span>
                  <span>
                    <RightOutlined style={{ color: '#999999' }} />
                  </span>
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key='1' tab='流程设计'>
            {timeLineData?.map((v: any) => {
              return (
                <>
                  <div style={{ paddingBottom: '10px' }}>
                    <div className={styles.lineBlock}>
                      <div
                        className={styles.lineBlockTitle}
                        onClick={() => {
                          setIsTimeLineTitleModalVisible(true)
                          setName(v?.name)
                          setId(v?.id)
                        }}
                      >
                        <div>{v?.name}</div>
                        <div style={{ cursor: 'pointer' }}>
                          <EditOutlined />
                        </div>
                      </div>
                      {v?.seq == 1 ||
                      v?.seq == 2 ||
                     // v?.seq == 3 ||
                      v?.seq == 7 ? (
                        <>
                          <div className={styles.lineBlockMain}>
                            <div>
                              {v?.seq == 1
                                ? '全部人员'
                                : v?.seq == 2 || v?.seq == 3 || v?.seq == 7
                                ? '部门主管'
                                : '-'}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.lineBlockMain}>
                            <div
                              className={styles.changePeopleMain}
                              onClick={(e) => {
                                e.stopPropagation()
                                setIsChooseApproverModalVisible(true)
                                setId(v?.id)
                                setGrantersId(v?.granters)
                              }}
                            >
                              {v?.grantername ? (
                                <div className={styles.changePeopleMainLeft}>
                                  <span>{v?.grantername}</span>
                                  <span
                                    style={{
                                      marginLeft: '10px',
                                      color: 'red',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      Modal.confirm({
                                        content: `是否删除？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onOk: () =>
                                          dispatch(
                                            getWorkTemplateChooseUserEdit({
                                              id: v?.id,
                                              granters: 0,
                                            })
                                          )
                                            .then(unwrapResult)
                                            .then((v) => {
                                              notification.success({
                                                message: '操作成功',
                                                duration: 3,
                                              })
                                              getLinePage()
                                            }),
                                      })
                                    }}
                                  >
                                    删除
                                  </span>
                                </div>
                              ) : (
                                <div className={styles.changePeopleMainText}>
                                  请选择审批人
                                </div>
                              )}
                              <div className={styles.changePeopleMainRight}>
                                <RightOutlined style={{ color: '#999999' }} />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {v?.seq != 7 && (
                      <DownLineOutlined className={styles.lineIcons} />
                    )}
                  </div>
                </>
              )
            })}
          </Tabs.TabPane>
        </Tabs>
      </Col>
      <EditOrAddTagsModal
        dataDetail={detail}
        name={name}
        id={id}
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false)
          setId('')
          setName('')
          getPage()
        }}
        onOk={() => {
          setIsModalVisible(false)
          setId('')
          setName('')
          getPage()
        }}
      />
      <EditTimeLineTitleModal
        name={name}
        id={id}
        visible={isTimeLineTitleModalVisible}
        onCancel={() => {
          setIsTimeLineTitleModalVisible(false)
          setId('')
          setName('')
          dispatch(getWorkTemplateTimeLineData(detail?.id))
            .then(unwrapResult)
            .then((v) => {
              setTimeLineData(v)
            })
        }}
        onOk={() => {
          setIsTimeLineTitleModalVisible(false)
          setId('')
          setName('')
          dispatch(getWorkTemplateTimeLineData(detail?.id))
            .then(unwrapResult)
            .then((v) => {
              setTimeLineData(v)
            })
        }}
      />
      <ChooseApproverModal
        grantersId={grantersId}
        id={id}
        visible={isChooseApproverModalVisible}
        onCancel={() => {
          setIsChooseApproverModalVisible(false)
          setId('')
          setName('')
          setGrantersId('')
          dispatch(getWorkTemplateTimeLineData(detail?.id))
            .then(unwrapResult)
            .then((v) => {
              setTimeLineData(v)
            })
        }}
        onOk={() => {
          setIsChooseApproverModalVisible(false)
          setId('')
          setName('')
          setGrantersId('')
          dispatch(getWorkTemplateTimeLineData(detail?.id))
            .then(unwrapResult)
            .then((v) => {
              setTimeLineData(v)
            })
        }}
      />
    </Col>
  )
}

export const DemandWorkOrderTemplateList = forwardRef(
  RealDemandWorkOrderTemplateList
)
