/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-16 16:00:34
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Row, Form, Modal, notification, Radio } from 'antd'
import React, {
    forwardRef,
    ReactElement,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './statistic.module.css'
import { PieCharts } from '../../compnents/PieCharts/PieCharts'
import { getStatistic, getZhexianData, getTableData } from './statisticSlice'
import { MultipleYLine } from "../../compnents/MultipleYLine/index";
import { EditableList } from '../../compnents/list/EditableList'
import { columns } from './column'
import { TimeModal } from './timeModal'



const RealStatisticView = (

): ReactElement => {

    const dispatch = useDispatch<RootDispatch>()

    const [params, setParams] = useState<any>({
        current: 1,
        size: 10,
    })

    const [page, setPage] = useState({
        items: [],
        total: 0,
    })

    const [time, setTime] = useState<any>(2);

    const [timeTable, setTimeTable] = useState<any>(3);

    const [dataAll, setDataAll] = useState<any>()

    const [pageLoading, setPageLoading] = useState(false)

    const [lineData, setLineData] = useState<any>()

    const [tableData, setTableData] = useState<any>()

    const [startTime, setStartTime] = useState<any>()

    const [endTime, setEndTime] = useState<any>()

    const [isTimeModal, setIsTimeModal] = useState(false)

    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getStatistic())
            .then(unwrapResult)
            .then((res: any) => {
                setDataAll(res)
            })
    }, [])

    useEffect(() => {
        dispatch(getZhexianData({
            queryType: time,
        }))
            .then(unwrapResult)
            .then((res: any) => {
                setLineData(res)
            })
    }, [time])

    useEffect(() => {
        if (timeTable != 1 || startTime || endTime) {
           dispatch(getTableData({
               queryType: timeTable,
               startTime: startTime,
               endTime: endTime
           }))
               .then(unwrapResult)
               .then((res: any) => {
                   setPage({
                       items: res,
                       total: 0
                   })
               })
       }
    }, [timeTable, startTime, endTime])



    return (
        <div style={{ margin: '10px', overflowY: 'scroll', height: '98%' }}>
            <Row>
                <Col span={24} style={{
                    backgroundColor: '#fff',
                    height: '120px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 50px 0 20px'
                }}>
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <div className={styles.bgIcon}></div>
                        </div>
                        <div className={styles.content}>
                            <span style={{ fontSize: '14px' }}>系统用户数（人）</span>
                            <span style={{ fontSize: '26px', fontWeight: 'bold' }}>{dataAll?.userCount}</span>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <div className={styles.bgIcon}></div>
                        </div>
                        <div className={styles.content}>
                            <span style={{ fontSize: '14px' }}>就诊人数（人）</span>
                            <span style={{ fontSize: '26px', fontWeight: 'bold' }}>{dataAll?.patientCount}</span>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <div className={styles.bgIcon}></div>
                        </div>
                        <div className={styles.content}>
                            <span style={{ fontSize: '14px' }}>挂号预约次数（次）</span>
                            <span style={{ fontSize: '26px', fontWeight: 'bold' }}>{dataAll?.appointCount}</span>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <div className={styles.bgIcon}></div>
                        </div>
                        <div className={styles.content}>
                            <span style={{ fontSize: '14px' }}>活动预约次数（次）</span> 
                            <span style={{ fontSize: '26px', fontWeight: 'bold' }}>{dataAll?.freeAppointCount}</span>
                        </div>
                    </div>
                    <div className={styles.box}>
                        <div className={styles.icon}>
                            <div className={styles.bgIcon}></div>
                        </div>
                        <div className={styles.content}>
                            <span style={{ fontSize: '14px' }}>在线客服次数（次）</span>
                            <span style={{ fontSize: '26px', fontWeight: 'bold' }}>{dataAll?.kefuCount}</span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{ padding: '0 50px 0 20px', backgroundColor: '#fff', margin: '10px 0' }}>
                <Col span={24}>
                    <div className={styles.title}>图表分析</div>
                </Col>
                <Col span={24}>
                    <Row gutter={40}>
                        <Col span={12}>
                            <div style={{ border: '1px solid rgba(176, 205, 255, 1)', borderRadius: '5px', height: '320px' }}>
                                <div className={styles.chartTitle}>就诊人男女分布</div>
                                <PieCharts PieData={[{ name: '男', value: dataAll?.nancount }, { name: '女', value: dataAll?.nvcount }]} show={true} style={{
                                    overflow: 'hidden',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}></PieCharts>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{ border: '1px solid rgba(176, 205, 255, 1)', borderRadius: '5px', height: '320px' }}>
                                <div className={styles.chartTitle}>就诊人年龄分布</div>
                                <PieCharts PieData={dataAll?.agelist?.map((v: any) => { return { name: v.key, value: v.value } })} show={true} style={{
                                    overflow: 'hidden',
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}></PieCharts>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginTop: '15px', height: '420px' }}>
                    <div style={{ border: '1px solid rgba(176, 205, 255, 1)', borderRadius: '5px', height: '400px' }}>
                        <Row
                            style={{ height: "100px", width: "100%", paddingRight: '15px' }}
                            align="middle"
                            justify="space-between"
                        >
                            <div className={styles.chartTitle}>运营趋势图</div>
                            <Radio.Group
                                defaultValue={time}
                                value={time}
                                onChange={(e: any) => {
                                    setTime(e.target.value);
                                }}
                                optionType="button"
                                buttonStyle="solid"
                            >
                                <Radio.Button value={2}>近七天</Radio.Button>
                                <Radio.Button value={3}>近30天</Radio.Button>
                                <Radio.Button value={4}>近一年</Radio.Button>
                                <Radio.Button value={5}>全部</Radio.Button>
                            </Radio.Group>
                        </Row>
                        <MultipleYLine
                            lineDataOne={lineData?.userCountList}
                            lineDataTwo={lineData?.patientCountList}
                            lineDataThree={lineData?.appointCountList}
                            lineDataFour={lineData?.freeAppointCountList}
                            lineDataFive={lineData?.kefuCountList}
                            style={{ height: "300px" }}
                        />
                    </div>
                </Col>
            </Row>
            <Row style={{ backgroundColor: '#fff', paddingBottom: '50px' }}>
                <Col span={24} style={{ paddingTop: "20px" }}>
                    <Row
                        style={{ width: "100%", paddingRight: '15px', paddingBottom: '8px' }}
                        align="middle"
                        justify="space-between"
                    >
                        <div className={styles.chartTitle}>运营趋势图</div>
                        <Radio.Group
                            defaultValue={timeTable}
                            value={timeTable}
                            onChange={(e: any) => {
                                setTimeTable(e.target.value);
                                setStartTime(undefined)
                                setEndTime(undefined)
                            }}
                            optionType="button"
                            buttonStyle="solid"
                        >
                            <Radio.Button value={1}
                                onClick={(v) => {
                                    setIsTimeModal(true)
                                    setTimeTable(3)
                                    setStartTime(undefined)
                                    setEndTime(undefined)
                                }}
                            >自定义</Radio.Button>
                            <Radio.Button value={3}>近30天</Radio.Button>
                            <Radio.Button value={4}>近一年</Radio.Button>
                            <Radio.Button value={5}>全部</Radio.Button>
                        </Radio.Group>
                    </Row>
                </Col>
                <Col span={24}>
                    <EditableList
                        rowKey={(_: any, i: any) => `${i}}`}
                        page={{
                            items: page.items,
                            current: params.current,
                            size: params.size,
                            total: page.total,
                        }}
                        pagination={false}
                        loading={pageLoading}
                        columns={columns((action: any, v: any) => {
                            return
                        }, params.size * (params.current - 1))}
                        onChangePage={(current, size) => {
                            console.log(params, 'change')
                            setParams({ ...params, current, size: size || 10 })
                        }}
                    />
                </Col>
            </Row>
            <TimeModal
                visible={isTimeModal}
                onOk={() => {
                    setIsTimeModal(false)
                    setLoading(false)
                }}
                onCancel={() => {
                    setIsTimeModal(false)
                    setStartTime(undefined)
                    setEndTime(undefined)
                    setLoading(false)
                }}
                onValueChange={(v) => {
                    setStartTime(v?.startTime)
                    setEndTime(v?.endTime)
                }}
            ></TimeModal>
        </div>
    )
}

export const StatisticView = forwardRef(RealStatisticView)
