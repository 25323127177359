import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { sendAsync } from "../../app/applicationSlice";
import { RootState, RootThunkApi } from "../../app/store";
import { LoadingState } from "../../models/common";
import { Page, pageOf, startPageOf } from "../../models/page";
import { fromJson, PrintTemplate } from "../../models/print";
import { ServiceError } from "../../services/errors";
import * as S from "../../services/print";
import {
  deleteTemplateFnParams,
  GetPrintSourceParams,
  GetPrintTemplateListParams,
  SelectPrintTemplateParams,
} from '../../services/print'

const TEMPLATE_OFFSET_KEY = 'template_offsets'
const DEFAULT_PRINTERS_KEY = 'default_printers'

export interface Printer {
  id: number
  name: string
}

interface Offset {
  left: number
  top: number
}

interface PrintState {
  initialized: LoadingState
  printers: Printer[]
  offsets: Record<string, Offset>
  defaultPrinters: Record<string, string>
  page: Page<PrintTemplate>
}

const initialState = {
  initialized: LoadingState.Idle,
  printers: [],
  offsets: JSON.parse(localStorage.getItem(TEMPLATE_OFFSET_KEY) || '{}'),
  defaultPrinters: JSON.parse(
    localStorage.getItem(DEFAULT_PRINTERS_KEY) || '{}'
  ),
  page: startPageOf(),
} as PrintState

interface LodopInterface {
  VERSION: string
  CVERSION: string
  GET_PRINTER_COUNT(): number
  GET_PRINTER_NAME(param: string | number): string
  SET_PRINTER_INDEX(param: string | number): void
}

declare global {
  let LODOP: LodopInterface
}

export const initializePrintService = createAsyncThunk<
  void,
  void,
  RootThunkApi<void>
>('print/initializePrintServiceStatus', async (_, api) => {
  return new Promise<void>((resolve, reject) => {
    const initialied = selectPrintServiceState(api.getState())
    if (initialied !== LoadingState.Loading) {
      reject(new ServiceError(500, '不能重复初始化打印服务。'))
      return
    }
    const head =
      document.head ||
      document.getElementsByTagName('head')[0] ||
      document.documentElement
    if (!head) {
      reject(new ServiceError(500, '安装Lodop脚本失败，请咨询售后服务。'))
      return
    }
    let errorCount = 0
    let script = document.createElement('script')
    script.src = 'http://localhost:8000/CLodopfuncs.js?priority=1'
    script.addEventListener('load', () => {
      resolve()
    })
    script.addEventListener('error', () => {
      ++errorCount
      if (errorCount >= 2) {
        reject(new ServiceError(500, '加载CLodop脚本失败'))
      }
    })
    head.insertBefore(script, head.firstChild)

    script = document.createElement('script')
    script.src = 'http://localhost:18000/CLodopfuncs.js?priority=1'
    head.insertBefore(script, head.firstChild)
    script.addEventListener('load', () => {
      resolve()
    })
    script.addEventListener('error', () => {
      ++errorCount
      if (errorCount >= 2) {
        reject(new ServiceError(500, '加载CLodop脚本失败'))
      }
    })
  })
})

export const getPrinters = createAsyncThunk<Printer[], void, RootThunkApi>(
  'print/getPrintersStatus',
  async () => {
    return new Promise<Printer[]>((resolve, reject) => {
      if (!LODOP) {
        reject(new ServiceError(500, 'Lodop未初始化'))
        return
      }
      const count = LODOP.GET_PRINTER_COUNT()
      resolve(
        _.range(0, count).map((i) => ({
          id: i,
          name: LODOP.GET_PRINTER_NAME(i),
        }))
      )
    })
  }
)

export const getPrintTemplateList = createAsyncThunk<
  Page<PrintTemplate>,
  GetPrintTemplateListParams,
  RootThunkApi<Page<PrintTemplate>>
>('print/getPrintTemplateListStatus', async (params, api) => {
  const result = await sendAsync(S.getPrintTemplateList(params), api).then(
    (data) => {
      return pageOf(
        data,
        _.chain(data.records)
          .map((t) => fromJson(t))
          .value()
      )
    }
  )
  if (params.tenantId) {
    const assigned = await sendAsync(
      S.getTenantPrintTemplateList(params.tenantId),
      api
    ).then((data) =>
      _.chain(data.records)
        .map((t) => fromJson(t))
        .value()
    )
    return {
      ...result,
      items: result.items.map((t) => {
        const template = assigned.find((tt) => t.id === tt.templateId)
        if (template) {
          return { ...t, templateId: template.id, assigned: true }
        } else {
          return t
        }
      }),
    }
  } else {
    return result
  }
})

// 删除模板
export const deleteTemplate = createAsyncThunk<
  any,
  deleteTemplateFnParams,
  RootThunkApi
>('print/deleteTemplate', async (params, api) => {
  return sendAsync(S.deleteTemplateFn(params), api)
}) 
// 获取预览模板路劲
export const getImgUrl = createAsyncThunk<
  any,
  deleteTemplateFnParams,
  RootThunkApi
>('print/getImgUrl', async (params, api) => {
  return sendAsync(S.getImgUrlFn(params), api)
})
// 上传预览图
export const getUploadImg = createAsyncThunk<
  void,
  deleteTemplateFnParams,
  RootThunkApi
>('print/getUploadImg', async (body, api) => {
  return sendAsync(S.getUploadImgFn(body), api)
})

export const assginPrintTemplate = createAsyncThunk<
  void,
  PrintTemplate,
  RootThunkApi
>('print/assignPrintTemplateStatus', async (params, api) => {
  return sendAsync(S.assginPrintTemplate(params), api)
})

export const removePrintTemplate = createAsyncThunk<void, string, RootThunkApi>(
  'print/removePrintTemplate',
  async (params, api) => {
    return sendAsync(S.removePrintTemplate(params), api)
  }
)

export const selectPrintTemplate = createAsyncThunk<
  PrintTemplate[],
  SelectPrintTemplateParams,
  RootThunkApi<PrintTemplate[]>
>('print/selectPrintTemplateStatus', async (params, api) => {
  return sendAsync(S.selectPrintTemplate(params), api).then((data) =>
    _.chain(data.records)
      .map((t) => fromJson(t))
      .value()
  )
})

export const printTemplate = createAsyncThunk<
  void,
  GetPrintSourceParams,
  RootThunkApi<void>
>('print/printTemplateStatus', async (params, api) => {
  return sendAsync(S.getPrintSource(params), api).then((source) => {
    eval(source)
  })
})

export const setPrintTemplateState = createAsyncThunk<
  void,
  PrintTemplate,
  RootThunkApi
>('print/setPrintTemplateStateStatus', async (params, api) => {
  return sendAsync(S.setPrintTemplateState(params), api)
})



const printSlice = createSlice({
  name: "print",
  initialState,
  reducers: {
    setTempalteOffset: (
      state,
      action: PayloadAction<{ code: string; offset: Offset }>
    ) => {
      const offsets = {
        ...state.offsets,
        [action.payload.code]: action.payload.offset,
      };
      state.offsets = offsets;
      localStorage.setItem(TEMPLATE_OFFSET_KEY, JSON.stringify(offsets));
    },
    setTemplateDefaultPrinter: (
      state,
      action: PayloadAction<{ code: string; printer: string }>
    ) => {
      const selectedPrinters = {
        ...state.defaultPrinters,
        [action.payload.code]: action.payload.printer,
      };
      state.defaultPrinters = selectedPrinters;
      localStorage.setItem(
        DEFAULT_PRINTERS_KEY,
        JSON.stringify(selectedPrinters)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializePrintService.pending, (state) => {
      state.initialized = LoadingState.Loading;
    });
    builder.addCase(initializePrintService.fulfilled, (state) => {
      state.initialized = LoadingState.Finished;
    });
    builder.addCase(initializePrintService.rejected, (state) => {
      state.initialized = LoadingState.Error;
    });
    builder.addCase(getPrintTemplateList.fulfilled, (state, action) => {
      if (action.meta.arg.templateCategory === undefined) {
        state.page = action.payload;
      }
    });
    builder.addCase(getPrinters.fulfilled, (state, action) => {
      state.printers = action.payload;
    });
  },
});

export const {
  setTempalteOffset,
  setTemplateDefaultPrinter,
} = printSlice.actions;

export const selectTemplateOffsets = (
  state: RootState
): Record<string, Offset> => state.print.offsets;

export const selectTemplateDefaultPrinters = (
  state: RootState
): Record<string, string> => state.print.defaultPrinters;

export const selectPrintServiceState = (state: RootState): LoadingState =>
  state.print.initialized;

export const selectPrintTemplatePage = (
  state: RootState
): Page<PrintTemplate> => state.print.page;

export const selectPrinters = (state: RootState): Printer[] =>
  state.print.printers;

export default printSlice.reducer;
