import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as C from '../../models/commoncode'
import { CommonCode, CommonCodeCategory } from '../../models/commoncode'
import * as D from '../../models/department'
import * as Dp from '../../models/department'
import { Department } from '../../models/department'
import * as Ds from '../../models/disease'
import { Disease } from '../../models/disease'
import { commonCodeWithOral } from '../../models/kqCommoncode'
import * as M from '../../models/material'
import { Material } from '../../models/material'
import { OnlyOrgaztion } from '../../models/messageCenter'
import { Page, pageOf } from '../../models/page'
import { FirstPage, Pager } from '../../models/pagination'
import { RecipeCategory } from '../../models/recipe'
import * as U from '../../models/user'
import { User } from '../../models/user'
import { getCommonCodes } from '../../services/commoncode'
import {
  getDepartmentList,
  GetDepartmentListParams,
  getOutpatientDepartment as apiGetOutpatientDepartment,
  getUserAddFn,
  getUserAddFnParams,
} from '../../services/department'
import {
  GetDiseaseCompletionParams,
  getDiseaseCompletions as apiGetDiseaseCompletions,
} from '../../services/disease'
import {
  getInventoryCompletions as apiGetInventoryCompletions,
  getMaterialCompletions as apiGetMaterialCompletions,
  getTreatmentServiceCompletions as apiGetTreatmentServiceCompletions,
} from '../../services/material'
import {
  GetOnlyOrgaztionParams,
  getOnlyOrgaztions,
} from '../../services/notificationCenter'
import { send } from '../../services/request'
import { durgSystemPage } from '../../services/storehouse'
import {
  getDoctorsInDepartment as apiGetDoctorsInDepartment,
  getUserList,
  GetUserListParams,
  getUsersInDepartment as apiGetUsersInDepartment,
} from '../../services/user'

interface CompletionState {
  departments: Department[]
  doctors: User[]
  users: User[]
  diseases: Disease[]
  materials: Material[]
  codes: {
    [CommonCodeCategory.Usage]: CommonCode[]
    [CommonCodeCategory.TcmUsage]: CommonCode[]
    [CommonCodeCategory.Frequency]: CommonCode[]
    [CommonCodeCategory.Unit]: CommonCode[]
    [CommonCodeCategory.Durg]: CommonCode[]
    [CommonCodeCategory.Dosago]: CommonCode[]
    [CommonCodeCategory.KQJC]: CommonCode[]
    [CommonCodeCategory.JYJC]: CommonCode[]
    [CommonCodeCategory.ZLJH]: CommonCode[]
    [CommonCodeCategory.KQCZ]: CommonCode[]
    [CommonCodeCategory.KQZL]: CommonCode[]
  }
}

const initialState = {
  departments: [],
  doctors: [],
  users: [],
  diseases: [],
  materials: [],
  codes: {
    [CommonCodeCategory.Usage]: [],
    [CommonCodeCategory.TcmUsage]: [],
    [CommonCodeCategory.Frequency]: [],
    [CommonCodeCategory.Unit]: [],
    [CommonCodeCategory.Durg]: [],
    [CommonCodeCategory.Dosago]: [],
    [CommonCodeCategory.KQJC]: [],
    [CommonCodeCategory.JYJC]: [],
    [CommonCodeCategory.ZLJH]: [],
    [CommonCodeCategory.KQCZ]: [],
    [CommonCodeCategory.KQZL]: [],
  },
} as CompletionState

interface kqCommonCode {
  category: CommonCodeCategory
  keyword: string
}

const completionSlice = createSlice({
  name: 'completion',
  initialState,
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload
    },
    setDoctorsInDepartment: (state, action: PayloadAction<User[]>) => {
      state.doctors = action.payload
    },
    setUsersInDepartment: (state, action: PayloadAction<User[]>) => {
      state.doctors = action.payload
    },
    setMaterials: (state, action: PayloadAction<Material[]>) => {
      state.materials = action.payload
    },
    setCommonCodes: (
      state,
      action: PayloadAction<{
        category: CommonCodeCategory
        codes: CommonCode[]
      }>
    ) => {
      state.codes[action.payload.category] = action.payload.codes
    },
  },
})

const { setDepartments, setMaterials, setCommonCodes } = completionSlice.actions

export const {
  setDoctorsInDepartment,
  setUsersInDepartment,
} = completionSlice.actions

export function getOutpatientDepartments(): RootThunk {
  return api(apiGetOutpatientDepartment(), (data: any, dispatch) => {
    const departments = _.chain(data.records)
      .map((d) => Dp.fromJson(d))
      .value()
    dispatch(setDepartments(departments))
  })
}

export function getDoctorsInDepartment(departmentId: string): RootThunk {
  return api(apiGetDoctorsInDepartment(departmentId), (data: any, dispatch) => {
    const doctors = _.chain(data.records)
      .map((u) => U.fromJson(u))
      .value()
    dispatch(setDoctorsInDepartment(doctors))
  })
}

export function getUsersInDepartment(
  departmentId: string,
  stationCategory: U.StationCategory
): RootThunk {
  return api(
    apiGetUsersInDepartment(departmentId, stationCategory),
    (data: any, dispatch) => {
      const doctors = _.chain(data.records)
        .map((u) => U.fromJson(u))
        .value()
      dispatch(setUsersInDepartment(doctors))
    }
  )
}

export function getMaterialCompletions(
  params: { keyword: string; current: number },
  category: RecipeCategory
): RootThunk {
  return api(
    apiGetMaterialCompletions(params, category),
    (data: any, dispatch) => {
      const materials = _.chain(data.records)
        .map((m) => ({
          ...M.fromMaterialJson(m),
          materialsCatalogId: m.id?.toString(),
        }))
        .value()
      dispatch(setMaterials(materials))
    }
  )
}

export function getTreatmentServiceCompletions(params: {
  keyword: string
  current: number
}): RootThunk {
  return api(
    apiGetTreatmentServiceCompletions(params),
    (data: any, dispatch) => {
      const materials = _.chain(data.records)
        .map((m) => ({
          ...M.fromTreatmentJson(m),
          drugCount: 1,
          treatmentServiceId: m.id?.toString(),
        }))
        .value()
      dispatch(setMaterials(materials))
    }
  )
}

export function getCommonCodeCompletions(
  category: CommonCodeCategory,
  keyword: string
): RootThunk {
  return api(getCommonCodes(category, keyword), (data: any, dispatch) => {
    const codes = _.chain(data.records)
      .map((c) => C.fromJson(c))
      .value()
    dispatch(setCommonCodes({ category, codes }))
  })
}



export const getCommonCodeCompletionsAsync = createAsyncThunk<
  any,
  kqCommonCode,
  RootThunkApi<void>
>('getkqCmmoncode', async (params, api) => {
  return sendAsync(getCommonCodes(params.category, params.keyword), api)
})

export const selectOutpatientDepartments = (state: RootState): Department[] =>
  state.completion.departments

export const selectDoctorsInDepartments = (state: RootState): User[] =>
  state.completion.doctors

export const selectMaterialCompletions = (state: RootState): Material[] =>
  state.completion.materials

export const selectCommonCodes = (category?: CommonCodeCategory) => (
  state: RootState
): CommonCode[] => (category ? state.completion.codes[category] : [])

export default completionSlice.reducer

export enum ItemCompletionType {
  Auto = 0,
  Material = 1,
  Treatment = 2,
  Inventory = 3,
}

interface RecipeItemCompletionsArgs {
  current: number
  keyword: string
  type: ItemCompletionType
  merge?: boolean
  category?: RecipeCategory
  storehouseId?: string
  includeAllTcm?: boolean
}

export const getRecipeItemCompletions = createAsyncThunk<
  { items: Material[]; pager: Pager },
  RecipeItemCompletionsArgs,
  RootThunkApi<{ items: Material[]; pager: Pager }>
>('completion/getRecipeItemCompletionsStatus', async (params, api) => {
  let request
  switch (params.type) {
    case ItemCompletionType.Material:
      request = apiGetMaterialCompletions(
        { keyword: params.keyword, current: params.current },
        params.category,
        params.includeAllTcm
      )
      break
    case ItemCompletionType.Inventory:
      request = apiGetInventoryCompletions(
        {
          keyword: params.keyword,
          current: params.current,
          storehouseId: params.storehouseId,
          merge: params.merge,
          // serviceShopPrice:params.serviceShopPrice
        },
        params.category,
        params.includeAllTcm
      )
      break
    case ItemCompletionType.Treatment:
      request = apiGetTreatmentServiceCompletions({
        keyword: params.keyword,
        current: params.current,
      })
      break
    case ItemCompletionType.Auto:
      return api.rejectWithValue({ items: [], pager: FirstPage })
  }
  return sendAsync(request, api).then((data) => ({
    pager: { current: data.current, size: data.size, total: data.total },
    items: _.chain(data.records)
      .map((m) => {
        const material = M.fromMaterialJson(m)
        switch (params.type) {
          case ItemCompletionType.Inventory:
            return params.merge
              ? {
                  ...material,
                  materialsCatalogId: m.id,
                  id: `${material.storehouseName}-${m.id}`,
                }
              : { ...material, inventoryLedgerId: m.id }
          case ItemCompletionType.Treatment:
            return { ...material, treatmentServiceId: m.id }
          default:
            return { ...material, materialsCatalogId: m.id }
        }
      })
      .value(),
  }))
})

export const getCodeCompletions = createAsyncThunk<
  CommonCode[],
  { category: CommonCodeCategory; keyword: string },
  RootThunkApi<{ category: CommonCodeCategory; keyword: string }>
>('completion/getCodeCompletionStatus', async (params, api) => {
  return sendAsync(getCommonCodes(params.category, params.keyword), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((c) => C.fromJson(c))
        .value()
  )
})

export const getFullDeparmentList = createAsyncThunk<
  Department[],
  GetDepartmentListParams,
  RootThunkApi<Department[]>
>('completion/getFullDeparmentListStatus', async (params, api) => {
  return sendAsync(getDepartmentList(params), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((d) => D.fromJson(d))
        .value()
  )
})

// 新增时自动开启机构病例
export const getUserAdd = createAsyncThunk<
  void,
  getUserAddFnParams,
  RootThunkApi<Department[]>
>('completion/getUserAdd', async (params, api) => {
  return sendAsync(getUserAddFn(params), api)
})

export const getFullStorageHouseList = createAsyncThunk<
  any[],
  void,
  RootThunkApi
>('completion/getFullStorageHouseListStatus', async (params, api) => {
  return sendAsync(durgSystemPage(1, 1000, 1), api).then((data) => data.records)
})

export const getFullUserList = createAsyncThunk<
  User[],
  GetUserListParams,
  RootThunkApi
>('completion/getFullUserListStatus', async (params, api) => {
  return sendAsync(getUserList(params), api).then(
    (data: Record<string, never>) =>
      _.chain(data.records)
        .map((d) => U.fromJson(d))
        .value()
  )
})

export const getDiseaseCompletions = createAsyncThunk<
  Page<Disease>,
  GetDiseaseCompletionParams,
  RootThunkApi<Page<Disease>>
>('completion/getDiseaseCompletionsStatus', async (params, api) => {
  return sendAsync(apiGetDiseaseCompletions(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((d) => Ds.fromJson(d))
        .value()
    )
  )
})

export const getOnlyOrgaztionCompletions = createAsyncThunk<
  Page<OnlyOrgaztion>,
  GetOnlyOrgaztionParams,
  RootThunkApi<Page<OnlyOrgaztion>>
>('completion/getDiseaseCompletionsStatus', async (params, api) => {
  return sendAsync(getOnlyOrgaztions(params), api).then((data) =>
    pageOf(
      data,
      _.chain(data.records)
        .map((d) => Ds.fromJson(d))
        .value()
    )
  )
})
