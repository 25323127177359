/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-04-04 16:49:10
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-17 11:50:07
 */
/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-13 10:22:50
 */
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { getAge } from '../../utils/StringUtils'
import styles from './userManagement.module.css'
import maleHead from '../images/male.png'
import { EditableList } from '../../compnents/list/EditableList'
import { patientListColumns } from './column'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store' 
import { getPatientsManagementList } from '../patientsManagement/patientsManagementSlice'
import { unwrapResult } from '@reduxjs/toolkit'

export const PatientListModal = (props: {
  patientPhonenumber?: any
  openid?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  useEffect(() => {
    if (props?.visible) {
      dispatch(
        getPatientsManagementList({
          current: 1,
          size: 1000,
          openid: props?.openid,
          patientPhonenumber: props?.patientPhonenumber,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setPage({
            items: res.records,
            total: res.total,
          })
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible])

  return (
    <div>
      <Modal
        title='就诊人'
        visible={props.visible}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
        width={1000}
      >
        <div className={styles.visibleContent}>
          <EditableList
            bordered
            pagination={false}
            page={{
              items: page.items,
              current: 1,
              size: 10,
              total: page.total,
            }}
            loading={pageLoading}
            columns={patientListColumns()}
          />
        </div>
      </Modal>
    </div>
  )
}
