import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { DemandWorkOrderList } from './demandWorkOrder/demandWorkOrder'
import { MaintenanceWorkOrderList } from './maintenanceWorkOrder/maintenanceWorkOrder'

interface WorkOrderApprovalProps {
  tabKey?: any
}
const RealWorkOrderApprovalLists = ({
  tabKey,
}: WorkOrderApprovalProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [currentTabKey, setCurrentTabKey] = useState('0')

  return (
    <TabBar
      activeKey={currentTabKey}
      style={{ margin: '10px 20px' }}
      onChange={(v) => {
        setCurrentTabKey(v)
      }}
    >
      <TabPane key='0' tab='信息化需求工单'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DemandWorkOrderList tabKey={currentTabKey} />
          </Col>
        </Col>
      </TabPane>
      <TabPane key='1' tab='维修工单'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MaintenanceWorkOrderList tabKey={currentTabKey} />
          </Col>
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const WorkOrderApprovalList = forwardRef(RealWorkOrderApprovalLists)
