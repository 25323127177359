/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-29 13:33:08
 * @LastEditors: linxi
 * @LastEditTime: 2021-05-13 16:05:29
 */
import { StationCategory, toJson, User } from "../models/user";
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant";
import { Request } from "./request";

export interface GetUserListParams {
  current: number;
  size?: number;
  outpatientDepartmentId?: string;
  innerDepartmentId?: string;
  param?: string;
  state?: number;
  tenantId?: string;
  stationCategory?: number;
}

export function getUserList(params: GetUserListParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/list`
      : `${API_PREFIX}/blade-user/user/list`,
    method: "GET",
    params,
  };
}

export interface CreateUserParams {
  tenantId?: string;
  user: User;
}

export function createOrUpdateUser(params: CreateUserParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/save`
      : `${API_PREFIX}/blade-user/user/save`,
    method: "POST",
    body: { ...toJson(params.user), tenantId: params.tenantId },
  };
}

export interface GetUserDetailParams {
  tenantId?: string;
  userId: string;
}

export function getUserDetail(params: GetUserDetailParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/detail`
      : `${API_PREFIX}/blade-user/user/detail`,
    method: "POST",
    params: {
      userId: params.userId,
    },
  };
}

export interface ToggleUserStateParams {
  tenantId?: string;
  userId: string;
}

export function toggleUserState(params: ToggleUserStateParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/logoutOrEnable`
      : `${API_PREFIX}/blade-user/user/logoutOrEnable`,
    method: "POST",
    params: {
      userId: params.userId,
    },
  };
}

export interface ResetUserPasswordParams {
  id: string;
  phone: string;
  tenantId?: string;
}

export function resetUserPassword(params: ResetUserPasswordParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/user/passwordReset`
      : `${API_PREFIX}/blade-user/user/password/reset`,
    method: "POST",
    body: {
      id: params.id,
      phone: params.phone,
    },
  };
}

export function getDoctorsInDepartment(departmentId: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: "GET",
    params: {
      outpatientDepartmentId: departmentId,
      stationCategory: StationCategory.DoctorOrPharmacist,
      state: 1,
      size: 1000,
    },
  };
}

export function getUsersInDepartment(
  departmentId: string,
  stationCategory?: StationCategory
): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/list`,
    method: "GET",
    params: {
      outpatientDepartmentId: departmentId,
      stationCategory,
      state: 1,
      size: 1000,
    },
  };
}
export function getUserProfile(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/profile`,
    method: "GET",
  };
}
