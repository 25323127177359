import { Bool } from "./common";

// 处方分类 1:西药方 3:草药方 4:治疗 6:检查 7:其他 12:颗粒草药方 101:预收挂号费 102:额外费用
export enum RecipeCategory {
  Medicine = 1,
  Tcm = 3,
  Treatment = 4,
  Examination = 6,
  Others = 7,
  TcmGranules = 12,
  Registration = 101,
  Extras = 102,
}

export const EditableRecipeCategories = [
  RecipeCategory.Medicine,
  RecipeCategory.Tcm,
  RecipeCategory.TcmGranules,
  RecipeCategory.Treatment,
  RecipeCategory.Examination,
  RecipeCategory.Others,
];

export function isTcmRecipeCategory(category?: RecipeCategory): boolean {
  return (
    category === RecipeCategory.Tcm || category === RecipeCategory.TcmGranules
  );
}

export function getRecipeCategoryName(category: RecipeCategory): string {
  switch (category) {
    case RecipeCategory.Medicine:
      return "西药方";
    case RecipeCategory.Tcm:
      return "中药方";
    case RecipeCategory.TcmGranules:
      return "颗粒草药方";
    case RecipeCategory.Treatment:
      return "治疗";
    case RecipeCategory.Examination:
      return "检查";
    case RecipeCategory.Others:
      return "其他";
    case RecipeCategory.Extras:
      return "额外费用";
    case RecipeCategory.Registration:
      return "挂号";
  }
  return "";
}

// TO_SETTLE(0, "未结算(待收费)"), IN_SETTLE(1, "结算中"),
// SETTLED_TO_CONFIRM(2, "已结算未确认"), SETTLED(3, "已结算(已收费)"),
// IN_RETURN(4, "退费中"), RETURNED(5, "已退费");
export enum RecipeStatus {
  Unknown = -1,
  ToSettle = 0,
  InSettle = 1,
  SettledToConfirm = 2,
  Settled = 3,
  InReturn = 4,
  Returned = 5,
}

export function isRecipePayable(recipe: Recipe): boolean {
  return (
    recipe.state === RecipeStatus.ToSettle ||
    recipe.state === RecipeStatus.Unknown
  );
}

export function getRecipeStatusName(status: RecipeStatus): string {
  switch (status) {
    case RecipeStatus.ToSettle:
      return "未结算";
    case RecipeStatus.InSettle:
      return "结算中";
    case RecipeStatus.SettledToConfirm:
      return "已结算未确认";
    case RecipeStatus.Settled:
      return "已结算";
    case RecipeStatus.InReturn:
      return "退费中";
    case RecipeStatus.Returned:
      return "已退费";
  }
  return "";
}

export interface Recipe {
  id: string;
  category: RecipeCategory;
  treatmentId: string;
  doctorId: string;
  doctorName: string;
  patientId: string;
  recipeTime: string;
  amount: number;
  executeState: Bool;
  state: number;
  useMethod: string;
  tip: number;
}

export function fromJson(json: any): Recipe {
  return {
    id: json.id?.toString() || "",
    category: json.category,
    treatmentId: json.treatmentId?.toString() || "",
    doctorId: json.doctorId?.toString() || "",
    doctorName: json.doctorName,
    patientId: json.patientId?.toString() || "",
    recipeTime: json.recipeTime,
    amount: json.amount,
    executeState: json.executeState,
    state: json.state,
    useMethod: json.useMethod,
    tip: json.tip,
  };
}
