import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import {
    DateTimeFormat,
    DateTimeFormatSimple,
    DateTimeFormatSimpleOnly,
} from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import { FreePatientStateName } from '../../models/freePatients'

type ActionType = 'detail' | 'setting'

export const Columns = (
  onClick: (action: ActionType, v: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '昵称',
      dataIndex: 'name',
      align: 'center',
      width: '15rem',
      ellipsis: true,
      key: 'treatmentDate',
      // render: (_, r) => {
      //   return r.title ? r.title : '-'
      // },
      render: function Title(_, r): ReactElement {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '手机号',
      key: 'phone',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      dataIndex: 'phone',
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },

    {
      title: '会员等级',
      dataIndex: 'memberLevelName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'treatmentDate',
      render: (_, r) => {
        return r.memberLevelName || '-'
      },
    },
    {
      title: '会员到期日',
      dataIndex: 'endTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'deadline',
      render: (_, r) => {
        return r.memberLevelName == '普通会员' ? '-' : r.endTime || '-'
      },
    },
    {
      title: '积分',
      dataIndex: 'points',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'points',
      render: (_, r) => {
        return r.points ? r.points : '-'
      },
    },
    {
      title: '开通日期',
      dataIndex: 'createTime',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      key: 'address',
      render: (_, r) => {
        return r.createTime
          ? moment(r.createTime).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '操作',
      dataIndex: 'status',
      align: 'center',
      width: '16rem',
      ellipsis: true,
      key: 'status',
      render: function DownLoad(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('setting', t)
              }}
            >
              积分核销
            </a>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              积分明细
            </a>
          </>
        )
      },
    },
  ]
}
