import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  api,
  ApplicationType,
  sendAsync,
  setCredentials,
  setErrorState,
} from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import { AccountType } from '../../models/accounttype'
import { ShopperTokenValue } from '../../models/auth'
import { ErrorCode } from '../../models/error'
import { fromJson, Tenant } from '../../models/tenant'
import {
  createDirectLoginToken,
  CreateDirectLoginTokenParams,
  getSmsCode as apiGetSmsCode,
  faceLogin,
} from '../../services/admin'
import {
  createToken,
  getScanUrl as apiGetScanUrl,
  getTenantByUsername,
  getThirdPartyLoginToken as apiGetThirdPartyLoginToken,
  getThirdPartyLoginUrl as apiGetThirdPartyLoginUrl,
  invoiceToken,
  InvoiceTokenParams,
  queryScanStatus as apiQueryScanStatus,
  replaceToken,
  ScanQueryParams,
  ThirdPartyLoginParams,
  ThirdPartyLoginTokenParams,
  unbindAccount as apiUnbindAccount,
} from '../../services/auth'

interface LoginState {
  tenants: Tenant[]
  smsCountdown: number
}

const LOGIN_SMS_COUNTDOWN = 'login_sms_countdown'

const initialState: LoginState = {
  tenants: [],
  smsCountdown: parseInt(localStorage.getItem(LOGIN_SMS_COUNTDOWN) || '0'),
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setTenants: (state, action: PayloadAction<Array<Tenant>>) => {
      state.tenants = action.payload
    },
    setSmsCountdown: (state, action: PayloadAction<number>) => {
      state.smsCountdown = action.payload
      localStorage.setItem(LOGIN_SMS_COUNTDOWN, action.payload.toString())
    },
  },
})

export const { setTenants } = loginSlice.actions

export function getTenants(username: string): RootThunk {
  return api(
    getTenantByUsername(username),
    (data: Array<Record<string, never>>, dispatch) => {
      const tenants = data.map((t) => fromJson(t))
      if (tenants.length === 0) {
        dispatch(
          setErrorState({
            code: ErrorCode.ResourceNotFound,
            message: '手机号码未注册',
          })
        )
      }
      dispatch(setTenants(tenants))
    }
  )
}

export function login(params: {
  username: string
  passWord: string
  rememberMe: boolean
}): RootThunk {
  const request = createToken(params.username, params.passWord)
  return api(
    request,
    (data: any, dispatch) => {
      const token = data.token as string
      dispatch(
        setCredentials({
          creds: {
            ...data,
            id: data.userId?.toString(),
            tenantName: data.tenantName,
            userName: data.userName || '',
            token,
          },
          rememberMe: params.rememberMe,
        })
      )
    },
    (e, dispatch): boolean => {
      const error = {
        code:
          e.code === 400
            ? ErrorCode.WrongUsernameOrPassword
            : ErrorCode.Unknown,
        message: e.message,
      }
      dispatch(setErrorState(error))
      return true
    }
  )
}

export const directLogin = createAsyncThunk<
  void,
  CreateDirectLoginTokenParams,
  RootThunkApi
>('login/directLoginStatus', async (params, api) => {
  return sendAsync(createDirectLoginToken(params), api).then((data) => {
    const token = data.accessToken as string
    const porintLogin = {
      ...data,
      applicationType: ApplicationType.Application,
      id: data.userId?.toString(),
      name: data.userName,
      departmentId: data.outpatientDepartmentId?.toString(),
      tenantName: data.tenantName || '',
      shortName: data.shortName || '',
      token,
      stationCategory: data.stationCategory,
    }
    let url = ''

    const hostname = window.location.hostname

    // win = window.open('http://his.test.hydhis.com/', '_blank')
    if (hostname.includes('test')) {
      url = 'http://his.test.hydhis.com/'
    } else if (hostname.includes('develop')) {
      url = 'http://ih.develop.hydhis.com/'
    } else {
      // url = 'http://localhost:3001/'
      url = 'https://www.hydhis.cn/'
    }
    const win = window.open(url, JSON.stringify(porintLogin))
    win?.focus()
    // api.dispatch(
    //   setTemporaryCredentials({
    //     ...data,
    //     applicationType: ApplicationType.Application,
    //     id: data.userId?.toString(),
    //     name: data.userName,
    //     departmentId: data.outpatientDepartmentId?.toString(),
    //     tenantName: data.tenantName || '',
    //     shortName: data.shortName || '',
    //     token,
    //     stationCategory: data.stationCategory,
    //   })
    // )
  })
})

export const getSmsCode = createAsyncThunk<void, string, RootThunkApi>(
  'login/getSmsCode',
  async (params, api) => {
    return sendAsync(apiGetSmsCode(params), api)
  }
)

// 人脸识别
export const getFaceLogin = createAsyncThunk<void, string, RootThunkApi>(
  'login/getFaceLogin',
  async (params, api) => {
    return sendAsync(faceLogin(params), api)
  }
)

export const getThirdPartyLoginUrl = createAsyncThunk<
  string,
  ThirdPartyLoginParams,
  RootThunkApi
>('login/getThirdPartyLoginUrl', async (params, api) => {
  return sendAsync(apiGetThirdPartyLoginUrl(params), api)
})

export const getThirdPartyLoginToken = createAsyncThunk<
  void,
  ThirdPartyLoginTokenParams,
  RootThunkApi
>('login/getThirdPartyLoginToken', async (params, api) => {
  return sendAsync(apiGetThirdPartyLoginToken(params), api).then((data) => {
    if (params.isLogin) {
      const token = data.accessToken as string
      api.dispatch(
        setCredentials({
          creds: {
            ...data,
            applicationType: ApplicationType.Application,
            id: data.userId?.toString(),
            name: data.userName,
            departmentId: data.outpatientDepartmentId?.toString(),
            tenantName: data.tenantName || '',
            shortName: data.shortName || '',
            token,
            endTime: data.endTime,
            stationCategory: data.stationCategory,
          },
          rememberMe: true,
        })
      )
    }
  })
})

export const unbindAccount = createAsyncThunk<void, AccountType, RootThunkApi>(
  'login/unbindAccount',
  async (params, api) => {
    sendAsync(apiUnbindAccount(params), api)
  }
)

export const getScanUrl = createAsyncThunk<string, void, RootThunkApi>(
  'login/getScanUrl',
  async (_params, api) => {
    return sendAsync(apiGetScanUrl(), api)
  }
)

export const replaceAccessToken = createAsyncThunk<
  ShopperTokenValue,
  void,
  RootThunkApi
>('login/replace', async (_, api) => {
  return sendAsync(replaceToken(), api)
})

export const queryScanStatus = createAsyncThunk<
  number,
  ScanQueryParams,
  RootThunkApi
>('login/queryScanStatus', async (params, api) => {
  return sendAsync(apiQueryScanStatus(params), api).then((data) => {
    if (data.status === 2) {
      const auth = data.authInfoDTO
      api.dispatch(
        setCredentials({
          creds: {
            ...data,
            applicationType: ApplicationType.Application,
            id: auth.userId?.toString(),
            name: auth.userName,
            departmentId: auth.outpatientDepartmentId?.toString(),
            tenantName: auth.tenantName || '',
            shortName: auth.shortName || '',
            token: auth.accessToken,
            endTime: auth.endTime,
            stationCategory: auth.stationCategory,
          },
          rememberMe: true,
        })
      )
    }
    return data.status
  })
})

// 电子发票三方授权
export const invoiceTokenAsync = createAsyncThunk<
  void,
  InvoiceTokenParams,
  RootThunkApi
>('login/invoiceTokenAsync', async (params, api) => {
  return sendAsync(invoiceToken(params), api)
})

export const { setSmsCountdown } = loginSlice.actions

export const selectTenants = (state: RootState): Tenant[] => state.login.tenants

export const selectSmsCountdown = (state: RootState): number =>
  state.login.smsCountdown

export default loginSlice.reducer
