import { SkinTestStatus } from "./treatment";

export enum OrderCategory {
  Jia = 1,
  Yi = 2,
  Bing = 3,
  Mixed = 4,
}

export function getOrderCategoryName(category: OrderCategory): string {
  switch (category) {
    case OrderCategory.Jia:
      return "甲";
    case OrderCategory.Yi:
      return "乙";
  }
  return "-";
}

export enum OtcCategory {
  Prescription = 0,
  Otc = 1,
  DualPrescription = 2,
}

export function getOtcCategoryName(category: OtcCategory): string {
  switch (category) {
    case OtcCategory.Prescription:
      return "处方药";
    case OtcCategory.Otc:
      return "OTC";
    case OtcCategory.DualPrescription:
      return "处方药双轨";
  }
  return "";
}

export interface Material {
  id: string;
  accountUnit: string;
  antibioticTag: number;
  approvalNumber: string;
  insuranceCode?: string;
  batchNo: string;
  barCode: string;
  basicMedicineTag: number;
  billingCategory: string;
  count: number;
  compoundTag: number;
  doseCategory: number;
  doseUnit: string;
  deadline: string;
  drugCategory: string;
  ephedrineTag: number;
  freezeTemperature: string;
  genericName: string;
  inventoryAlert: number;
  materialsCategory: number;
  miscNo: string;
  mnemonicCode: string;
  name: string;
  no: string;
  normalDose: number;
  normalFrequency: string;
  normalUsemethod: string;
  orderCategory: OrderCategory;
  origin: string;
  otc: number;
  packSpec: string;
  productionLicence: string;
  putCategory: string;
  qualityControlCategory: string;
  reportNo: string;
  retailPrice: number;
  skinTestTag: SkinTestStatus;
  source: number;
  sourceMaterialsCatalogId: string;
  spec: string;
  splitPrice: number;
  splitScale: number;
  splitTag: number;
  splitUnit: string;
  splitCount: number;
  state: number;
  status: number;
  stimulantTag: number;
  stockPrice: number;
  tenantSupplierId: string;
  tenantSupplierName: string;
  tradeRemark: string;
  transitionScale: number;
  useDays: string;
  drugCount: number;
  groupNumber: number;
  sort: number;
  drugType: number;
  tip: number;
  countPerTip: number;
  materialsCatalogId: string;
  inventoryLedgerId: string;
  treatmentServiceId: string;
  inventoryInfo: string;
  unShelveInfo: string;
  skinTestInfo: string;
  storehouseId: string;
  storehouseName: string;
  drugAmount: number;
  shipmentsState: number;
  materTreatPrice: number;
  remark: string;
  tenantMaterialsCatalogId: string;
  productDate: string;
  productInfo: string;
  unitPrice: number;
  splitUnitPrice: number;
}

export function fromMaterialJson(json: any): any {
  return {
    id: json.id?.toString() || "",
    accountUnit: json.accountUnit,
    antibioticTag: json.antibioticTag,
    approvalNumber: json.approvalNumber,
    batchNo: json.batchNo,
    barCode: json.barCode,
    deadline: json.deadline,
    basicMedicineTag: json.basicMedicineTag,
    billingCategory: json.billingCategory,
    compoundTag: json.compoundTag,
    doseCategory: json.doseCategory,
    count: json.count,
    doseUnit: json.doseUnit,
    drugCategory: json.drugCategory,
    ephedrineTag: json.ephedrineTag,
    freezeTemperature: json.freezeTemperature,
    genericName: json.genericName,
    inventoryAlert: json.inventoryAlert,
    materialsCategory: json.materialsCategory,
    miscNo: json.miscNo,
    mnemonicCode: json.mnemonicCode,
    name: json.name,
    normalDose: json.normalDose,
    normalFrequency: json.normalFrequency,
    normalUsemethod: json.normalUsemethod,
    orderCategory: json.orderCategory,
    origin: json.origin,
    otc: json.otc,
    drugType: json.drugType,
    packSpec: json.packSpec,
    productionLicence: json.productionLicence,
    putCategory: json.putCategory,
    qualityControlCategory: json.qualityControlCategory,
    reportNo: json.reportNo,
    retailPrice: json.retailPrice,
    skinTestTag: json.skinTestTag,
    source: json.source,
    sourceMaterialsCatalogId: json.sourceMaterialsCatalogId?.toString() || "",
    spec: json.spec,
    splitPrice: json.splitPrice,
    splitScale: json.splitScale,
    splitTag: json.splitTag,
    splitUnit: json.splitUnit,
    splitCount: json.splitCount,
    state: json.state,
    status: json.status,
    stimulantTag: json.stimulantTag,
    stockPrice: json.stockPrice,
    tenantSupplierId:
      json.tenantSupplierId > 0 ? json.tenantSupplierId?.toString() : "",
    tenantSupplierName: json.tenantSupplierName,
    tradeRemark: json.tradeRemark,
    transitionScale: json.transitionScale,
    useDays: json.useDays,
    drugCount: json.drugCount,
    groupNumber: json.groupNumber,
    sort: json.sort === undefined ? 0 : json.sort,
    tip: json.tip || 1,
    countPerTip: json.countPerTip,
    materialsCatalogId:
      json.materialsCatalogId > 0 ? json.materialsCatalogId?.toString() : "",
    inventoryLedgerId:
      json.inventoryLedgerId > 0 ? json.inventoryLedgerId?.toString() : "",
    treatmentServiceId:
      json.treatmentServiceId > 0 ? json.treatmentServiceId?.toString() : "",
    inventoryInfo: json.inventoryInfo,
    unShelveInfo: json.unShelveInfo,
    skinTestInfo: json.skinTestInfo,
    storehouseId: json.storehouseId,
    storehouseName: json.storehouseName,
    drugAmount: json.drugAmount,
    shipmentsState: json.shipmentsState,
    materTreatPrice: json.materTreatPrice,
    remark: json.remark,
    tenantMaterialsCatalogId: json.tenantMaterialsCatalogId,
    productDate: json.productDate,
    productInfo: json.productInfo,
    unitPrice: json.unitPrice,
    splitUnitPrice: json.splitUnitPrice,
  };
}

export function fromTreatmentJson(json: any): Material {
  return {
    id: json.id,
    name: json.name,
    accountUnit: json.accountUnit,
    billingCategory: json.billingCategory,
    mnemonicCode: json.mnemonicCode,
    insuranceCode: json.insuranceCode,
    no: json.no,
    retailPrice: json.retailPrice,
    state: json.state,
    status: json.status,
  } as Material;
}
