/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import EChartsReact from 'echarts-for-react'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'

export interface LineChartsProps {
  xData?: any
  tag?: any
  dataTwo?: any
  title?: any
  data?: any
  style?: React.CSSProperties
}

export const LineCharts: React.FC<LineChartsProps> = ({
  xData,
  tag,
  title,
  data,
  dataTwo,
  style,
}): ReactElement => {
  const [LineOption, setLineOption] = useState<any>()

  console.log(data, dataTwo, '11111111111111')

  useEffect(() => {
    if (data || dataTwo) {
      const option = {
        legend: {
          data: ['收缩压', '舒张压'],
        },
        grid: {
          left: '8%',
          right: '2%',
          bottom: '15em',
          top: '32em',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          // formatter: '<font>数据: {c0}</font><br />',
        },
        xAxis: {
          type: 'category',
          data: xData,
          splitLine: {
            show: false,
          },
          axisLabel: {
            interval: 0,
          },
        },
        yAxis: [
          {
            name: title,
            type: 'value',
            splitNumber: 4,
            //网格线样式
            splitLine: {
              show: true,
            },
            //Y轴样式
            axisLine: {
              show: false,
              symbol: ['none', 'arrow'],
            },
            axisLabel: {
              show: true,
              min: 0,
              // eslint-disable-next-line react/prop-types
              max: data,
            },
          },
          {
            name: '',
            type: 'value',
            splitNumber: 4,
            //网格线样式
            splitLine: {
              show: false,
              lineStyle: {
                color: ['#061F72'],
                width: 1,
                type: 'solid',
              },
            },
            //Y轴样式
            axisLine: {
              show: false,
              symbol: ['none', 'arrow'],
              lineStyle: {
                color: '#027AFF',
              },
            },
            axisLabel: {
              show: false,
              min: 0,
              max: dataTwo,
            },
          },
        ],
        series: [
          {
            name: tag ? '舒张压' : '数据',
            data: data,
            type: 'line',
            smooth: true,
            // yAxisIndex: 0,
            lineStyle: {
              color: '#2D7BFF',
            },
            emphasis: {
              focus: 'series',
            },
          },
          {
            name: tag ? '收缩压' : '数据',
            data: dataTwo,
            type: 'line',
            smooth: true,
            // yAxisIndex: 1,
            lineStyle: {
              color: '#75bbc4',
            },
            emphasis: {
              focus: 'series',
            },
          },
        ],
      }
      setLineOption(option)
    }
  }, [data, dataTwo])
  return <>{LineOption && <EChartsReact option={LineOption} style={style} />}</>
}
