/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 09:24:25
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-27 16:11:36
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface DepartmentManagementListPrams {
  current: number
  size: number
  hisDepartmentName?: string
}

// 列表
export function departmentManagementList(
  body: DepartmentManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/detail`,
    method: 'POST',
    body,
  }
}

// 详情
export function departmentManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/target`,
    method: 'GET',
    params: { id },
  }
}

export interface DepartmentManagementEditPrams {
  hisDepartmentName?: string
  deptLogoUrl?: string
  id?: string
}

// 保存
export function departmentManagementEditSubmit(
  body: DepartmentManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/saveOrUpdate`,
    method: 'POST',
    body,
  }
}

export interface DepartmentManagementUploadPrams {
  id?: any
  flag?:any
}

// 保存
export function departmentManagementUpload(
  params: DepartmentManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/changeDisabledFlg`,
    method: 'GET',
    params,
  }
}

export interface IsSuggestDepartmentManagementUploadPrams {
  id?: any
  isSuggest?: any
}

// 保存
export function isSuggestDepartmentManagementUpload(
  params: IsSuggestDepartmentManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/suggestChange`,
    method: 'GET',
    params,
  }
}


export function ossUrl(file: any): Request {
  return {
    url: `${API_PREFIX}/oss/create`,
    method: 'POST',
    // headers: {
    //   [ContentType]: MultiPartFormData,
    // },
    body: file,
  }
}

