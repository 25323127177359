/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-16 16:00:34
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { getAppointmentList } from './appointmentListSlice'
import { columns } from './column'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

export interface AppointmentListRef {
  refresh?: () => void
  tabKey?: any
}

interface AppointmentListProps {
  tabKey?: any
}
const RealAppointmentList = (
  { tabKey }: AppointmentListProps,
  ref: React.Ref<AppointmentListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [sortedInfo, setSorter] = useState<any>()

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    if (params?.startTime && params?.endTime)
      dispatch(
        getAppointmentList({
          ...params,
          current: params.current,
          size: params.size,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setPage({
            items: res.records,
            total: res.total,
          })
        })
        .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (
      sortedInfo?.order === 'ascend' &&
      sortedInfo?.columnKey === 'appointDate'
    ) {
      setParams({
        ...params,
        orderType: 1,
        orderDocterType: undefined
      })
    } else if (
      sortedInfo?.order === 'descend' &&
      sortedInfo?.columnKey === 'appointDate'
    ) {
      setParams({
        ...params,
        orderType: 0,
        orderDocterType: undefined
      })
    } else if (
      sortedInfo?.order === 'ascend' &&
      sortedInfo?.columnKey === 'docterName'
    ) {
      setParams({
        ...params,
        orderType: undefined,
        orderDocterType: 1,
      })
    } else if (
      sortedInfo?.order === 'descend' &&
      sortedInfo?.columnKey === 'docterName'
    ) {
      setParams({
        ...params,
        orderType: undefined,
        orderDocterType: 0,
      })
    } else {
      setParams({
        ...params,
        orderType: undefined,
        orderDocterType: undefined
      })
    }
    console.log(sortedInfo?.order, 'sortedInfo?.order')
  }, [sortedInfo])

  // useEffect(() => {
  //   if (
  //     sortedDocterInfo?.order === 'ascend' &&
  //     sortedDocterInfo?.columnKey === 'docterName'
  //   ) {
  //     setParams({
  //       ...params,
  //       orderDocterType: 1,
  //       // finishInspectionNumberSort: undefined,
  //       // recentlyApplicationTimeSort: undefined,
  //       // othersSort: undefined,
  //     })
  //   } else if (
  //     sortedDocterInfo?.order === 'descend' &&
  //     sortedDocterInfo?.columnKey === 'docterName'
  //   ) {
  //     setParams({
  //       ...params,
  //       orderDocterType: 0,
  //     })
  //   } else {
  //     setParams({
  //       ...params,
  //       orderDocterType: undefined,
  //     })
  //   }
  //   console.log(sortedDocterInfo?.order, 'sortedInfo?.order')
  // }, [sortedDocterInfo])


  useEffect(() => {
    getPage()
  }, [params, tabKey])

  const exportList = () => {
    const theader = [
      '预约ID',
      '门诊号',
      '姓名',
      '性别',
      '年龄',
      '身份证号',
      '手机号',
      '预约时间',
      '预约科室',
      '预约医生',
      // '预约来源',
      '创建时间',
      '状态',
    ]
    const filterVal = [
      'id',
      'medicalCardNo',
      'name',
      'sex',
      'age',
      'identityCard',
      'phone',
      'appointDate',
      'departmentName',
      'docterName',
      // '',
      'createTime',
      'status',
    ]
    dispatch(getAppointmentList({ ...params, current: 1, size: 10000 }))
      .then(unwrapResult)
      .then((res: any) => {
        const data = res.records
          .map((v: any) => ({
            ...v,
            status:
              v?.status == 1 ? '预约成功' : v?.status == 0 ? '已取消' : '-',
            sex: getGenderName(v?.sex),
            age: getAge(v?.ageYear, v?.ageMonth),
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '预约列表',
            autoWidth: true,
          })
        })
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        margin: ' 10px',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '15px  15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          export={() => {
            exportList()
          }}
          onValueChange={(v: any) => {
            setParams({
              ...params,
              ...v,
              current: 1,
              startTime: v?.timeHead,
              endTime: v?.timeTail,
              timeHead: undefined,
              timeTail: undefined,
            })
            setSorter(undefined)
          }}
        />
        <EditableList
          rowKey={(_: any, i: any) => `${i}}`}
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          onChange={(a, b, sorter: any) => {
            setSorter(sorter)
            console.log(sorter, a, b, 'sorter?.order')
          }}
          columns={columns((action: any, v: any) => {
            return
          }, params.size * (params.current - 1), sortedInfo)}
          onChangePage={(current, size) => {
            console.log(params, 'change')
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>
    </Col>
  )
}

export const AppointmentList = forwardRef(RealAppointmentList)
