/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 09:59:26
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-13 15:55:47
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface DivisionManagementListPrams {
  current: number
  size: number
}

// 列表
export function divisionManagementList(
  params: DivisionManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/ihtenantuserdepartment/list`,
    method: 'GET',
    params,
  }
}

// 部门人员列表
export function departCountWithUserList(name?: any): Request {
  return {
    url: `${API_PREFIX}/ihtenantuserdepartment/departCountWithUser`,
    method: 'POST',
    params: { name },
  }
}

// 详情
export function divisionManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminTenantDepartment/target`,
    method: 'GET',
    params: { id },
  }
}

export interface DivisionManagementEditPrams {
  name?: string
  id?: string
}

// 保存
export function divisionManagementEditSubmit(
  body: DivisionManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/ihtenantuserdepartment/saveorupdate`,
    method: 'POST',
    body,
  }
}

//删除
export function divisionManagementDelete(
  id: number
): Request {
  return {
    url: `${API_PREFIX}/ihtenantuserdepartment/remove?ids=${id}`,
    method: 'POST',
  }
}
