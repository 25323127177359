// 角色 0：普通用户，1：机构内部管理员，2：超级管理员
export enum Role {
  User = 0,
  Manager = 1,
  Administrator = 2,
}

export enum Gender {
  Unknown = 0,
  Male = 1,
  Female = 2,
}

export const GenderOptions = [Gender.Male, Gender.Female, Gender.Unknown]

export const GenderNames = ["未知", "男", "女"]

export const SexNames = ["female", "male", "female"]

export function getGenderName(gender: Gender): string {
  if (gender < 0 || gender >= GenderNames.length) {
    return ""
  }
  return GenderNames[gender]
}

export function getSexName(gender: Gender): string {
  if (gender < 0 || gender >= GenderNames.length) {
    return ""
  }
  return SexNames[gender]
}

// 岗位类别 0护士 1医生/药师  2库房管理员 3柜台
export enum StationCategory {
  Nurse = 0,
  DoctorOrPharmacist = 1,
  Warehouse = 2,
  Count = 3,
  Counselor = 4,
}

export const StationCategories = [
  StationCategory.DoctorOrPharmacist,
  StationCategory.Nurse,
  StationCategory.Warehouse,
  StationCategory.Count,
  StationCategory.Counselor,
]

export const StationCategoryNames = [
  '护士',
  '医生/药师',
  '库房管理员',
  '柜台',
  '咨询师',
]

export function getStationCategoryName(category: StationCategory): string {
  if (category < 0 || category >= StationCategoryNames.length) {
    return ""
  }
  return StationCategoryNames[category]
}

export enum StationProperty {
  OnJob = 0,
  Contract = 1,
}

export const StationProperties = [
  StationProperty.OnJob,
  StationProperty.Contract,
]

export function getStationPropertyName(property: StationProperty): string {
  if (property == StationProperty.OnJob) {
    return "行政在职"
  } else if (property == StationProperty.Contract) {
    return "行政合同"
  }
  return ""
}

export interface User {
  id: string
  alipayUserId: string
  name: string
  gender: Gender
  phone: string
  role: Role
  password: string
  mnemonicCode: string
  state: number
  stationCategory: StationCategory
  stationProperty: StationProperty
  departmentId: string
  departmentName: string
  outpatientId: string
  outpatientName: string
  doctorCode: string
  email: string
  identityCard: string
  countryCode: string
  createTime: string
  innerDepartmentId: string
  innerDepartmentName: string
  outpatientDepartmentId: string
  outpatientDepartmentName: string
  practiceCertificate: string
  qualificationCertificate: string
  realname: string
  roleFilter: string
  sex: string
  status: string
  diagnoses?: string
  prescription?: string
  tenantId?: string
}

export function fromJson(json: any): User {
  return {
    id: json.id?.toString() || '',
    name: json.realname,
    gender: json.sex,
    phone: json.phone,
    realname: json.realname,
    roleFilter: json.roleFilter,
    alipayUserId: json.alipayUserId || '',
    role: json.role,
    password: '',
    mnemonicCode: json.mnemonicCode,
    state: json.state,
    stationCategory: json.stationCategory,
    stationProperty: json.stationProperty,
    departmentId: json.innerDepartmentId?.toString(),
    departmentName: json.innerDepartmentName,

    outpatientId: json.outpatientDepartmentId?.toString(),
    outpatientName: json.outpatientDepartmentName,
    doctorCode: json.doctorCode,
    email: json.email,
    identityCard: json.identityCard,
    countryCode: json.countryCode,
    createTime: json.createTime,
    innerDepartmentId: json.innerDepartmentId,
    innerDepartmentName: json.innerDepartmentName,
    outpatientDepartmentId: json.outpatientDepartmentId,
    outpatientDepartmentName: json.outpatientDepartmentName,
    practiceCertificate: json.practiceCertificate,
    qualificationCertificate: json.qualificationCertificate,
    sex: json.sex,
    status: json.status,
    diagnoses: json.diagnoses,
    prescription: json.prescription,
    tenantId: json.tenantId,
  }
}

export function toJson(user: User): any {
  return {
    id: user.id,
    realname: user.name,
    sex: user.gender,
    phone: user.phone,
    password: user.password,
    mnemonicCode: user.mnemonicCode,
    stationCategory: user.stationCategory,
    stationProperty: user.stationProperty,
    innerDepartmentId: user.departmentId,
    innerDepartmentName: user.departmentName,
    roleFilter: user.roleFilter,
    outpatientDepartmentId: user.outpatientId,
    outpatientDepartmentName: user.outpatientName,
    practiceCertificate: user.practiceCertificate,
    doctorCode: user.doctorCode,
    tenantRole: user.role,
    name: user.name,
    gender: user.gender,
    role: user.role,
    email: user.email,
    identityCard: user.identityCard,
    countryCode: user.countryCode,
    createTime: user.createTime,
    qualificationCertificate: user.qualificationCertificate,
    status: user.status,
    state: user.state,
    departmentId: user.departmentId,
    departmentName: user.departmentName,
    outpatientId: user.outpatientId,
    diagnoses: user.diagnoses,
    prescription: user.prescription,
    tenantId: user.tenantId,
  }
}
