import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { columns } from './columns'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { EditableList } from '../../compnents/list/EditableList'
import {
  getChangeStateSubmit,
  getDeleteSubmit,
  getMedicalGuideList,
} from './medicalGuideSlice'
import { DateSelect } from '../../compnents/form/DateSelect'
import { useHistory } from 'react-router-dom'
import {
  getReleaseStateName,
  ReleaseStateCategories,
} from '../../models/health'
import { MedicalGuideDetailModal } from './medicalGuideDetailModal'

const MedicalGuides = (): // { tabKey }: ReservationChannelProps,
ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const history: any = useHistory()

  const [
    isMedicalGuideDetailVisible,
    setIsMedicalGuideDetailVisible,
  ] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [id, setId] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(
      getMedicalGuideList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '10px 15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            console.log(values, 'values')

            setParams({
              ...params,
              ...values,
              current: 1,
              pushTimeStart: values?.timeHead,
              pushTimeEnd: values?.timeTail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='title' noStyle>
                <Input
                  placeholder='标题'
                  style={{ width: 200 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <DateSelect
                placeholder='发布时间'
                labelPrefix='发布时间-'
                namePrefix='time'
                style={{ width: '8.5rem' }}
                onChange={form.submit}
              />
              <Form.Item noStyle name='state'>
                <Select
                  placeholder='发布状态/全部'
                  allowClear
                  style={{ width: 140 }}
                  onChange={() => form.submit()}
                >
                  {ReleaseStateCategories?.map((v) => {
                    return (
                      <Option value={v} key={v}>
                        {getReleaseStateName(v)}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <Space>
              <Button
                type='primary'
                onClick={() => {
                  history.push({
                    pathname: '/admin/medicalGuideList/addOrEditDetail',
                  })
                }}
              >
                新增就医指南
              </Button>
            </Space>
          </Row>
        </Form>
        <EditableList
          rowKey={(_, i) => `${i}}`}
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns((action: any, v: any, e: any) => {
            switch (action) {
              case 'withdraw':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认撤回？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getChangeStateSubmit({ id: v?.id, state: 2 }))
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })
                  },
                })
                break
              case 'publish':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认发布？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getChangeStateSubmit({ id: v?.id, state: 1 }))
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })
                  },
                })
                break
              case 'delete':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认删除？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getDeleteSubmit({ id: v?.id }))
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })
                  },
                })
                break
              case 'edit':
                history.push({
                  pathname: '/admin/medicalGuideList/addOrEditDetail',
                  state: {
                    id: v.id,
                  },
                })
                break
              case 'detail':
                setIsMedicalGuideDetailVisible(true)
                setId(v.id)
                break
            }
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            console.log(params, 'change')
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>

      <MedicalGuideDetailModal
        id={id}
        visible={isMedicalGuideDetailVisible}
        onCancel={() => {
          setIsMedicalGuideDetailVisible(false)
          setId('')
          getPage()
        }}
        onOk={() => {
          setIsMedicalGuideDetailVisible(false)
          setId('')
          getPage()
        }}
      />
    </Col>
  )
}

export const MedicalGuideList = forwardRef(MedicalGuides)
