import { API_PREFIX } from "./constant";
import { Request } from "./request";

export interface GetDiseaseCompletionParams {
  current?: number;
  size?: number;
  param?: string;
}

export function getDiseaseCompletions(
  params: GetDiseaseCompletionParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/diseasecode/list`,
    method: "GET",
    params,
  };
}
