/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 14:19:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 14:21:00
 */
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'

// 获取-挂号-医生
export interface getAllDoctorListFnPrams {
  size: number
  state: number
  stationCategory: number
  outpatientDepartmentId?: string
}
export const getAllDoctorListFn = (
  params: getAllDoctorListFnPrams
): Request => {
  return {
    url: `${API_PREFIX}/blade-user/user/listWithBusyInfo`,
    method: 'GET',
    params,
  }
}
