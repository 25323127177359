/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-04-13 16:13:55
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-16 18:07:57
 */

import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormatSimple } from '../../../../models/datetime'
import { getGenderName } from '../../../../models/user'
import { getAge } from '../../../../utils/StringUtils'

type ActionType = 'send'

export const Columns = (
  onClick: (action: ActionType, v: any) => void,
  clickDelete: (id: number) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '门诊号',
      dataIndex: 'medicalCardNo',
      align: 'center',
      key: 'medicalCardNo',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.medicalCardNo ? r?.medicalCardNo : '-'
      },
    },
    {
      title: '就诊人姓名',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.name ? r?.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.patientSex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return getAge(r?.patientAge, r?.patientMonth, '岁')
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.identityCard ? r.identityCard : '-'
      },
    },
    {
      title: '就诊人手机号',
      dataIndex: 'patientPhone',
      align: 'center',
      key: 'patientPhone',
      width: '7rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.patientPhone ? r?.patientPhone : '-'
      },
    },
    {
      title: '活动日期',
      dataIndex: 'appointmentDate',
      align: 'center',
      key: 'appointmentDate',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.appointmentDate
          ? moment(r?.appointmentDate).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '活动班次',
      dataIndex: 'classes',
      align: 'center',
      key: 'classes',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.classes == 0 ? '上午' : r?.classes == 1 ? '下午' : '-'
      },
    },
    {
      title: '操作',
      dataIndex: 'result',
      align: 'center',
      key: 'result',
      width: '10rem',
      ellipsis: true,
      render: function detail(_, t): ReactElement {
        return (
          <>
            {/* {t?.isSendSms == 0 ? (
              <a
                onClick={(e) => {
                  onClick('send', t)
                }}
              >
                发送短信
              </a>
            ) : t?.isSendSms == 1 ? (
              <a
                onClick={(e) => {
                  onClick('send', t)
                }}
              >
                重发短信
              </a>
            ) : (
              ''
            )} */}
            <a
              // style={{ paddingLeft: '18px' }}
              onClick={() => {
                clickDelete(t.id)
              }}
            >
              取消
            </a>
          </>
        )
      },
    },
  ]
}

export const PrintColumns = (
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'no',
      align: 'center',
      width: '2rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      width: '3rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.name ? r?.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '2rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.patientSex)
      },
    },

    {
      title: '联系电话',
      dataIndex: 'patientPhone',
      align: 'center',
      key: 'patientPhone',
      width: '4rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.patientPhone ? r?.patientPhone : '-'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '7rem',
      ellipsis: true,
    },
    {
      title: '门诊号',
      dataIndex: 'medicalCardNo',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
    {
      title: '班次',
      dataIndex: 'classes',
      align: 'center',
      width: '2rem',
      ellipsis: true,
      render: (_, r) => {
        return r?.classes == 0 ? '上午' : r?.classes == 1 ? '下午' : '-'
      },
    },
    {
      title: '代煎/自费',
      dataIndex: '',
      align: 'center',
      width: '4rem',
      ellipsis: true,
    },
    {
      title: '地址',
      dataIndex: 'address',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.address ? r?.address : '-'}
          </div>
        )
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      width: '3rem',
      ellipsis: true,
    },
  ]
}
