/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:43:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-15 10:10:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/reservationChannel'

// 列表
export const getMedicalGuideList = createAsyncThunk<
  void,
  DT.MedicalGuideListPrams,
  RootThunkApi<void>
>('clinic/getReservationChannelList', async (params, api) => {
  return sendAsync(DT.medicalGuideList(params), api)
})

// 新增编辑保存
export const getMedicalGuideUpload = createAsyncThunk<
  void,
  DT.MedicalGuideEditPrams,
  RootThunkApi<void>
>('clinic/getReservationChannelEditSubmit', async (params, api) => {
  return sendAsync(DT.medicalGuideEditSubmit(params), api)
})

// 详情
export const getMedicalGuideDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getReservationChannelEditSubmit', async (params, api) => {
  return sendAsync(DT.medicalGuideDetail(params), api)
})

// ，重新绑定/解除绑定
export const getReservationChannelUpload = createAsyncThunk<
  void,
  DT.ReservationChannelUploadPrams,
  RootThunkApi<void>
>('clinic/getReservationChannelUpload', async (params, api) => {
  return sendAsync(DT.reservationChannelUpload(params), api)
})

// 撤回,发布
export const getChangeStateSubmit = createAsyncThunk<
  void,
  DT.changeStateParams,
  RootThunkApi<void>
>('clinic/getReservationChannelUpload', async (params, api) => {
  return sendAsync(DT.changeStateSubmit(params), api)
})

// 删除
export const getDeleteSubmit = createAsyncThunk<
  void,
  DT.deleteParams,
  RootThunkApi<void>
>('clinic/getReservationChannelUpload', async (params, api) => {
  return sendAsync(DT.deleteSubmit(params), api)
})


// oss图片
export const getTinyUrl = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/getTinyUrl',
  async (params, api) => {
    return sendAsync(DT.tinyUrl(params), api)
  }
)