/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 11:34:14
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../app/store'
import { sendAsync } from '../../../app/applicationSlice'
import * as DT from '../../../services/maintenanceWorkOrder'

// 列表
export const getMaintenanceWorkOrderList = createAsyncThunk<
  void,
  DT.MaintenanceWorkOrderPrams,
  RootThunkApi<void>
>('clinic/getMaintenanceWorkOrderList', async (params, api) => {
  return sendAsync(DT.maintenanceWorkOrderList(params), api)
})

// 详情
export const getMaintenanceWorkOrderDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getMaintenanceWorkOrderDetail', async (params, api) => {
  return sendAsync(DT.maintenanceWorkOrderDetail(params), api)
})



