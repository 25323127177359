/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:00:31
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect } from 'react'

import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { freePatientQueryParams } from '../../services/freePatients'
import { ChecklistManagementPrams } from '../../services/checklistManagement'

export const Query = (props: {
  onValueChange: (queries: ChecklistManagementPrams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                autoComplete='off'
                placeholder='就诊人姓名'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <DateSelect
              placeholder='时间范围'
              labelPrefix='时间范围-'
              namePrefix='time'
              style={{ width: '8.5rem' }}
              initialValue={DateRangeType.Today}
              onChange={form.submit}
            />

            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
