/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-25 19:43:49
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 09:42:29
 */
import { Action, configureStore, Dispatch, ThunkAction } from '@reduxjs/toolkit'
import completionReducer from '../features/completion/completionSlice'
import loginReducer from '../features/login/loglinSlice'
import printReducer from '../features/print/printSlice'
import settingsReducer from '../features/settings/settingsSlice'
import layoutReducer from '../layouts/layoutSlice'
import applicationReducer from './applicationSlice'
import freePatientsReducer from '../features/freePatients/freePatientSlice'

export const store = configureStore({
  reducer: {
    application: applicationReducer,
    completion: completionReducer,
    layout: layoutReducer,
    login: loginReducer,
    print: printReducer,
    settings: settingsReducer,
    freePatients: freePatientsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type RootDispatch = typeof store.dispatch
export type RootThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type RootThunkApi<RejectValue = void> = {
  state: RootState
  dispatch: Dispatch<any>
  // dispatch: RootDispatch
  // dispatch: ReturnType<RootDispatch>
  rejectValue: RejectValue
}
