import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as service from '../../services/member'
import { freePatientQueryParams } from '../../services/freePatients'
import * as DT from '../../services/menmberHexiao'

export const getMemberHexiaoList = createAsyncThunk<
  void,
  DT.MemberHexiaoListParams,
  RootThunkApi<void>
>('clinic/getMemberList', async (params, api) => {
  return sendAsync(DT.memberHexiaoList(params), api)
})
