/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-07-07 10:28:16
 * @LastEditors: linxi
 * @LastEditTime: 2022-09-21 09:41:31
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface NotificationCenterParams {
  current?: number
  size?: number
  startTime?: any
  endTime?: any
  flag?: any
  name?: any
  userId?: any
}

export function NotificationCenterList(
  params: NotificationCenterParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/list`,
    method: 'GET',
    params,
  }
}

// 机构名称检索列表
export interface GetOnlyOrgaztionParams {
  current?: number
  size?: number
  name?: string
  status?: number
}

export function getOnlyOrgaztions(params: GetOnlyOrgaztionParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/tenant/list`,
    method: 'GET',
    params,
  }
}

// 创建人
export function messageUserList(): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/getUser`,
    method: 'GET',
  }
}


// 新增保存接口
export interface NotificationCenterSubmitParams {
  tenantId?: number
}

export function NotificationCenterSubmit(
  body: NotificationCenterSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/submit`,
    method: 'POST',
    body,
  }
}

// 一键到期
export interface NotificationCenterExpireSubmitParams {
  id?: number
}

export function NotificationCenterExpireSubmit(
  body: NotificationCenterExpireSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/expire`,
    method: 'POST',
    body,
  }
}

// 一键延期
export interface NotificationCenterPostponeSubmitParams {
  id?: number
  days?: number
}

export function NotificationCenterPostponeSubmit(
  body: NotificationCenterPostponeSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/postpone`,
    method: 'POST',
    body,
  }
}

// 删除
export interface NotificationCenterDeleteSubmitParams {
  messageReadId?: number
  messageId?: number
}

export function NotificationCenterDeleteSubmit(
  body: NotificationCenterDeleteSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/deletedMessageRead`,
    method: 'POST',
    body,
  }
}

// 详情
export interface NotificationCenterDetailParams {
  id?: number
}

export function NotificationCenterDetailSubmit(
  params: NotificationCenterDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/message/detail`,
    method: 'GET',
    params,
  }
}
export function NotificationCenterDetailSubmit2(
  params: NotificationCenterDetailParams
): Request {
  return {
    url: `${API_PREFIX}/blade-administer/m/sysadvertising/detailPopup`,
    method: 'GET',
    params,
  }
}


