/*
 * @Descripttion:
 * @version:
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-01-07 11:21:56
 * @LastEditors: K
 * @LastEditTime: 2022-08-11 16:41:25
 */
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { Request } from './request'


export function getUserMenus(): Request {
  return {
    url: `${API_PREFIX}/auth/menu/list`,
    method: 'POST',
  }
}
