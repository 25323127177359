/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 14:28:44
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { columns } from './column'
import { getUserManagementList } from './userManagementSlice'
import { PatientListModal } from './patientModal'
import { EditUserDetailModal } from './editUserModal'

export interface UserManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface UserManagementProps {
  tabKey?: any
}
const RealUserManagement = (
  { tabKey }: UserManagementProps,
  ref: React.Ref<UserManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isPatientListVisible, setIsPatientListVisible] = useState(false)

  const [isEditVisible, setIsEditVisible] = useState(false)

  const [openId, setOpenId] = useState<string>()

  const [id, setId] = useState('')

  const [patientPhonenumber, setPatientPhonenumber] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getUserManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='账号列表'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '15px  15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Query
              onValueChange={(v) => {
                setParams({ ...params, ...v, current: 1 })
              }}
            />
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                switch (action) {
                  case 'lookDetail':
                    setIsPatientListVisible(true)
                    setOpenId(v.openid)
                    setPatientPhonenumber(v?.patientPhonenumber)
                    break
                  case 'edit':
                    setIsEditVisible(true)
                    setId(v.id)
                    setDetail({
                      patientPhonenumber: v?.patientPhonenumber,
                      name: v?.name,
                      isEmployee: v?.isEmployee,
                      isManager: v?.isManager,
                      departmentId: v?.departmentId,
                    })
                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
        </Col>
        <PatientListModal
          patientPhonenumber={patientPhonenumber}
          openid={openId}
          visible={isPatientListVisible}
          onCancel={() => {
            setIsPatientListVisible(false)
            setOpenId('')
            getPage()
          }}
          onOk={() => {
            setIsPatientListVisible(false)
            setOpenId('')
            getPage()
          }}
        />
        <EditUserDetailModal
          detail={detail}
          id={id}
          visible={isEditVisible}
          onCancel={() => {
            setIsEditVisible(false)
            setId('')
            getPage()
          }}
          onOk={() => {
            setIsEditVisible(false)
            setId('')
            getPage()
          }}
        />
      </TabPane>
    </TabBar>
  )
}

export const UserManagementList = forwardRef(RealUserManagement)
