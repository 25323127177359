/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 16:40:27
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-11 10:44:53
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { setData } from '../freePatients/freePatientSlice'
import { DemandWorkOrderTemplateList } from './demandWorkOrderTemplate/demandWorkOrderTemplate'
import { MaintenanceWorkOrderTemplateList } from './maintenanceWorkOrderTemplate/maintenanceWorkOrderTemplate'
// import { DemandWorkOrderList } from './demandWorkOrder/demandWorkOrder'
// import { MaintenanceWorkOrderList } from './maintenanceWorkOrder/maintenanceWorkOrder'

interface WorkOrderTemplateProps {
  tabKey?: any
  dataList?: any
}
const RealWorkOrderTemplateLists = ({
  tabKey,
  dataList,
}: WorkOrderTemplateProps): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [currentTabKey, setCurrentTabKey] = useState('0')

  const [name, setName] = useState('')


  return (
    <TabBar
      activeKey={currentTabKey}
      style={{ margin: '10px 20px' }}
      onChange={(v) => {
        setCurrentTabKey(v)
      }}
    >
      <TabPane key='0' tab='信息化需求工单'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DemandWorkOrderTemplateList
              tabKey={currentTabKey}
            />
          </Col>
        </Col>
      </TabPane>
      <TabPane key='1' tab='维修工单'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MaintenanceWorkOrderTemplateList
              tabKey={currentTabKey}
            />
          </Col>
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const WorkOrderTemplateList = forwardRef(RealWorkOrderTemplateLists)
