/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-30 09:43:40
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-29 17:45:29
 */ import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import styles from './Card.module.css'
import arrowLeft from './images/Arrowleft.png'
import arrowRight from './images/ArrowRight.png'
import noArrowRight from './images/rightIconArrow.png'

export interface DataCardProps {
  title?: string
  width?: number | string
  height?: number | string
  extra?: React.ReactNode
  style?: React.CSSProperties
  changeTime?: (v: any) => void
  visible?: any
}

export const DataCard: React.FC<DataCardProps> = ({
  title,
  width,
  height,
  extra,
  style,
  children,
  changeTime,
  visible,
}): ReactElement => {
  const [weekTime, setWeekTime] = useState<any>()

  const [paramsData, setParamsData] = useState<any>()

  const [weekTimeKey, setWeekTimeKey] = useState<any>('0')

  useEffect(() => {
    if (visible == 'true') {
      setWeekTimeKey('0')
      setWeekTime(getWeek())
    }
  }, [visible])

  useEffect(() => {
    if (visible == 'true') {
      const day = moment().format('YYYY/MM/DD')
      const oneMonthBefore = moment().subtract(6, 'days').format('YYYY/MM/DD')
      const paramsDatas = [oneMonthBefore, day]
      console.log(paramsDatas, 'paramsDatas')
      changeTime && changeTime(paramsDatas)
      setParamsData(paramsDatas)
    }
  }, [visible])

  useEffect(() => {
    setWeekTime(getWeek())
  }, [])

  const getWeek = (i?: any) => {
    // 获取周的日期
    const day = moment().format('YYYY/MM/DD')
    const oneMonthBefore = moment().subtract(6, 'days').format('YYYY/MM/DD')
    const paramsData = [oneMonthBefore, day]
    setParamsData(paramsData)
    setWeekTimeKey('0')

    console.log(day, oneMonthBefore, 'oneMonthBefore')
    return `${moment(paramsData[0]).format('MM/DD')} ~ ${moment(
      paramsData[1]
    ).format('MM/DD')}`
  }

  const reduceTime = () => {
    const startTime: any = paramsData[0]
    const endTime: any = paramsData[1]
    const day = moment(endTime).subtract(6, 'days').format('YYYY/MM/DD')
    const oneMonthBefore = moment(startTime)
      .subtract(6, 'days')
      .format('YYYY/MM/DD')
    const paramsDatas = [oneMonthBefore, day]
    setParamsData(paramsDatas)
    setWeekTime(
      `${moment(paramsDatas[0]).format('MM/DD')} ~ ${moment(
        paramsDatas[1]
      ).format('MM/DD')}`
    )
    setWeekTimeKey(weekTimeKey - 1)
    changeTime && changeTime(paramsDatas)
    console.log(paramsData, oneMonthBefore, day, weekTimeKey, 'weekTime')
  }

  const addTime = () => {
    const startTime: any = paramsData[0]
    const endTime: any = paramsData[1]
    const day = moment(endTime).add(6, 'days').format('YYYY/MM/DD')
    const oneMonthBefore = moment(startTime).add(6, 'days').format('YYYY/MM/DD')
    const paramsDatas = [oneMonthBefore, day]
    setParamsData(paramsDatas)
    setWeekTime(
      `${moment(paramsDatas[0]).format('MM/DD')} ~ ${moment(
        paramsDatas[1]
      ).format('MM/DD')}`
    )
    setWeekTimeKey(weekTimeKey + 1)
    changeTime && changeTime(paramsDatas)
    console.log(paramsData, oneMonthBefore, day, weekTimeKey, 'weekTime')
  }
  return (
    <div className={styles.card} style={{ width, height, ...style }}>
      <div className={styles.border}></div>

      {title && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            marginRight: 16,
            paddingLeft: 20,
          }}
        >
          <span
            style={{
              width: '70%',
              color: '#101010',
              fontSize: '1.125em',
              fontWeight: 'bold',
              margin: '13px 0 10px -10px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'start',
            }}
          >
            {title}
          </span>
          <div
            style={{
              flex: 1,
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <img
              src={arrowLeft}
              alt=''
              className={styles.iconImg}
              onClick={() => {
                reduceTime()
              }}
            ></img>
            <view>{weekTime}</view>
            {weekTimeKey == '0' ? (
              <img src={noArrowRight} alt='' className={styles.iconImg}></img>
            ) : (
              <img
                src={arrowRight}
                alt=''
                className={styles.iconImg}
                onClick={() => {
                  addTime()
                }}
              ></img>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default DataCard
