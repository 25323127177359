/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 11:21:04
 */
import { Button, Col, Form, Input, Modal, notification, Row } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getIntegralWriteOffSubmit } from './memberSlice'
import { FORMERR } from 'dns'

export const IntegralWriteOffModal = (props: {
  id?: string
  name?: any
  phone?: any
  points?: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  useEffect(() => {
    if (props?.visible) {
      form.setFieldsValue({ name: props?.name, phone: props?.phone })
    }
  }, [props?.visible])

  return (
    <div>
      <Modal
        title='积分核销'
        visible={props.visible}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        footer={null}
        width={600}
      >
        <Row gutter={20} style={{ marginLeft: '25px' }}>
          <Col span={18} style={{ marginBottom: '25px' }}>
            <Row>
              <Col>积分余额：</Col>
              <Col>{props?.points}</Col>
            </Row>
          </Col>
        </Row>
        <Form
          form={form}
          onFinish={(values) => {
            console.log(values, 'values')
            dispatch(
              getIntegralWriteOffSubmit({ ...values, memberId: props?.id })
            )
              .then(unwrapResult)
              .then((v: any) => {
                notification.success({
                  message: '操作成功',
                  duration: 3,
                })
                form.resetFields()
                props.onOk()
              })
          }}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <Row>
            <Col span={18}>
              <Form.Item label='用户手机号' name='phone'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label='用户昵称' name='name'>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item required={true} label='核销积分数量' name='payPoints'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label='兑换礼品名称' name='reason'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                style={{ float: 'right' }}
                onClick={() => {
                  form.resetFields()
                  props.onCancel()
                }}
              >
                取消
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ float: 'left' }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
