/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-11 11:28:26
 */

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Timeline,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getWorkTemplateTimeLineTitleEdit } from '../workOrderTemplateSlice'
import styles from './maintenanceWorkOrderTemplate.module.css'

interface editTimeLineTitleModalProps {
  id: any
  name?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditTimeLineTitleModal = ({
  id,
  name,
  visible,
  onCancel,
  onOk,
}: editTimeLineTitleModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  useEffect(() => {
    if (visible) {
      console.log(name, 'name')

      if (name) {
        form.setFieldsValue({
          name: name,
        })
      }
    }
  }, [visible, name])

  return (
    <Modal
      title={'编辑标题'}
      width={500}
      visible={visible}
      onOk={() => {
        form.validateFields().then((values) => {
          console.log(values, 'values')

          dispatch(
            getWorkTemplateTimeLineTitleEdit({
              id: id,
              name: values?.name,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({ message: '保存成功', duration: 3 })
              form.resetFields()
              onOk()
            })
            .catch(() => {
              notification.error({ message: '保存失败', duration: 3 })
              form.resetFields()
              onOk()
            })
        })
      }}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        name='dynamic_form_nest_item'
        onFinish={(v) => {
          form.resetFields()
          console.log(v, 'values')
        }}
        autoComplete='off'
        form={form}
      >
        <Form.Item
          label='标题'
          name='name'
          rules={[{ required: true, message: '请输入' }]}
        >
          <Input
            placeholder='请输入'
            style={{
              width: '250px',
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
