/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 09:52:54
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 11:10:55
 */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { useHistory, useLocation } from 'react-router-dom'
import { Query } from './Query'
import styles from './integralDetail.module.css'
import { unwrapResult } from '@reduxjs/toolkit'
import { getIntegralDetailList } from './integralDetailSliceSlice'
import { Col } from 'antd'
import { NavBar } from '../../../compnents/nav/NavBar'
import { Columns } from './column'
import moment from 'moment'
import { DateTimeFormatMessageSimple } from '../../../models/datetime'

export const IntegralDetailList = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [pageLoading, setPageLoading] = useState<any>(false) //表格 loading

  const [queries, setQueries] = useState<any>({ current: 1, size: 10 })

  const history: any = useHistory()

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const memberId = location.state.memberId

  const getPage = () => {
    setPageLoading(true)
    dispatch(getIntegralDetailList({ ...queries, memberId }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [queries])

  return (
    <>
      <Col
        style={{
          margin: '10px 20px',
        }}
      >
        <NavBar
          where={['会员列表', '积分明细']}
          backtrace={{
            name: '会员列表',
            path: '/admin/memberList',
            state: {
              tag: true,
            },
          }}
        />
      </Col>
      <div className={styles.content}>
        <Query
          onValueChange={(values) => {
            console.log(values, 'values')
            setQueries({
              ...queries,
              ...values,
              current: 1,
              startTime: values?.timeHead
                ? moment(values?.timeHead).format(DateTimeFormatMessageSimple)
                : undefined,
              endTime: values?.timeTail
                ? moment(values?.timeTail).format(DateTimeFormatMessageSimple)
                : undefined,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        />
        <EditableList
          className={styles.freePatients}
          loading={pageLoading}
          rowKey={(v, i) => v.id}
          page={{
            items: page.items,
            current: queries.current,
            size: queries.size,
            total: page.total,
          }}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size: size || 10 })
          }}
          columns={Columns(() => {
            console.log(222)
          }, queries.size * (queries.current - 1))}
        />
      </div>
    </>
  )
}
