import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  notification,
  Radio,
  Row,
  Select,
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './health.module.css'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { NavBar } from '../../compnents/nav/NavBar'
import { Editor } from '@tinymce/tinymce-react'
import TextArea from 'antd/lib/input/TextArea'
import FileUpload from '../../compnents/file/FileUpload'
import Upload, { RcFile } from 'antd/lib/upload'
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { getUrl } from '../hospitalIntroduce/hospitalIntroduceSlice'
import { UploadFile } from 'antd/lib/upload/interface'

import { getTinyUrl } from '../medicalGuide/medicalGuideSlice'
import { getOssUrl } from '../DepartmentManagement/departmentManagementSlice'
import { getHealthDetail, getHealthEditSubmit } from './healthSlice'
const { Option } = Select

export const buttonSize = 'large'

export const AddOrEditHealthContent = () => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const [loading, setLoading] = useState(false)

  const [imageUrl, setImageUrl] = useState<any>()

  const [videoUrl, setVideoUrl] = useState<any>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const history = useHistory()

  const [ContentBody, setContentBody] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const [radioType, setRadioType] = useState(1)

  const [fileList, setFileList] = useState<any[]>([])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传照片</div>
    </div>
  )

  const rowProps = {
    gutter: 4,
  }

  console.log(fileList, 'fileList')

  const colProps = { span: 8 }

  const firstColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 19 },
  }

  const handleImageUpload: any = (blobInfo: any, progress: any) =>
    new Promise((resolve, reject) => {
      console.log(blobInfo.blob(), 'blobInfo')
      const formData = new FormData()
      formData.append('file', blobInfo.blob())
      dispatch(getTinyUrl(formData))
        .then(unwrapResult)
        .then((v) => {
          console.log(v, 'blobInfo')
          resolve(v)
        })
        .catch(() => {
          reject('上传失败，请重新上传')
        })
    })

  useEffect(() => {
    if (location?.state?.id) {
      dispatch(getHealthDetail(location?.state?.id))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
          setContentBody(v?.content)
          setImageUrl(v?.knowledgeImg)
          setVideoUrl(v?.knowledgeVideo)
          setRadioType(v?.type)
          form.setFieldsValue({
            ...v,
          })
        })
    }
  }, [location?.state?.id])

  useEffect(() => {
    form.setFieldsValue({
      type: 1,
    })
  }, [])

  return (
    <>
      <Col
        className={styles.addOrEditDetailMain}
        style={{
          margin: '10px',
        }}
      >
        <>
          {location.state?.id ? (
            <NavBar
              where={['文章管理', '编辑']}
              backtrace={{
                name: '文章管理',
                path: '/admin/health',
                routeName: '文章管理',
              }}
            />
          ) : (
            <NavBar
              where={['文章管理', '新增']}
              backtrace={{
                name: '文章管理',
                path: '/admin/health',
                routeName: '文章管理',
              }}
            />
          )}
        </>
      </Col>
      <div className={styles.form}>
        <Form
          name='basic'
          form={form}
          labelCol={{
            span: 3,
          }}
          wrapperCol={{
            span: 21,
          }}
          onFinish={(values) => {
            dispatch(
              getHealthEditSubmit({
                ...detail,
                ...values,
                content: values?.content?.level?.content,
                knowledgeImg: imageUrl,
                knowledgeVideo: videoUrl,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                  duration: 3,
                  onClose: () => {
                    return
                  },
                })
                history.push({
                  pathname: '/admin/health',
                })
              })
            console.log(
              values,
              imageUrl,
              values?.content?.level?.content,
              'values'
            )

            return
          }}
        >
          <Row {...rowProps}>
            <Col {...colProps} span={16}>
              <Form.Item
                {...firstColumnLayout}
                name='type'
                label='类型'
                required
                rules={[{ required: true, message: '类型不能为空' }]}
              >
                <Radio.Group
                  defaultValue={1}
                  value={radioType}
                  onChange={(e) => {
                    setRadioType(e?.target?.value)
                    console.log(e, '111111111111')
                  }}
                >
                  <Radio value={1}>推文</Radio>
                  <Radio value={2} style={{ marginLeft: '20px' }}>
                    视频
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row {...rowProps}>
            <Col {...colProps} span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='图片'
                name='knowledgeImg'
                rules={[{ required: true, message: '请上传科室头图' }]}
              >
                <Row>
                  <Col>
                    <Upload
                      beforeUpload={(file) => {
                        if (file.size / 1024 < 2000) {
                          return true
                        } else {
                          notification.info({
                            message: '图片大于2Mb,请重新选择!',
                          })
                          return false
                        }
                      }}
                      accept={'.png,.jpg,.jpeg,bmp'}
                      name='avatar'
                      listType='picture-card'
                      className='avatar-uploader'
                      showUploadList={false}
                      customRequest={async ({ file }) => {
                        if (file) {
                          const formData = new FormData()
                          // 向 FormData 中添加新的属性值 append()方法
                          formData.append('file', file)
                          dispatch(getOssUrl(formData))
                            .then(unwrapResult)
                            .then((v) => {
                              setImageUrl(v)
                              console.log(v, 'blobInfo')
                            })
                            .catch(() => {
                              return
                            })
                        }
                      }}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt='avatar'
                          style={{ width: '100%' }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Col>
                  <Col
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                      margin: '0  0 6px 10px',
                    }}
                  >
                    <div style={{ fontSize: '18px' }}>尺寸 ：270*210</div>
                    <div style={{ fontSize: '14px', color: '#999999' }}>
                      支持.jpg、.png等图片格式
                    </div>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row {...rowProps}>
            <Col {...colProps} span={18}>
              <Form.Item
                {...firstColumnLayout}
                name='title'
                label='标题'
                required
                rules={[{ required: true, message: '标题不能为空' }]}
              >
                <TextArea showCount maxLength={20} rows={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col {...colProps} span={18}>
              <Form.Item
                {...firstColumnLayout}
                name='preface'
                label='导语'
                required
                rules={[{ required: true, message: '标题不能为空' }]}
              >
                <TextArea showCount maxLength={100} rows={2} />
              </Form.Item>
            </Col>
          </Row>
          {radioType == 2 && (
            <Row {...rowProps}>
              <Col {...colProps} span={18}>
                <Form.Item
                  name='knowledgeVideo'
                  label='上传视频'
                  {...firstColumnLayout}
                  rules={[{ required: true, message: '视频不能为空' }]}
                >
                  <Row>
                    <Col span={24}>
                      <Upload
                        beforeUpload={(file) => {
                          if (file.size / 1024 / 1024 > 10) {
                            notification.info({
                              message: '图片大于100MB,请重新选择!',
                            })
                          }
                        }}
                        showUploadList={false}
                        maxCount={1}
                        customRequest={async ({ file, onSuccess }) => {
                          console.log(file, 'filefilefile')
                          setFileList([file])
                          if (file instanceof File) {
                            const formData = new FormData()
                            // 向 FormData 中添加新的属性值 append()方法
                            formData.append('file', file)
                            await dispatch(getOssUrl(formData))
                              .then(unwrapResult)
                              .then((v) => {
                                setVideoUrl(v)
                                console.log(v, 'blobInfo')
                              })
                              .catch(() => {
                                return
                              })
                          }
                        }}
                      >
                        <Button icon={<UploadOutlined />}>上传视频</Button>
                      </Upload>
                    </Col>
                    <Col span={24}>
                      <Col
                        flex={1}
                        style={{
                          width: '120px',
                          height: '80px',
                          marginTop: '20px',
                        }}
                      >
                        <video
                          style={{ width: ' 100%', height: '100%' }}
                          controls
                          autoPlay
                          loop
                          muted
                          src={videoUrl || detail?.knowledgeVideo}
                          poster={videoUrl || detail?.knowledgeVideo}
                        ></video>
                        {/* <span
                              style={{
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                verticalAlign: 'middle',
                              }}
                            >
                              {item.name.split('.')[0]}
                            </span>
                            <span
                              style={{
                                verticalAlign: 'middle',
                              }}
                            >
                              .{item.name.split('.')[1]}
                            </span>
                            <CloseOutlined
                              style={{
                                marginLeft: '5px',
                                marginBottom: '10px',
                                background: '#999999',
                                padding: '4px',
                                borderRadius: '50%',
                                fontSize: '10px',
                                color: '#fff',
                              }}
                              onClick={() => {
                                setFileList(() =>
                                  fileList.filter((ite) => ite.uid !== item.uid)
                                )
                              }}
                            /> */}
                      </Col>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row {...rowProps}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                name='content'
                label='内容'
                required
                rules={[{ required: true, message: '内容不能为空' }]}
              >
                <Editor
                  id={'tincyEditor'}
                  apiKey={'p3ro6wyg9qkte1x1gsubztktrsp7b0oanq16112u99carvxt'}
                  value={ContentBody}
                  inline={false}
                  onChange={(e: any) => {
                    const value = e.level.content
                    console.log(e.level.content, ' e.level.content')
                    setContentBody(value)
                  }}
                  init={{
                    selector: '#textarea' as any,
                    language: 'zh_CN',
                    height: '500px',
                    images_upload_handler: handleImageUpload,
                    convert_urls: false,
                    plugins:
                      'initEditor  preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs',
                    toolbar:
                      'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                    table image pageembed  charmap emoticons hr pagebreak insertdatetime  preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
                    toolbar_sticky: true,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.buttonMain}>
            <Button
              type='primary'
              ghost
              style={{
                float: 'left',
                marginLeft: '115px',
                color: '#999',
                borderColor: '#999',
              }}
              onClick={() => {
                history.push({
                  pathname: '/admin/health',
                })
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                float: 'left',
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Row>
        </Form>
      </div>
    </>
  )
}
