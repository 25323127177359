/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-15 16:41:32
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-28 11:33:47
 */


import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import * as CM from '../../services/hospitalIntroduce'
import { sendAsync } from '../../app/applicationSlice'
import { getUrlLink, urlLinkParams } from '../../services/hospitalIntroduce'

// 右侧详情
export const getHospitalIntroduceDetail = createAsyncThunk<
  any,
  void,
  RootThunkApi
>('clinic/getClinicDetail', async (params, api) => {
  return sendAsync(CM.hospitalIntroduceDetail(), api)
})

// 保存接口
export const getHospitalIntroduceSubmit = createAsyncThunk<
  void,
  CM.HospitalIntroduceSubmitParams,
  RootThunkApi<void>
>('clinic/getClinicSubmit', async (params, api) => {
  return sendAsync(CM.hospitalIntroduceSubmit(params), api)
})

export const getUrl = createAsyncThunk<void, urlLinkParams, RootThunkApi>(
  'diagnosis/getUrl',
  async (params, api) => {
    return sendAsync(getUrlLink(params), api)
  }
)

// 广告详情
export const getAdvertisingDetail = createAsyncThunk<any, void, RootThunkApi>(
  'clinic/getAdvertisingDetail',
  async (params, api) => {
    return sendAsync(CM.advertisingDetail(), api)
  }
)

export const getAdvertisingSubmit = createAsyncThunk<
  void,
  CM.AdvertisingParams,
  RootThunkApi
>('diagnosis/getAdvertisingSubmit', async (params, api) => {
  return sendAsync(CM.advertisingSubmit(params), api)
})
