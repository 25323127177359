/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-11 10:42:38
 */

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Timeline,
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getWorkTemplateSaveorupdate } from '../workOrderTemplateSlice'
import styles from './maintenanceWorkOrderTemplate.module.css'

interface editOrAddTagsModalProps {
  dataDetail?: any
  id: any
  name?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditOrAddTagsModal = ({
  dataDetail,
  id,
  name,
  visible,
  onCancel,
  onOk,
}: editOrAddTagsModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [tagsList, setTagsList] = useState<any>([])

  useEffect(() => {
    if (visible) {
      if (dataDetail) {
        setDetail(dataDetail)
      }
    }
  }, [visible, dataDetail])

  console.log(detail, 'detaildetail')

  
  useEffect(() => {
    if (visible) {
      if (name == '紧急程度') {
        setTagsList(detail?.ermerge?.split(','))
      } else if (name == '报修主题') {
        setTagsList(detail?.topics?.split(','))
      }
    }
  }, [visible, name, detail])

  useEffect(() => {
    if (visible) {
      if (name)
        if (tagsList?.length) {
          form.setFieldsValue({
            tags: [...tagsList],
          })
        } else {
          form.setFieldsValue({
            tags: [''],
          })
        }
    } else {
      setTagsList([])
    }
  }, [tagsList?.length, visible, name])

  return (
    <Modal
      title={name}
      width={500}
      visible={visible}
      onOk={() => {
        form.validateFields().then((values) => {
          const value = Object.values(values).flat(1)
          const index = value.findIndex((v) => v === '' || v === undefined)
          const newDate = index === -1 ? value : value.slice(0, index)
          const submitParams =
            name == '紧急程度'
              ? {
                  id: detail?.id,
                  ermerge: newDate?.length ? newDate?.join(',') : '',
                }
              : name == '报修主题'
              ? {
                  id: detail?.id,
                  topics: newDate?.length ? newDate?.join(',') : '',
                }
              : { id: detail?.id }

          dispatch(
            getWorkTemplateSaveorupdate({
              ...submitParams,
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({ message: '保存成功', duration: 3 })
                form.resetFields()
                onOk()
            })
            .catch(() => {
              notification.error({ message: '保存失败', duration: 3 })
                form.resetFields()
                onOk()
            })
        
        })
      }}
      onCancel={() => {
        form.resetFields()
        onCancel()
        setDetail({})
        setTagsList([])
        form.setFieldsValue({
          tags: [''],
        })
      }}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        name='dynamic_form_nest_item'
        onFinish={(v) => {
          form.resetFields()
          console.log(v, 'values')
        }}
        autoComplete='off'
        form={form}
      >
      
          <Form.List name='tags'>
            {(fields: any, { add, remove }) => (
              <>
                {fields.map((field: any, index: any) => (
                  <Form.Item
                    className={styles.name}
                    style={{ marginLeft: '20px' }}
                    label={name + (index + 1)}
                    required
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      required
                      validateTrigger={['onChange', 'onBlur']}
                      noStyle
                    >
                      <Input placeholder='请输入' style={{ width: '60%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <span
                        className={styles.add}
                        onClick={() => remove(field.name)}
                      >
                        <MinusCircleOutlined
                          style={{
                            marginLeft: '10px',
                            color: '999999',
                            fontSize: '18px',
                          }}
                        />
                      </span>
                    ) : null}
                    {fields.length === index + 1 ? (
                      <span onClick={() => add()}>
                        <PlusCircleOutlined
                          style={{
                            marginLeft: '10px',
                            color: '999999',
                            fontSize: '18px',
                          }}
                        />
                      </span>
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item></Form.Item>
              </>
            )}
          </Form.List>
      </Form>
    </Modal>
  )
}
