import { DefaultPageSize } from './pagination'

/* eslint-disable @typescript-eslint/ban-types */
export interface Page<T extends object> {
  /**
   * Current page index start from 1.
   */
  current: number

  /**
   * Page size.
   */
  size: number

  /**
   * Total number of items.
   */
  total: number

  /**
   * Item list in this page.
   */
  items: T[]
}

export interface List<T extends object> {
  /**
   * Current page index start from 1.
   */
  current: number

  /**
   * Page size.
   */
  size: number

  /**
   * Total number of items.
   */
  total: number

  /**
   * Item list in this page.
   */
  records: T[]
}

export function startPageOf<T extends object>(): Page<T> {
  return {
    current: 1,
    size: DefaultPageSize,
    total: 0,
    items: [],
  }
}

export function pageOf<T extends object>(
  data: Record<string, never>,
  items: T[]
): Page<T> {
  return {
    current: data.current || 1,
    size: data.size || DefaultPageSize,
    total: data.total || 0,
    items: items || [],
  }
}
