export class Dimen {
  // 序号
  static Num = "4rem";
  // 门诊号
  static OutpatientNo = "5rem";
  // 患者姓名
  static Name = "14rem";
  // 性别
  static Sex = "5rem";
  // 年龄
  static Age = "5rem";
  
  // 手机号
  static PatientPhone = "12rem";
  // 科室名称
  static DepartmentName = "16rem";

  // 皮试结果
  static Result = "6rem";
  // 最近操作员
  static OperateUser = "12rem";
  // 疾病名称
  static Disease = "15rem";
  // 科室
  static Department = "12rem";
  // 地址
  static Address = "10rem";
  // 医生
  static Doctor = "12rem";
  // 就诊状态
  static Status = "8rem";
  // 状态
  static State = "6rem";
  // 时间
  static Time = "10rem";
  // 电话
  static TelPhone = "12rem";
  // 身份证号
  static IdCardNumber = "18rem";
  // 商品编码
  static Coding = "12rem";
  // 开票项目
  static BillingCategory = "8rem";
  // 批次号
  static BatchNo = "6rem";
  // 拆零比例
  static SplitScale = "8rem";
  // 数量
  static Count = "12rem";
  // 价格(售价/进价)
  static Price = "8rem";
  // 利润
  static Profit = "8rem";
  // 药品用法
  static Usemethod = "8rem";
  // 处方名称
  static Category = "8rem";
  // 结算编号
  static OrderSource = "8rem";
  // 标签
  static Tag = "6rem";
  // 支付方式
  static PayMethod = "8rem";
  // 结算编号
  static PaymentId = "6rem";
  // 是否管理员
  static Role = "6rem";
  // 权限
  static Permissions = "4rem";
  // 注销状态
  static Actions = "8rem";
  // 科室代码
  static Code = "10rem";
  // 业务单号
  static PutNumber = "10rem";
  // 价格变化
  static PriceChange = "12rem"
  // 规格
  static PackSpec = "10rem"
  // 批准文号
  static ApprovalNumber = "10rem"
  // 供应商
  static SupplierName = "16rem"
  // 单位
  static Unit = "8rem"
  // 费用代码
  static No = "8rem"
  // 通用名
  static GenericName = "15rem"
  
  

}
