/*
 * @Descripttion: 
 * @version: 
 * @Author: 苏
 * @email: 1373842098@qq.com
 * @Date: 2022-01-07 11:21:55
 * @LastEditors: sj
 * @LastEditTime: 2022-06-14 15:25:42
 */
import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import _ from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootDispatch } from "../../app/store";
import { AccountType } from "../../models/accounttype";
import { ThemeContext } from "../../theme/ThemeContext";
import { useQuery } from "../../utils/HttpUtils";
import splash from "./assets/splash.jpg";
import { getThirdPartyLoginToken, invoiceTokenAsync } from "./loglinSlice";
import styles from "./OAuthorProxy.module.css";

export const OAuthorProxy = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>();

  const location = useLocation();

  const query = useQuery();

  const theme = useContext(ThemeContext);

  const [message, setMessage] = useState("");

  useEffect(() => {
    switch (location.pathname.toLocaleLowerCase()) {
      case "/login/callback":
        setMessage("正在登录第三方账号...");
        break;
      case "/usercenter/callback":
        setMessage("正在绑定账号...");
        break;
      case "/authorization/callback":
        setMessage("正在三方授权...");
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    const isLogin = location.pathname === "/login/callback";
    if (isLogin) {
      dispatch(
        getThirdPartyLoginToken({
          accountType: AccountType.Alipay,
          isLogin,
          others: Object.fromEntries(query),
        })
      )
        .then(unwrapResult)
        .then(() => {
          window.close();
        })
        .catch((e) => {
          setMessage(e?.message || "与第三方交互出错");
          closeWindow()
        });
    }
    if (location.pathname === "/authorization/callback") {
      if (Object.fromEntries(query)) {
        const params = Object.fromEntries(query)
        const list = params.state.split(';').map(v => { return { [v.split(':')[0]]: v.split(':')[1] } })
        const data = {
          code: params.code,
          supplierId: list.find(v => v.supplierId)?.supplierId || '',
          taxNo: params.taxnum,
          tenantId: list.find(v => v.tenantId)?.tenantId || '',
        }
        dispatch(invoiceTokenAsync(data)).then(unwrapResult).then(() => {
          closeWindow()
        }).catch((e) => {
          setMessage(e?.message || "与第三方交互出错");
          closeWindow()
        });
      } else {
        setMessage("与第三方交互出错");
        closeWindow()
      }

    }
  }, []);

  const closeWindow = () => {
    _.delay(() => {
      window.close();
    }, 5000);
  }

  return (
    <div className={styles.viewport}>
      <img src={splash} className={styles.background} alt="登录页面背景图" />
      <div className={styles.container}>
        <div className={styles.message}>
          <Spin />
          <span style={{ fontSize: "1rem", marginLeft: 10, color: theme.tc2 }}>
            {message}
          </span>
        </div>
        <div className={styles.banner}></div>
      </div>
    </div>
  );
};
