/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 15:59:13
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns, HealthHotStatisticsColumns } from './columns'
import { getHealthHotList, getHealthList } from './healthSlice'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { HealthHotStatisticsQuery, Query } from './Query'

export interface HealthHotStatisticsRef {
  refresh?: () => void
  tabKey?: any
}

interface HealthHotStatisticsProps {
  tabKey?: any
}
const RealHealthHotStatistics = (
  { tabKey }: HealthHotStatisticsProps,
  ref: React.Ref<HealthHotStatisticsRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [sortedInfo, setSorter] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getHealthHotList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (
      sortedInfo?.order === 'ascend' &&
      sortedInfo?.columnKey === 'visitCount'
    ) {
      setParams({
        ...params,
        orderType: 1,
        // finishInspectionNumberSort: undefined,
        // recentlyApplicationTimeSort: undefined,
        // othersSort: undefined,
      })
    } else if (
      sortedInfo?.order === 'descend' &&
      sortedInfo?.columnKey === 'visitCount'
    ) {
      setParams({
        ...params,
        orderType: 0,
      })
    }else{
      setParams({
        ...params,
        orderType: undefined,
      })
    }
    console.log(sortedInfo?.order, 'sortedInfo?.order')
  }, [sortedInfo])

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='热门科普统计'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '15px  15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <HealthHotStatisticsQuery
              onValueChange={(v) => {
                setParams({ ...params, ...v, current: 1 })
                setSorter(undefined)
              }}
            />
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              onChange={(a, b, sorter: any) => {
                setSorter(sorter)
                console.log(sorter, a, b, 'sorter?.order')
              }}
              loading={pageLoading}
              columns={HealthHotStatisticsColumns(
                (action: any, v: any, e: any) => {
                  switch (action) {
                    case 'withdraw':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认撤回？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          return
                        },
                      })
                      break
                    case 'publish':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认发布？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          return
                        },
                      })
                      break
                    case 'delete':
                      Modal.confirm({
                        centered: true,
                        title: '',
                        content: <div>是否确认删除？</div>,
                        okText: '确认',
                        cancelText: '取消',
                        icon: null,
                        onOk: () => {
                          return
                        },
                      })
                      break

                    case 'detail':
                      break
                    case 'edit':
                      break
                  }
                },
                params.size * (params.current - 1),
                sortedInfo
              )}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const HealthHotStatisticsList = forwardRef(RealHealthHotStatistics)
