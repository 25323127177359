import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import { ErrorCode, ErrorState, NoErrorState } from '../models/error'
import { StationCategory } from '../models/user'
import { ADMIN_API_PREFIX } from '../services/constant'
import { ServiceError } from '../services/errors'
import * as R from '../services/request'
import { Request } from '../services/request'
import { RootDispatch, RootState, RootThunk } from './store'

const CREDENTIAL_KEY = 'credentials_me'

const TEMPORARY_CREDENTIAL_KEY = 'temporary_credentials'

export enum ApplicationType {
  Management = 0,
  Application = 1,
}

interface Credentials {
  id: string
  userName: string
  tenantName: string
  tenantId: number
  token: string
}

interface AppState {
  errorState: ErrorState
  credentials?: Credentials
  header?: any
}

const initialState: AppState = {
  errorState: NoErrorState,
  credentials: (() => {
    try {
      let json = sessionStorage.getItem(CREDENTIAL_KEY)
      if (!json) {
        json = localStorage.getItem(TEMPORARY_CREDENTIAL_KEY)
        if (json) {
          sessionStorage.clear()
          sessionStorage.setItem(CREDENTIAL_KEY, json)
          localStorage.removeItem(TEMPORARY_CREDENTIAL_KEY)
        }
      }
      if (!json) {
        json = localStorage.getItem(CREDENTIAL_KEY)
      }
      if (json) {
        return JSON.parse(json) as Credentials
      }
      return undefined
    } catch (e) {
      return undefined
    }
  })(),
  header: '',
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setErrorState: (state, action: PayloadAction<ErrorState>) => {
      state.errorState = action.payload
    },
    setCredentials: (
      state,
      action: PayloadAction<{ creds: Credentials; rememberMe: boolean }>
    ) => {
      state.credentials = action.payload.creds
      localStorage.setItem(CREDENTIAL_KEY, JSON.stringify(action.payload.creds))
    },
    setTemporaryCredentials: (_, action: PayloadAction<Credentials>) => {
      try {
        localStorage.setItem(
          TEMPORARY_CREDENTIAL_KEY,
          JSON.stringify(action.payload)
        )
      } catch {
        // do nothing.
      }
    },
    setAppHeader: (state, action) => {
      state.header = action.payload
    },
    removeCredentials: (state) => {
      state.credentials = undefined
      localStorage.removeItem(CREDENTIAL_KEY)
      sessionStorage.removeItem(CREDENTIAL_KEY)
    },
  },
})

export function setCommonHeaders(
  request: Request,
  getState: () => RootState
): Request {
  const creds = getState().application.credentials

  console.log(creds, 'creds')

  const headers = request.headers || {}
  return {
    ...request,
    headers: { ...headers, token: creds?.token },
  }
}

export const {
  setErrorState,
  setCredentials,
  removeCredentials,
  setTemporaryCredentials,
  setAppHeader,
} = applicationSlice.actions

export async function sendAsync(
  request: Request,
  {
    getState,
    dispatch,
  }: {
    getState: () => RootState
    dispatch: RootDispatch
  },
  ignoreError = false
): Promise<any> {
  return R.send(setCommonHeaders(request, getState)).catch((e) => {
    const error =
      e instanceof ServiceError
        ? {
            code: e.code,
            message: e.message,
          }
        : { code: ErrorCode.Unknown, message: e.message }
    if (!ignoreError && error.code !== 401) {
      dispatch(setErrorState(error))
    }
    throw e
  })
}

export function api<T>(
  request: Request,
  action: (t: T, dispatch: RootDispatch, getState: () => RootState) => void,
  actionOnError?: (
    e: ServiceError,
    dispatch: RootDispatch,
    getState: () => RootState
  ) => boolean
): RootThunk {
  return async (dispatch, getState) => {
    try {
      const data = await R.send<T>(setCommonHeaders(request, getState))
      action(data, dispatch, getState)
    } catch (e) {
      if (e instanceof ServiceError) {
        if (!actionOnError || !actionOnError(e, dispatch, getState)) {
          if (e.code === 401) {
            // if (e.code == 400 && e.message == 'token失效，请重新登录。') {
            // token expired.
            dispatch(removeCredentials())
          } else {
            dispatch(
              setErrorState({
                code: ErrorCode.RemoteServiceError,
                message: e.message,
              })
            )
          }
        }
      } else if (process.env.node_env === 'development') {
        // Rethrow exception.
        throw e
      } else {
        console.error(e)
      }
    }
  }
}

export const containsCredentials = (): boolean =>
  !!localStorage.getItem(CREDENTIAL_KEY)

export const selectErrorState = (state: RootState): ErrorState =>
  state.application.errorState

export const selectCredentials = (state: RootState): Credentials | undefined =>
  state.application.credentials

export const selectUserId = (state: RootState): string =>
  state.application.credentials?.id || ''

export const selectUserName = (state: RootState): string =>
  state.application.credentials?.userName || ''

export const selectTenantName = (state: RootState): string =>
  state.application.credentials?.tenantName || ''

export const selectTenantId = (state: RootState): number =>
  state.application.credentials?.tenantId || 0

export const selectHeader = (state: RootState): any => state.application.header

export const selectAppToken = (state: RootState): any =>
  state.application.credentials?.token

export default applicationSlice.reducer
