/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:04:21
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-16 16:02:38
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface AppointmentListPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}

// 列表
export function AppointmentList(params: AppointmentListPrams): Request {
  return {
    url: `${API_PREFIX}/adminappointment/page`,
    method: 'GET',
    params,
  }
}
