/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 10:40:05
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 11:30:07
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../../app/store'
import { sendAsync } from '../../../app/applicationSlice'
import * as DT from '../../../services/integralDetail'

// 列表
export const getIntegralDetailList = createAsyncThunk<
  void,
  DT.IntegralDetailListPrams,
  RootThunkApi<void>
>('clinic/getIntegralDetailList', async (params, api) => {
  return sendAsync(DT.IntegralDetailList(params), api)
})
