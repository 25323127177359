import _ from "lodash";

export enum MenuCategory {
  Menu = 1,
  Button = 2,
}

export interface Menu {
  id: string;
  parentId: string;
  icon: string;
  path: string;
  name: string;
  category: number;
  sort: number;
  subMenus: Menu[];
  level?: number;
}

export function fromJson(json: any): Menu {
  return {
    id: json.id?.toString() || "",
    parentId: json.parentId === "-1" ? undefined : json.parentId?.toString(),
    icon: json.icon,
    path: json.path,
    name: json.name,
    category: json.category,
    sort: json.sort,
    subMenus: [],
    level: 0,
  };
}

export function toJson(menu: Menu): any {
  return {
    id: menu.id,
    parentId: menu.parentId,
    icon: menu.icon,
    path: menu.path,
    name: menu.name,
    category: menu.category,
    sort: menu.sort,
  };
}

function fixMenuLevels(menus: Menu[], level: number) {
  _.forEach(menus, (m) => {
    m.level = level;
    fixMenuLevels(m.subMenus, level + 1);
  });
}

export function constructMenuLayers(data: any[]): Menu[] {
  const menus =
    _.chain(data)
      .groupBy((m) => m.parentId?.toString() || '-1')
      .mapValues((ms) => ms.map((m) => fromJson(m)))
      .mapValues((ms) => _.sortBy(ms, ['sort']))
      .mapValues((ms, _, gs) => {
        return ms.map((m) => ({
          ...m,
          subMenus: gs[m.id] || [],
        }))
      })
      .value()['-1'] || []
  fixMenuLevels(menus, 1)
  return menus
}

export function findMenuById(
  menus: Menu[],
  menuId: string,
  parent?: Menu
): Menu[] {
  if (menuId === "") {
    return [];
  }
  const menu = _.chain(menus)
    .find((m) => m.id === menuId)
    .value();
  if (menu != null) {
    if (parent) {
      return [menu, parent];
    } else {
      return [menu];
    }
  }
  for (const menu of menus) {
    const result = findMenuById(menu.subMenus, menuId, menu);
    if (result.length > 0) {
      return result;
    }
  }
  return [];
}

export function getMenuMaxSort(menus: Menu[]): number {
  if (menus.length === 0) {
    return 0;
  }
  return (
    _.chain(menus)
      .map((m) => [m.sort, getMenuMaxSort(m.subMenus)])
      .flatten()
      .max()
      .value() + 1
  );
}
