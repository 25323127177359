  /*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-07-27 10:38:14
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-14 18:13:04
 */



export enum WorkOrderStatusCategory {
  // CSH = '0',
  SPZ = '1',
  YTG = '5',
  YJJ = '7',
  YCX = '15',
  YZJ = '10',
}
// 全部
export const WorkOrderStatusCategories = [
  // WorkOrderStatusCategory.CSH,
  WorkOrderStatusCategory.SPZ,
  WorkOrderStatusCategory.YTG,
  WorkOrderStatusCategory.YJJ,
  WorkOrderStatusCategory.YCX,
  WorkOrderStatusCategory.YZJ,
]

export function getWorkOrderStatus(state: WorkOrderStatusCategory): string {
  switch (state) {
    // case '0':
    //   return '初始化'
    case '1':
      return '审批中'
    case '5':
      return '已完成'
    case '7':
      return '已拒绝'
    case '15':
      return '已撤销'
    case '10':
      return '已转交'
  }
  return '-'
}
