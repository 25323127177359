/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 14:31:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-18 18:59:56
 */
import { unwrapResult } from '@reduxjs/toolkit'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import { columns } from './columns'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { getMemberHexiaoList } from './memberHexiaoSlice'
import { Query } from './Query'
import styles from './memberHexiao.module.css'
import { Columns } from './columns'
import { getDateRange, DateRangeType } from '../../compnents/form/DateSelect'

export const MemberHexiaoList = (): // { tabKey }: ReservationChannelProps,
ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [pageLoading, setPageLoading] = useState<any>(false) //表格 loading

  const [head, tail] = getDateRange(DateRangeType.ThisWeek)

  const [queries, setQueries] = useState<any>({
    current: 1,
    size: 10,
    startTime: head,
    endTime: tail,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    dispatch(getMemberHexiaoList({ ...queries }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [queries])

  return (
    <>
      <div className={styles.content}>
        <Query
          onValueChange={(values) => {
            console.log(values, 'values')
            setQueries({
              ...queries,
              ...values,
              current: 1,
              startTime: values?.timeHead ? values?.timeHead : head,
              endTime: values?.timeTail ? values?.timeTail : tail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        />
        <EditableList
          loading={pageLoading}
          rowKey={(_: any, i: any) => `${i}}`}
          page={{
            items: page.items,
            current: queries.current,
            size: queries.size,
            total: page.total,
          }}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size: size || 10 })
          }}
          columns={Columns(() => {
            console.log(222)
          }, queries.size * (queries.current - 1))}
        />
      </div>
    </>
  )
}
