import {
  AlipayCircleFilled,
  CaretDownFilled,
  QrcodeOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Checkbox, Form, Input, notification, Row, Select } from 'antd'
import _ from 'lodash'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  ApplicationType,
  containsCredentials,
} from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import {
  AccountFilled,
  PasswordFilled,
  TenantFilled,
} from '../../compnents/icons/Icons'
import { ThemeContext } from '../../theme/ThemeContext'
import { isValidMobileNumber } from '../../utils/StringUtils'
import splash from './assets/splash.jpg'
import styles from './Login.module.css'
import {
  getSmsCode,
  getTenants,
  login,
  selectSmsCountdown,
  selectTenants,
  setSmsCountdown,
  setTenants,
} from './loglinSlice'

export interface LoginProps {
  qrcodeEnabled?: boolean
}

export const Login = ({ qrcodeEnabled = false }: LoginProps): ReactElement => {
  const appName = '浙江中医药大学名中医馆'

  const dispatch = useDispatch<RootDispatch>()

  const history = useHistory()

  const theme = useContext(ThemeContext)

  const [loginMode, setLoginMode] = useState<'form' | 'qrcode'>('form')

  const [form] = Form.useForm()

  const tenants = useSelector(selectTenants)

  const countdown = useSelector(selectSmsCountdown)

  const [popup, setPopup] = useState<null | Window>(null)

  // )   // 1 机构用 0 自己用
  const [applicationType, setApplicationType] = useState(0)

  const [rememberMe, setRememberMe] = useState(true)

  useEffect(() => {
    const tenantId = tenants?.length ? tenants[0].id : undefined
    form.setFieldsValue({
      tenantId,
    })
  }, [form, tenants])

  const onFinish = (values: Record<string, never>) => {
    dispatch(
      login({
        ...values,
        username: values.username,
        passWord: values.password,
        rememberMe,
      })
    )
  }

  const { Option } = Select

  const layout = {
    labelCol: { flex: '0 0 34px' },
    wrapperCol: { flex: 1 },
  }

  const tailLayout = {
    wrapperCol: { offset: 3, span: 21 },
  }

  const wholeLayout = {
    wrapperCol: { offset: 0, span: 24 },
  }

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        dispatch(setSmsCountdown(countdown - 1))
      }, 1000)
    }
  }, [countdown])

  useEffect(() => {
    const timer = popup
      ? setInterval(() => {
          if (popup.closed) {
            setPopup(null)
            if (containsCredentials()) {
              history.go(0)
            }
          }
        }, 1000)
      : undefined
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [popup])

  return (
    <div className={styles.viewport}>
      <img src={splash} className={styles.background} alt='登录页面背景图' />
      <div className={styles.container}>
        <div className={styles.banner}></div>
        <div className={styles.login}>
          <div
            className={styles.form}
            style={{ display: loginMode === 'form' ? 'block' : 'none' }}
          >
            <div className={styles.title}>{appName}</div>
            <Form
              {...layout}
              form={form}
              onFinish={onFinish}
              colon={false}
              requiredMark={false}
            >
              <Form.Item
                name='username'
                label={<AccountFilled />}
                className={styles.entry}
                style={{ fontSize: '1rem' }}
                rules={[{ required: true, message: '请输入手机号码' }]}
              >
                <Input
                  placeholder='手机号码'
                  bordered={false}
                  allowClear
                  onChange={(e) => {
                    const text = e.target.value
                    if (
                      isValidMobileNumber(text) &&
                      applicationType === ApplicationType.Application
                    ) {
                      dispatch(getTenants(text))
                    } else {
                      dispatch(setTenants([]))
                    }
                  }}
                />
              </Form.Item>

              {/* <Form.Item
                name='smsCode'
                label={<TenantFilled />}
                className={styles.entry}
              >
                <Input
                  placeholder='手机验证码'
                  autoComplete='off'
                  suffix={
                    <div
                      style={{
                        // width: "6.5rem",
                        height: '1.8rem',
                        lineHeight: '1.8rem',
                        textAlign: 'center',
                        fontSize: countdown > 0 ? '0.625rem' : '0.875rem',
                        color: countdown > 0 ? theme.tc3 : theme.tc2,
                        backgroundColor: theme.c3,
                        cursor: countdown > 0 ? 'not-allowed' : 'pointer',
                      }}
                      onClick={async (e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (countdown > 0) {
                          return
                        }
                        const phone = form.getFieldValue('username')
                        if (!isValidMobileNumber(phone)) {
                          notification.error({
                            message: '手机号码格式不正确',
                            duration: 3,
                          })
                          return
                        }
                        try {
                          await dispatch(getSmsCode(phone)).then(unwrapResult)
                          dispatch(setSmsCountdown(20))
                        } catch {
                          // do nothing.
                        }
                      }}
                    >
                      {countdown > 0
                        ? `获取验证码(${countdown}s)`
                        : '获取验证码'}
                    </div>
                  }
                />
              </Form.Item> */}

              <Form.Item
                name='password'
                label={<PasswordFilled />}
                className={styles.password}
                rules={[
                  { required: true, message: '请输入密码' }
                ]}
              >
                <Input.Password placeholder='密码' bordered={false} />
              </Form.Item>
              <Form.Item {...tailLayout} className={styles.remember}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  记住密码
                </Checkbox>
              </Form.Item>
              <Form.Item {...wholeLayout} style={{ margin: '40px 0 0 0' }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  className={styles.submit}
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <div className={styles.internet}>浙ICP备2023009621号</div>

    </div>
  )
}
