import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { DateTimeFormatMessageSimple } from '../../../models/datetime'

export const Columns = (
  onClick: (v: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '类型',
      dataIndex: 'type',
      align: 'center',
      width: '15rem',
      ellipsis: true,
      key: 'treatmentDate',
      render: (_, r) => {
        return r.type == 1 ? '收入' : r.type == 2 ? '支出' : '-'
      },
    },
    {
      title: '备注',
      dataIndex: 'reason',
      align: 'center',
      width: '15rem',
      ellipsis: true,
      key: 'reason',
      render: function Title(_, r): ReactElement {
        return r.reason ? r.reason : '-'
      },
    },
    {
      title: '积分',
      key: 'value',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      dataIndex: 'value',
      render: (_, r) => {
        return r.value ? r.value : '-'
      },
    },

    {
      title: '积分余额',
      dataIndex: 'balanceValue',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'treatmentDate',
      render: (_, r) => {
        return r.balanceValue ? r.balanceValue : '-'
      },
    },

    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      key: 'address',
      render: (_, r) => {
        return r.createTime
          ? moment(r.createTime).format(DateTimeFormatMessageSimple)
          : '-'
      },
    },
  ]
}
