import _ from "lodash";

// 部门类型 0 全科 1临床 2医技 3药剂 4病区 5行政/财务/其他
export enum DepartmentCategory {
  General = 0,
  Clinic = 1,
  Technology = 2,
  Medicament = 3,
  Ward = 4,
  Others = 5,
}

export const DepartmentCategories = [
  DepartmentCategory.General,
  DepartmentCategory.Clinic,
  DepartmentCategory.Technology,
  DepartmentCategory.Medicament,
  DepartmentCategory.Ward,
  DepartmentCategory.Others,
];

export const DepartmentCategoryNames = [
  "全科",
  "临床",
  "医技",
  "药剂",
  "病区",
  "行政/财务/其他",
];

export function getDepartmentCategoryName(
  category: DepartmentCategory
): string {
  if (category < 0 || category >= DepartmentCategoryNames.length) {
    return "";
  }
  return DepartmentCategoryNames[category];
}

// 部门子类型 0 临床 1手术 2医技 3库房 4病区 5财务 6院外单位
export enum DepartmentSubCategory {
  Clinic = 0,
  Surgery = 1,
  Technology = 2,
  Warehouse = 3,
  Ward = 4,
  Finance = 5,
  External = 6,
}

export const DepartmentSubCategories = [
  DepartmentSubCategory.Clinic,
  DepartmentSubCategory.Surgery,
  DepartmentSubCategory.Technology,
  DepartmentSubCategory.Warehouse,
  DepartmentSubCategory.Ward,
  DepartmentSubCategory.Finance,
  DepartmentSubCategory.External,
];

export const DepartmentSubCategoryNames = [
  "临床",
  "手术",
  "医技",
  "库房",
  "病区",
  "财务",
  "院外单位",
];

export function getDepartmentSubCategoryName(
  category: DepartmentSubCategory
): string {
  if (category < 0 || category >= DepartmentSubCategoryNames.length) {
    return "";
  }
  return DepartmentSubCategoryNames[category];
}

export function getDepartmentSubCategories(
  category: DepartmentCategory
): { editable: boolean; categories: DepartmentSubCategory[] } {
  switch (category) {
    case DepartmentCategory.General:
      return {
        editable: true,
        categories: [
          DepartmentSubCategory.Clinic,
          DepartmentSubCategory.Surgery,
          DepartmentSubCategory.Technology,
          DepartmentSubCategory.Warehouse,
          DepartmentSubCategory.Ward,
          DepartmentSubCategory.Finance,
        ],
      };
    case DepartmentCategory.Clinic:
      return { editable: false, categories: [DepartmentSubCategory.Clinic] };
    case DepartmentCategory.Technology:
      return {
        editable: false,
        categories: [DepartmentSubCategory.Technology],
      };
    case DepartmentCategory.Medicament:
      return {
        editable: false,
        categories: [DepartmentSubCategory.Warehouse],
      };
    case DepartmentCategory.Ward:
      return { editable: false, categories: [DepartmentSubCategory.Ward] };
    case DepartmentCategory.Others:
      return { editable: false, categories: [DepartmentSubCategory.Finance] };
  }
}

export interface Department {
  id: string
  name: string
  // 科室编码
  code: string
  // 状态
  status: number
  // 科室类型
  category: DepartmentCategory
  // 科室子类型
  subCategory: DepartmentSubCategory[]
  //
  showFlg?: any
}

export function fromJson(json: any): Department {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    code: json.code,
    status: json.status,
    category: json.category,
    subCategory: json.subCategory
      ? _.chain(json.subCategory)
          .split(',')
          .map((c) => parseInt(c))
          .value()
      : [],
    showFlg: json.showFlg,
  }
}
