/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-10 15:25:03
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { columns } from './column'
import { getPatientsManagementList } from './patientsManagementSlice'
import { PatientMessageModal } from './patientModal'

export interface PatientsManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface PatientsManagementProps {
  tabKey?: any
}
const RealPatientsManagement = (
  { tabKey }: PatientsManagementProps,
  ref: React.Ref<PatientsManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isPatientMessageVisible, setIsPatientMessageVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [message, setMessage] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getPatientsManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='就诊人管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '15px  15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Query
              onValueChange={(v) => {
                setParams({ ...params, ...v, current: 1 })
              }}
            />
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                switch (action) {
                  case 'lookDetail':
                    setIsPatientMessageVisible(true)
                    setId(v.id)
                    setMessage({
                      phone: v?.phone,
                      identityCard: v?.identityCard,
                      name: v?.name,
                      sex: v?.sex,
                      ageYear: v?.ageYear,
                      ageMonth: v?.ageMonth,
                    })

                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
        </Col>
        <PatientMessageModal
          message={message}
          id={id}
          visible={isPatientMessageVisible}
          onCancel={() => {
            setIsPatientMessageVisible(false)
            setId('')
            getPage()
          }}
          onOk={() => {
            setIsPatientMessageVisible(false)
            setId('')
            getPage()
          }}
        />
      </TabPane>
    </TabBar>
  )
}

export const PatientsManagementList = forwardRef(RealPatientsManagement)
