import { API_PREFIX } from './constant'
import { Request } from './request'

export interface MemberQueryParams {
  current: number
  size?: number
  param?: string
}

// 列表
export function memberList(params: MemberQueryParams): Request {
  return {
    url: `${API_PREFIX}/openUser/selectMemberList`,
    method: 'GET',
    params,
  }
}

export interface IntegralWriteOffParams {
  current: number
  size?: number
  param?: string
  reason?: any
  startTime?: any
  endTime?: any
}

// 保存
export function integralWriteOffDetailSubmit(
  body: IntegralWriteOffParams
): Request {
  return {
    url: `${API_PREFIX}/openUser/memberPointsHeXiao`,
    method: 'POST',
    body,
  }
}



