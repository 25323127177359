/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-12 09:33:40
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Modal, notification } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditableList } from '../../compnents/list/EditableList'
import { freePatientQueryParams } from '../../services/freePatients'
import {
  getFreePatientDownLoad,
  getFreePatientList,
  getFreePatientsDelete,
  getFreePatientsFinish,
  getFreePatientsPublish,
  getFreePatientsRecall,
  getFreePrintData,
  selectCurrent,
  selectFreePatientData,
  selectPageLoading,
  selectTotal,
  setCurrent,
  setPageLoading,
  getPageExoprt
} from './freePatientSlice'
import { Columns, PrintColumns } from './columns'
import { Query } from './Query'

import styles from './freePatients.module.css'
import { StationOfFreeModal } from './stationOfFreeModal'
import { AddOrEditFreePatientModal } from './addOrEditFreePatients'
import { useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import { RootDispatch } from '../../app/store'
import { PrintFrame, PrintFrameRef } from '../print/PrintFrame'
import { FreePatientDetailModal } from './freePatientDetailModal'
import { TableRowSelection } from 'antd/lib/table/interface'

export const FreePatientList = (props: { value?: any }): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const printRef = useRef<PrintFrameRef>(null)

  const history: any = useHistory()

  const Current = useSelector(selectCurrent)

  const total = useSelector(selectTotal)

  const data = useSelector(selectFreePatientData)

  const [detailId, setDetailId] = useState('')

  const [id, setId] = useState<any>()

  const [disabled, setDisabled] = useState<any>(false)

  const [treatmentIdList, setTreatmentIdList] = useState<any>()

  const [
    isStationOfFreeModalVisible,
    setIsStationOfFreeModalVisible,
  ] = useState(false)

  const [
    isAddOrEditFreePatientModalVisible,
    setIsAddOrEditFreePatientModalVisible,
  ] = useState(false)

  const [
    isFreePatientDetailModalVisible,
    setIsFreePatientDetailModalVisibleVisible,
  ] = useState(false)

  const [size, setSize] = useState(50)

  const [printDataList, setPrintData] = useState<any>()

  const [selectIds, setSelectIds] = useState<any[]>()

  const [status, setStatus] = useState<any>(2)

  const pageLoadingRX = useSelector(selectPageLoading)

  const [queries, setQueries] = useState<freePatientQueryParams>()

  useEffect(() => {
    dispatch(setPageLoading(true))
    dispatch(getFreePatientList({ ...queries, current: Current, size }))
    setTimeout(() => {
      dispatch(setPageLoading(false))
    }, 3000)
  }, [queries, size, Current])




  useEffect(() => {
    setTreatmentIdList(data?.map((v: any) => v?.id))
  }, [data])

  useEffect(() => {
    console.log(treatmentIdList, printDataList, 'printDatasprintDatas')
  }, [treatmentIdList, printDataList])

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectIds(selectedRowKeys)
    },
  }

  const printData = () => {
    return (
      <EditableList
        pagination={false}
        bordered
        style={{ margin: '10px 0' }}
        // className={styles.printFreePatients}
        loading={pageLoadingRX}
        rowKey={(_, i) => `${i}}`}
        page={{
          items: printDataList,
          current: Current,
          size: size,
          total: total,
        }}
        onChangePage={(current, pageSize) => {
          setSize(pageSize as number)
          dispatch(setCurrent(current))
        }}
        onRow={(r: any) => ({
          onDoubleClick: () => {
            setDetailId(r.id)
            setIsFreePatientDetailModalVisibleVisible(true)
          },
        })}
        columns={PrintColumns(size * (Current - 1))}
      />
    )
  }

  return (
    <>
      <div className={styles.content}>
        <Query
          onValueChange={(v) => {
            setQueries({ ...queries, ...v, current: 1 })
            dispatch(setCurrent(1))
          }}
          addFreePatient={() => {
            setIsAddOrEditFreePatientModalVisible(true)
            setStatus(Math.random())
          }}
          export={() => {
            console.log(selectIds);
            dispatch(getPageExoprt({ treatmentIdList: selectIds || [] }))
              .then(unwrapResult)
              .then((res: any) => {
                const content = res
                const blob = new Blob([content])
                const fileName = '支出汇总.xlsx'
                const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
                const elink = document.createElement('a')
                if ('download' in elink) {
                  elink.download = fileName
                  elink.style.display = 'none'
                  elink.href = selfURL['createObjectURL'](blob)
                  document.body.appendChild(elink)
                  // 触发链接
                  elink.click()
                  selfURL.revokeObjectURL(elink.href)
                  document.body.removeChild(elink)
                } else {
                  navigator.msSaveBlob(blob, fileName)
                }
              })
              .then(() => {
                notification.success({
                  message: '导出成功',
                  duration: 3,
                })
              })
              .catch((res: any) => {
                notification.success({
                  message: '导出失败',
                  duration: 3,
                })
              })
          }}
          print={() => {
            console.log(treatmentIdList, 'treatmentIdList')
            dispatch(getFreePrintData({ treatmentIdList: treatmentIdList }))
              .then(unwrapResult)
              .then((v: any) => {
                console.log(v)
                setPrintData(v)
                printRef.current?.print()
              })
          }}
        />
        <EditableList
          className={styles.freePatients}
          loading={pageLoadingRX}
          rowKey={(v, i) => v.id}
          page={{
            items: data,
            current: Current,
            size: size,
            total: total,
          }}
          rowSelection={
            {
              selectedRowKeys: selectIds,
              ...rowSelection,
            } as TableRowSelection<any>
          }
          onChangePage={(current, pageSize) => {
            setSize(pageSize as number)
            dispatch(setCurrent(current))
          }}
          onRow={(r: any) => ({
            onDoubleClick: () => {
              setDetailId(r.id)
              setIsFreePatientDetailModalVisibleVisible(true)
            },
          })}
          columns={Columns((action: any, v: any) => {
            switch (action) {
              case 'withdraw':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认撤回？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getFreePatientsRecall(v?.id))
                      .then(unwrapResult)
                      .then((v: any) => {
                        dispatch(
                          getFreePatientList({
                            ...queries,
                            current: Current,
                            size,
                          })
                        )
                        dispatch(setCurrent(1))
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                      })
                  },
                })
                break
              case 'publish':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认发布？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getFreePatientsPublish(v?.id))
                      .then(unwrapResult)
                      .then((v: any) => {
                        dispatch(
                          getFreePatientList({
                            ...queries,
                            current: Current,
                            size,
                          })
                        )
                        dispatch(setCurrent(1))
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                      })
                  },
                })
                break
              case 'delete':
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认删除？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getFreePatientsDelete(v?.id))
                      .then(unwrapResult)
                      .then((v: any) => {
                        dispatch(
                          getFreePatientList({
                            ...queries,
                            current: Current,
                            size,
                          })
                        )
                        dispatch(setCurrent(1))
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                      })
                  },
                })
                break
              case 'over':
                setId(v?.id)
                Modal.confirm({
                  centered: true,
                  title: '',
                  content: <div>是否确认完成？</div>,
                  okText: '确认',
                  cancelText: '取消',
                  icon: null,
                  onOk: () => {
                    dispatch(getFreePatientsFinish(v?.id))
                      .then(unwrapResult)
                      .then((v: any) => {
                        dispatch(
                          getFreePatientList({
                            ...queries,
                            current: Current,
                            size,
                          })
                        )
                        dispatch(setCurrent(1))
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })

                        setIsStationOfFreeModalVisible(true)
                      })
                  },
                })
                break
              case 'detail':
                setDetailId(v.id)
                history.push({
                  pathname: '/admin/freePatient/detail',
                  state: {
                    id: v.id,
                  },
                })
                break
              case 'edit':
                setIsAddOrEditFreePatientModalVisible(true)
                setDetailId(v.id)
                break
              case 'stationFree':
                setId(v?.id)
                setDisabled(false)
                setIsStationOfFreeModalVisible(true)
                break
              case 'stationFreeDetail':
                setId(v?.id)
                setDisabled(true)
                setIsStationOfFreeModalVisible(true)
                break
              case 'downLoad':
                dispatch(getFreePatientDownLoad(v?.id))
                  .then(unwrapResult)
                  .then((res: any) => {
                    console.log(res, '11111111111')
                    dispatch(setCurrent(1))
                    const a = document.createElement('a')
                    a.href = res
                    a.setAttribute('download', 'chart-download')
                    a.click()
                    notification.success({
                      message: '操作成功',
                      duration: 3,
                    })
                  })
                break
            }
          }, size * (Current - 1))}
        />
      </div>
      <PrintFrame ref={printRef} title='浙江中医药大学名中医馆活动支出汇总表'>
        {printData()}
      </PrintFrame>
      <StationOfFreeModal
        disabled={disabled}
        id={id}
        visible={isStationOfFreeModalVisible}
        onCancel={() => {
          setIsStationOfFreeModalVisible(false)
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
          setId('')
          setDetailId('')
        }}
        onOk={() => {
          setIsStationOfFreeModalVisible(false)
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
          setId('')
          setDetailId('')
        }}
      ></StationOfFreeModal>
      <AddOrEditFreePatientModal
        id={detailId}
        status={status}
        visible={isAddOrEditFreePatientModalVisible}
        onCancel={() => {
          setIsAddOrEditFreePatientModalVisible(false)
          setDetailId('')
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
        }}
        onOk={() => {
          setIsAddOrEditFreePatientModalVisible(false)
          setDetailId('')
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
        }}
      ></AddOrEditFreePatientModal>

      <FreePatientDetailModal
        id={detailId}
        visible={isFreePatientDetailModalVisible}
        onCancel={() => {
          setIsFreePatientDetailModalVisibleVisible(false)
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
          setDetailId('')
          setId('')
        }}
        onOk={() => {
          setIsAddOrEditFreePatientModalVisible(false)
          dispatch(getFreePatientList({ ...queries, current: Current, size }))
          setDetailId('')
          setId('')
        }}
      ></FreePatientDetailModal>
    </>
  )
}
