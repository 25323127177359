/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-22 09:44:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-15 10:11:38
 */

import { API_PREFIX, WX_API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface MedicalGuideListPrams {
  current: number
  size: number
}

// 列表
export function medicalGuideList(body: MedicalGuideListPrams): Request {
  return {
    url: `${API_PREFIX}/adminMedicalGuide/queryList`,
    method: 'POST',
    body,
  }
}

export interface MedicalGuideEditPrams {
  status?: any
  tenantId?: any
  comment?: any
}

// 保存
export function medicalGuideEditSubmit(body: MedicalGuideEditPrams): Request {
  return {
    url: `${API_PREFIX}/adminMedicalGuide/saveOrUpdate`,
    method: 'POST',
    body,
  }
}

// 详情
export function medicalGuideDetail(id: any): Request {
  return {
    url: `${API_PREFIX}/adminMedicalGuide/detail`,
    method: 'GET',
    params: { id },
  }
}

export interface ReservationChannelUploadPrams {
  id?: string
}

// 保存
export function reservationChannelUpload(
  params: ReservationChannelUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/blade-appointment/appointmentchannel/updateStatus`,
    method: 'GET',
    params,
  }
}

export function tinyUrl(file: any): Request {
  return {
    url: `${WX_API_PREFIX}/oss/create`,
    method: 'POST',
    body: file,
  }
}

export interface changeStateParams {
  id?: any
  state?: any
}

export function changeStateSubmit(params: changeStateParams): Request {
  return {
    url: `${WX_API_PREFIX}/adminMedicalGuide/changeStateSingle`,
    method: 'GET',
    params,
  }
}

export interface deleteParams {
  id?: any
}

export function deleteSubmit(params: deleteParams): Request {
  return {
    url: `${WX_API_PREFIX}/adminMedicalGuide/deleteLogicSingle`,
    method: 'GET',
    params,
  }
}



