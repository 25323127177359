/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-20 12:50:44
 * @LastEditors: K
 * @LastEditTime: 2022-08-31 16:00:31
 */
import { Col, Row } from "antd";
import React, { ReactElement, ReactNode, useContext } from "react";
import { useDispatch } from "react-redux";
import { traceRoute } from "../../layouts/layoutSlice";
import { ThemeContext } from "../../theme/ThemeContext";
import { BackFilled } from "../icons/Icons";
import styles from "./NavBar.module.css";

interface NavBarProps {
  where: [string, string]
  backtrace?: {
    name: string
    path: any
    query?: any
    state?: any
    params?: any
    routeName?: string
  }
  buttons?: ReactNode
  style?: React.CSSProperties
  type?: boolean
}

export const NavBar = ({
  where,
  backtrace,
  buttons,
  style,
  type = true
}: NavBarProps): ReactElement => {
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();

  return (
    <Row
      justify="space-between"
      style={{
        height: "2.5rem",
        backgroundColor: theme.pn,
        borderBottom: `solid 2px ${theme.c1}`,
        paddingRight: 15,
        ...style,
      }}
      align="middle"
    >
      <Row align="top">
        <Col
          style={{
            position: "relative",
            color: theme.tt,
            fontSize: "1.25rem",
            lineHeight: "2.5rem",
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "36px",
          }}
          className={styles.where0}
        >
          <span style={{ zIndex: 99, position: "relative" }}>{where[0]}</span>
        </Col>
        {type && <Col
          style={{
            color: theme.tt,
            fontSize: "1.125rem",
            lineHeight: "2.125rem",
            backgroundColor: theme.c1,
            padding: "0 15px",
            transform: "skewX(15deg)",
          }}
        >
          <div style={{ display: "inline-block", transform: "skewX(-15deg)" }}>
            {where[1]}
          </div>
        </Col>}
        {backtrace && (
          <Col
            onClick={() =>
              dispatch(
                traceRoute({
                  ...backtrace,
                  name: backtrace.routeName || backtrace.name,
                })
              )
            }
            style={{
              color: theme.c1,
              fontSize: "1.125rem",
              lineHeight: "2.125rem",
              backgroundColor: theme.ds,
              padding: "0 15px",
              marginLeft: 7,
              transform: "skewX(15deg)",
              cursor: "pointer",
            }}
          >
            <Row style={{ transform: "skewX(-15deg)" }} align="middle">
              <BackFilled
                mode="small"
                style={{ fill: theme.c1, marginRight: 4 }}
              />
              返回{backtrace.name}
            </Row>
          </Col>
        )}
      </Row>
      {buttons}
    </Row>
  );
};
