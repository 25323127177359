import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import {
  getAccountManagementList,
  getAccountManagementUpload,
  resetUserPassword,
  deleteListFn
} from './accountManagementSlice'
import { useParams } from 'react-router-dom'
import { AddOrEditDetailModal } from './addOrEditDetailModal'
import { selectUserId } from '../../app/applicationSlice'
// import { EditDetailModal } from './editDetailModal'

export interface AccountManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface AccountManagementProps {
  tabKey?: any
}
const RealAccountManagements = (
  { tabKey }: AccountManagementProps,
  ref: React.Ref<AccountManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isEditRemarkVisible, setIsEditRemarkVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getAccountManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='账号管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Form
              form={form}
              autoComplete='off'
              onFinish={(values) => {
                setParams({ ...params, ...values, current: 1 })
              }}
            >
              <Row
                align='middle'
                justify='space-between'
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <Space>
                  <Form.Item name='name' noStyle>
                    <Input
                      autoComplete='off'
                      placeholder='用户名/手机号'
                      style={{ width: 300 }}
                      onChange={() => form.submit()}
                      prefix={<SearchOutlined />}
                      allowClear
                    />
                  </Form.Item>
                  <Button type='primary' onClick={form.submit}>
                    查询
                  </Button>
                </Space>
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      setIsEditRemarkVisible(true)
                      setId('')
                    }}
                  >
                    新增账号
                  </Button>
                </Space>
              </Row>
            </Form>
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                switch (action) {
                  case 'edit':
                    setIsEditRemarkVisible(true)
                    setId(v.id)
                    break
                  case 'resetSecret':
                    Modal.confirm({
                      title: '是否重置密码？',
                      content: `重置密码为员工手机号码后6位`,
                      cancelText: '取消',
                      okText: '确认',
                      onOk: () =>
                        dispatch(
                          resetUserPassword({
                            userId: v?.id,
                          })
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                          }),
                    })
                    setId(v.id)
                    break
                  case 'stateEdit':
                    dispatch(
                      getAccountManagementUpload({
                        id: v.id,
                      })
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })

                    break

                  case 'delete':
                    Modal.confirm({
                      content: `是否删除？`,
                      cancelText: '取消',
                      okText: '确认',
                      onOk: () =>
                        dispatch(
                          deleteListFn(v?.id)
                        )
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                            getPage()
                          }),
                    })

                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
          <AddOrEditDetailModal
            id={id}
            visible={isEditRemarkVisible}
            onCancel={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
            onOk={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
          />
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const AccountManagementList = forwardRef(RealAccountManagements)
