/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:43:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-21 11:45:32
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/departmentManagement'

// 列表
export const getDepartmentManagementList = createAsyncThunk<
  void,
  DT.DepartmentManagementListPrams,
  RootThunkApi<void>
>('clinic/getDepartmentManagementList', async (params, api) => {
  return sendAsync(DT.departmentManagementList(params), api)
})

// 详情
export const getDepartmentManagementDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getDepartmentManagementDetail', async (params, api) => {
  return sendAsync(DT.departmentManagementDetail(params), api)
})

// 保存
export const getDepartmentManagementEditSubmit = createAsyncThunk<
  void,
  DT.DepartmentManagementEditPrams,
  RootThunkApi<void>
>('clinic/getDepartmentManagementEditSubmit', async (params, api) => {
  return sendAsync(DT.departmentManagementEditSubmit(params), api)
})

// 是否展示状态
export const getDepartmentManagementUpload = createAsyncThunk<
  void,
  DT.DepartmentManagementUploadPrams,
  RootThunkApi<void>
>('clinic/getDepartmentManagementUpload', async (params, api) => {
  return sendAsync(DT.departmentManagementUpload(params), api)
})

export const getIsSuggestDepartmentManagementUpload = createAsyncThunk<
  void,
  DT.IsSuggestDepartmentManagementUploadPrams,
  RootThunkApi<void>
>('clinic/getIsSuggestDepartmentManagementUpload', async (params, api) => {
  return sendAsync(DT.isSuggestDepartmentManagementUpload(params), api)
})



export const getOssUrl = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/ossUrl',
  async (params, api) => {
    return sendAsync(DT.ossUrl(params), api)
  }
)

