/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:00:31
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useState } from 'react'

import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { freePatientQueryParams } from '../../services/freePatients'
import { unwrapResult } from '@reduxjs/toolkit'
import { getDepartmentManagementList } from '../DepartmentManagement/departmentManagementSlice'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { AppointmentListPrams } from '../../services/appointment'

export const Query = (props: {
  onValueChange: (queries: AppointmentListPrams) => void
  export: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [form] = Form.useForm()

  const { Option } = Select

  const [departments, setDepartments] = useState<any[]>([])

  useEffect(() => {
    const [head, tail] = getDateRange(DateRangeType.Today)
    form.setFieldsValue({
      timeHead: head,
      timeTail: tail,
    })
    form.submit()
  }, [])

  useEffect(() => {
    dispatch(getDepartmentManagementList({ current: 1, size: 1000 }))
      .then(unwrapResult)
      .then((ds: any) => setDepartments(ds?.records))
      .catch(() => {
        // do nothing
      })
  }, [dispatch])
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='search' noStyle>
              <Input
                autoComplete='off'
                placeholder='姓名/预约ID/手机号/医生'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='departmentId'>
              <Select
                style={{ width: '10rem' }}
                placeholder='科室'
                allowClear
                onChange={form.submit}
              >
                {departments?.map((d) => (
                  <Select.Option
                    key={d.hisDepartmentId}
                    value={d.hisDepartmentId}
                  >
                    {d.hisDepartmentName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='时间范围'
              labelPrefix='时间范围-'
              namePrefix='time'
              allowClear={false}
              style={{ width: '8.5rem' }}
              initialValue={DateRangeType.Today}
              onChange={form.submit}
            />
            <Form.Item noStyle name='status'>
              <Select
                style={{ width: '10rem' }}
                placeholder='状态'
                allowClear
                onChange={form.submit}
              >
                <Select.Option key={1} value={1}>
                  预约成功
                </Select.Option>
                <Select.Option key={0} value={0}>
                  已取消
                </Select.Option>
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                props.export && props.export()
              }}
            >
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
