/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-14 17:59:29
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getReleaseStateName } from '../../models/health'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

export const columns = (
  onClick: (action: any, v?: any) => void,
  startIndex: number,
  sortedInfo: any
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '预约ID',
      dataIndex: 'id',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return r.id ? r.id : '-'
      },
    },
    {
      title: '门诊号',
      dataIndex: 'medicalCardNo',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.medicalCardNo ? r.medicalCardNo : '-'
      },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'age',
      align: 'center',
      width: '5rem',
      ellipsis: true,
      render: (_, r) => {
        return getAge(r?.ageYear, r?.ageMonth)
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '12rem',
      ellipsis: true,
      render: (_, r) => {
        return r.identityCard ? r.identityCard : '-'
      },
    },

    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },

    {
      title: '预约时间',
      dataIndex: 'appointDate',
      align: 'center',
      width: '14rem',
      ellipsis: true,
      key: 'appointDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'appointDate' && sortedInfo?.order,
      render: (_, r) => {
        return r?.appointDate
      },
    },
    {
      title: '预约科室',
      dataIndex: 'departmentName',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'result',
      render: (_, r) => {
        return r?.departmentName
      },
    },
    {
      title: '预约医生',
      dataIndex: 'docterName',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'docterName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'docterName' && sortedInfo?.order,
      render: (_, r) => {
        return r?.docterName
      },
    },
    // {
    //   title: '预约来源',
    //   dataIndex: '',
    //   align: 'center',
    //   width: '6rem',
    //   ellipsis: true,
    //   key: '',
    // },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'createTime',
      render: (_, r) => {
        return r?.createTime
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'result',
      render: (_, r) => {
        return r?.status == 1 ? '预约成功' : r?.status == 0 ? '已取消' : '-'
      },
    },
  ]
}
