/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-02-22 09:44:59
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 10:48:23
 */

import { API_PREFIX, WX_API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface IntegralDetailListPrams {
  current: number
  size: number
  memberId?: any
  param?: string
  type?: any
  startTime?: any
  endTime?: any
}

// 列表
export function IntegralDetailList(params: IntegralDetailListPrams): Request {
  return {
    url: `${API_PREFIX}/openUser/searchMemberPointsRecord`,
    method: 'GET',
    params,
  }
}
