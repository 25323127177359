/* * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:00:20
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { Dimen } from '../../models/dimen'
import femaleHead from '../images/female.png'
import maleHead from '../images/male.png'

type ActionType = 'edit' | 'stateEdit' | 'isSuggestStateEdit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '科室头图',
      dataIndex: 'name',
      align: 'center',
      ellipsis: true,
      width: '10rem',
      render: function ShowActions(_, r) {
        return r.deptLogoUrl ? (
          <img
            src={r.deptLogoUrl}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : r.sex == 2 ? (
          <img
            src={femaleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        ) : (
          <img
            src={maleHead}
            alt=''
            style={{ width: '30px', height: '30px', borderRadius: '50%' }}
          ></img>
        )
      },
    },
    {
      title: '科室名称',
      dataIndex: 'hisDepartmentName',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.hisDepartmentName ? r.hisDepartmentName : '-'
      },
    },
    {
      title: '科室擅长',
      dataIndex: 'deptSpecial',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.deptSpecial ? r.deptSpecial : '-'
      },
    },
    {
      title: '科室简介',
      dataIndex: 'itro',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.itro ? r.itro : '-'
      },
    },
    {
      title: '推荐科室',
      dataIndex: '',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, d): ReactElement {
        const active = d.status === 1
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {d.isSuggest == 0 ? '不推荐' : d.isSuggest == 1 ? '推荐' : ''}
            <Switch
              checked={Boolean(d.isSuggest == 1 ? true : false)}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                onClick('isSuggestStateEdit', d, e ? 1 : '0')
              }}
            />
          </div>
        )
      },
    },
    {
      title: '是否展示在小程序',
      dataIndex: '',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, d): ReactElement {
        const active = d.status === 1
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {d.disabledFlg == 0 ? '展示' : d.disabledFlg == 1 ? '不展示' : ''}

            <Switch
              checked={Boolean(d.disabledFlg == 0 ? true : false)}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                onClick('stateEdit', d, e == false ? 1 : '0')
              }}
            />
          </div>
        )
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '10rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('edit', u)}
              style={{ marginRight: '4px' }}
            >
              编辑
            </a>
          </>
        )
      },
    },
  ]
}
