/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-17 17:14:27
 */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getTinyUrl } from '../medicalGuide/medicalGuideSlice'
import styles from './departmentManagement.module.css'
import {
  getDepartmentManagementDetail,
  getDepartmentManagementEditSubmit,
  getOssUrl,
} from './departmentManagementSlice'

interface editDetailModalProps {
  name?: any
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditDetailModal = ({
  name,
  id,
  visible,
  onCancel,
  onOk,
}: editDetailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<RootDispatch>()

  const [imageUrl, setImageUrl] = useState<any>()

  const [detail, setDetail] = useState<any>()

  useEffect(() => {
    if (visible) {
      dispatch(getDepartmentManagementDetail(id))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      })
      setImageUrl(detail.deptLogoUrl)
    }
  }, [detail])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传照片</div>
    </div>
  )

  return (
    <Modal
      title='科室编辑'
      width={600}
      visible={visible}
      // destroyOnClose
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          dispatch(
            getDepartmentManagementEditSubmit({
              ...detail,
              ...values,
              deptLogoUrl: imageUrl,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              form.resetFields()
            })
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item label='科室名称' name='hisDepartmentName'>
          <Input style={{ width: '300px' }} disabled={true} />
        </Form.Item>
        <Form.Item
          label='科室头图'
          name='deptLogoUrl'
          rules={[{ required: true, message: '请上传科室头图' }]}
        >
          <Row>
            <Col>
              <Upload
                beforeUpload={(file) => {
                  if (file.size / 1024 < 100) {
                    return true
                  } else {
                    notification.info({ message: '图片大于100kb,请重新选择!' })
                    return false
                  }
                }}
                accept={'.png,.jpg,.jpeg,bmp'}
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                customRequest={async ({ file }) => {
                  if (file) {
                    const formData = new FormData()
                    // 向 FormData 中添加新的属性值 append()方法
                    formData.append('file', file)
                    dispatch(getOssUrl(formData))
                      .then(unwrapResult)
                      .then((v) => {
                        setImageUrl(v)
                        console.log(v, 'blobInfo')
                      })
                      .catch(() => {
                        return
                      })
                    // try {
                    //   params = unwrapResult(
                    //     await dispatch(getFileUploadToken('hydhis-public'))
                    //   )
                    //   const index = file.name.lastIndexOf('.')
                    //   const ext = index >= 0 ? file.name.substring(index) : ''
                    //   const key = unwrapResult(
                    //     await dispatch(
                    //       uploadFileCloud({
                    //         ...params,
                    //         file,
                    //         key: `${nanoid()}${ext}`,
                    //       })
                    //     )
                    //   )
                    //   onSuccess?.(null, (null as unknown) as XMLHttpRequest)
                    //   await dispatch(
                    //     getUrl({
                    //       fileName: key,
                    //       bucketName: 'hydhis-public',
                    //     })
                    //   ) //获取图片路径
                    //     .then(unwrapResult)
                    //     .then((res: any) => {
                    //       setImageUrl(res as string)
                    //     })
                    // } catch (e) {
                    //   notification.error({ message: '上传失败' })
                    // }
                    // setImageUrl((await getBase64(file)) as string)
                  }
                }}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt='avatar'
                    style={{ width: '100%', maxHeight: '102px' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: '14px',
                color: '#cbcacb ',
                margin: '0  0 4px 10px',
              }}
            >
              支持.jpg、.png、.jpeg等图片格式
              {/* <br /> 科室头像为空时用系统默认头像 */}
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label='简介'
          name='itro'
          rules={[{ required: true, message: '请输入简介' }]}
        >
          <Input.TextArea
            disabled={true}
            style={{ width: '400px' }}
            maxLength={500}
            showCount
            rows={3}
          />
        </Form.Item>
        <Form.Item
          label='擅长'
          name='deptSpecial'
          rules={[{ required: true, message: '请输入擅长' }]}
        >
          <Input.TextArea
            disabled={true}
            rows={3}
            style={{ width: '400px' }}
            maxLength={500}
            showCount
          />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Space>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
