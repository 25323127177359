/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:02:20
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:04:45
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:02:20
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:02:20
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/checklistManagement'

// 列表
export const getChecklistManagementList = createAsyncThunk<
  void,
  DT.ChecklistManagementPrams,
  RootThunkApi<void>
>('clinic/getHealthList', async (params, api) => {
  return sendAsync(DT.ChecklistManagementList(params), api)
})
