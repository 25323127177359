/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 09:24:25
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-29 15:27:03
 */
import { ADMIN_API_PREFIX, API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface AccountManagementListPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}

// 列表
export function accountManagementList(
  params: AccountManagementListPrams
): Request {
  return {
    url: `${API_PREFIX}/user/list`,
    method: 'GET',
    params,
  }
}

// 详情
export function accountManagementDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/user/detail`,
    method: 'GET',
    params: { id },
  }
}

export interface AccountManagementEditPrams {
  name?: string
  phone?: string
  id?: string
  menuList?: string
}

// 保存
export function accountManagementEditSubmit(
  body: AccountManagementEditPrams
): Request {
  return {
    url: `${API_PREFIX}/user/save`,
    method: 'POST',
    body,
  }
}

export interface AccountManagementUploadPrams {
  id?: string
}

// 保存
export function acountManagementUpload(
  params: AccountManagementUploadPrams
): Request {
  return {
    url: `${API_PREFIX}/user/logoutOrEnable`,
    method: 'POST',
    params,
  }
}

export interface ResetUserPasswordParams {
  userId: string
}

export function resetUserPassword(body: ResetUserPasswordParams): Request {
  return {
    url: `${API_PREFIX}/user/resetPwd`,
    method: 'POST',
    body
  }
}

export interface EditNameParams {
  userId: string
  name: string
}

export function editName(body: EditNameParams): Request {
  return {
    url: `${API_PREFIX}/user/updateName`,
    method: 'POST',
    body,
  }
}

export interface EditPasswordParams {
  userId: string
  newPwd: string
  oldPwd: string
}

export function editPassword(body: EditPasswordParams): Request {
  return {
    url: `${API_PREFIX}/user/updatePwd`,
    method: 'POST',
    body,
  }
}


export function menuList(): Request {
  return {
    url: `${API_PREFIX}/user/allMenu`,
    method: 'GET',
  }
}

export function deleteList(id: string): Request {
  return {
    url: `${API_PREFIX}/user/del`,
    method: 'POST',
    body: { id },
  }
}





