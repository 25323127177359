/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:52
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-13 15:24:10
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 16:00:31
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect } from 'react'
import { DateSelect } from '../../../compnents/form/DateSelect'
import {
  WorkOrderStatusCategories,
  getWorkOrderStatus,
} from '../../../models/workOrder'

export const Query = (props: {
  export: (v?: any) => void
  onValueChange: (queries: any) => void
  departmentList?: any
  topicsList?:any
}): ReactElement => {
  const [form] = Form.useForm()

  const { Option } = Select
  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='submiter' noStyle>
              <Input
                autoComplete='off'
                placeholder='请输入申请人'
                style={{ width: 200 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item name='topics' noStyle>
              <Select
                placeholder='报修主题'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {props?.topicsList?.map((v: any) => {
                  return (
                    <Option value={v} key={v}>
                      {v}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='departID' noStyle>
              <Select
                placeholder='部门'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {props?.departmentList?.map((v: any) => {
                  return (
                    <Option value={v?.id} key={v?.id}>
                      {v?.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item name='status' noStyle>
              <Select
                placeholder='状态'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {WorkOrderStatusCategories?.map((v: any) => {
                  return (
                    <Option value={v} key={v}>
                      {getWorkOrderStatus(v?.toString())}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='时间'
              labelPrefix='时间-'
              namePrefix='time'
              style={{ width: '8.5rem' }}
              onChange={form.submit}
            />
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
            <Button type='primary' onClick={() => props?.export()}>
              导出
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
