/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-11 11:50:50
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getReleaseStateName } from '../../models/health'

export const columns = (
  onClick: (action: any, v?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '就诊人',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.identityCard ? r.identityCard : '-'
      },
    },

    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '医生姓名',
      dataIndex: 'docname',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.docname ? r.docname : '-'
      },
    },
    {
      title: '报告单',
      dataIndex: 'filename',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('lookMore', u)}
              style={{ marginRight: '4px' }}
            >
              查看
            </a>
          </>
        )
      },
    },

    {
      title: '上传时间',
      dataIndex: 'result',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'result',
      render: (_, r) => {
        return r?.createTime
      },
    },
  ]
}
