import {
    Button,
    Col,
    Tabs,
    Row,
    TableColumnType,
    Form,
    Space,
    Input,
    Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement } from 'react'
import {} from 'antd'

export const Query = (props: {
  onValueChange: (queries: any) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='param' noStyle>
              <Input
                autoComplete='off'
                placeholder='用户名/手机号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
