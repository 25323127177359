/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-29 16:22:34
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/accountManagement'

// 列表
export const getAccountManagementList = createAsyncThunk<
  void,
  DT.AccountManagementListPrams,
  RootThunkApi<void>
>('clinic/getAccountManagementList', async (params, api) => {
  return sendAsync(DT.accountManagementList(params), api)
})

// 详情
export const getAccountManagementDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getAccountManagementDetail', async (params, api) => {
  return sendAsync(DT.accountManagementDetail(params), api)
})

// 保存
export const getAccountManagementEditSubmit = createAsyncThunk<
  void,
  DT.AccountManagementEditPrams,
  RootThunkApi<void>
>('clinic/getAccountManagementEditSubmit', async (params, api) => {
  return sendAsync(DT.accountManagementEditSubmit(params), api)
})

// 是否展示状态
export const getAccountManagementUpload = createAsyncThunk<
  void,
  DT.AccountManagementUploadPrams,
  RootThunkApi<void>
>('clinic/getAccountManagementUpload', async (params, api) => {
  return sendAsync(DT.acountManagementUpload(params), api)
})

// 重置密码
export const resetUserPassword = createAsyncThunk<
  void,
  DT.ResetUserPasswordParams,
  RootThunkApi<void>
>('user/resetUserPasswordStatus', async (params, api) => {
  return sendAsync(DT.resetUserPassword(params), api)
})

// 编辑姓名
export const getEditNameSubmit = createAsyncThunk<
  void,
  DT.EditNameParams,
  RootThunkApi<void>
>('user/editNameParams', async (params, api) => {
  return sendAsync(DT.editName(params), api)
})

// 编辑密码
export const getEditPasswordSubmit = createAsyncThunk<
  void,
  DT.EditPasswordParams,
  RootThunkApi<void>
>('user/editPasswordParams', async (params, api) => {
  return sendAsync(DT.editPassword(params), api)
})

// 查询菜单
export const getMenuList = createAsyncThunk<any, void, RootThunkApi<void>>(
  'user/MenuList',
  async (params, api) => {
    return sendAsync(DT.menuList(), api)
  }
)

// 查询菜单
export const deleteListFn = createAsyncThunk<any, any, RootThunkApi<void>>(
  'user/deleteList',
  async (params, api) => {
    return sendAsync(DT.deleteList(params), api)
  }
)
