/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-18 11:54:19
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getReleaseStateName } from '../../models/health'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

type ActionType = 'lookDetail' | 'edit'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.nickname ? r.nickname : '-'
      },
    },
    {
      title: '账号',
      dataIndex: 'patientPhonenumber',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.patientPhonenumber ? r.patientPhonenumber : '-'
      },
    },

    {
      title: '绑定的就诊人数',
      dataIndex: 'jzrs',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.jzrs
      },
    },
    {
      title: '就诊人详情',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('lookDetail', u)}
              style={{ marginRight: '4px' }}
            >
              查看
            </a>
          </>
        )
      },
    },
    {
      title: '是否员工',
      dataIndex: 'isEmployee',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.isEmployee == 1 ? '是' : r.isEmployee == 0 ? '否' : '-'
      },
    },
    {
      title: '用户姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r?.name : '-'
      },
    },
    {
      title: '所在部门',
      dataIndex: 'departmentName',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.departmentName ? r?.departmentName : '-'
      },
    },
    {
      title: '是否主管',
      dataIndex: 'isManager',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.isManager == 1 ? '是' : r.isManager == 0 ? '否' : '-'
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('edit', t)
              }}
            >
              编辑
            </a>
          </>
        )
      },
    },
  ]
}

export const patientListColumns = (): TableColumnType<any>[] => {
  return [
    {
      title: '关系',
      dataIndex: 'relationType',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.relationType ? r.relationType : '-'
      },
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '性别',
      dataIndex: 'sex',
      align: 'center',
      render: (_, r) => {
        return getGenderName(r?.sex)
      },
    },
    {
      title: '年龄',
      dataIndex: 'ageYear',
      align: 'center',
      render: (_, r) => {
        return getAge(r?.ageYear, r?.ageMonth)
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '14rem',
      ellipsis: true,
      render: (_, r) => {
        return r.identityCard ? r.identityCard : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      key: 'organName',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
  ]
}
