import { RecipeCategory } from "../models/recipe";
import { API_PREFIX } from "./constant";
import { Request } from "./request";

export function getMaterialCompletions(
  params: { keyword: string; current: number },
  recipeCategory?: RecipeCategory,
  includeAllTcm?: boolean,
): Request {
  let flag = 0;
  switch (recipeCategory) {
    case RecipeCategory.Medicine:
      flag = 1;
      break;
    case RecipeCategory.TcmGranules:
      flag = includeAllTcm ? 4 : 2;
      break;
    case RecipeCategory.Tcm:
      flag = includeAllTcm ? 4 : 3;
      break;
  }
  return {
    url: `${API_PREFIX}/blade-store/materialscatalog/page`,
    method: "GET",
    params: {
      allStr: params.keyword,
      materialsCategory: -1,
      allMaterials: -1,
      tenantSupplierId: -1,
      state: 1,
      otc: -1,
      current: params.current,
      size: 5,
      flag: recipeCategory === undefined ? undefined : flag,
    },
  };
}

export function getInventoryCompletions(
  params: {
    keyword: string;
    current: number;
    storehouseId?: string;
    merge?: boolean;
    serviceShopPrice?:string
  },
  recipeCategory?: RecipeCategory,
  includeAllTcm?: boolean,
): Request {
  let flag = 0;
  switch (recipeCategory) {
    case RecipeCategory.Medicine:
      flag = 1;
      break;
    case RecipeCategory.TcmGranules:
      flag = includeAllTcm ? 4 : 2;
      break;
    case RecipeCategory.Tcm:
      flag = includeAllTcm ? 4 : 3;
      break;
  }
  return {
    url: `${API_PREFIX}/blade-store/inventoryledger/page`,
    method: "GET",
    params: {
      name: params.keyword,
      current: params.current,
      size: 5,
      state: 1,
      flag,
      storehouseId: params.storehouseId,
      mergeFlag: params.merge ? 1 : 0,
      serviceShopPrice:1
    },
  };
}

export function getTreatmentServiceCompletions(params: {
  keyword: string;
  current: number;
}): Request {
  return {
    url: `${API_PREFIX}/blade-store/treatmentServiceCatalog/page`,
    method: "GET",
    params: {
      billingCategory: -1,
      state: 1,
      size: 5,
      current: params.current,
      name: params.keyword,
    },
  };
}
