/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-03-25 19:44:30
 * @LastEditors: sj
 * @LastEditTime: 2022-06-14 12:43:34
 */
import { AccountType } from "../models/accounttype"
import { API_PREFIX } from "./constant"
import { Request } from "./request"

export function createToken(username: string, passWord: string): Request {
  return {
    url: `${API_PREFIX}/auth/login`,
    method: 'POST',
    params: {
      phone: username,
      passWord,
    },
  }
}

export function getTenantByUsername(username: string): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/queryByPhone`,
    method: "GET",
    params: {
      phone: username,
    },
  }
}

export function getVasContent(): Request {
  return {
    url: `${API_PREFIX}/blade-user/user/outgetvaskey`,
    method: "GET",
  }
}
export interface ThirdPartyLoginParams {
  accountType: AccountType
  isLogin: boolean
  redirectUrl?: string
}

export function getThirdPartyLoginUrl(params: ThirdPartyLoginParams): Request {
  let source
  switch (params.accountType) {
    case AccountType.Alipay:
      source = "alipay"
      break
    default:
      source = ""
      break
  }
  return {
    url: `${API_PREFIX}/blade-user/oauth/render/${source}`,
    method: "POST",
    body: {
      frontCallBackUrl: params.redirectUrl,
      type: params.isLogin ? 0 : 1,
    },
  }
}

export interface ThirdPartyLoginTokenParams {
  accountType: AccountType
  isLogin: boolean
  others: any
}

// 支付宝登录
export function getThirdPartyLoginToken(
  params: ThirdPartyLoginTokenParams
): Request {
  let source
  switch (params.accountType) {
    case AccountType.Alipay:
      source = "alipay"
      break
    default:
      source = ""
      break
  }
  return {
    url: `${API_PREFIX}/blade-user/oauth/callback/${source}`,
    method: "POST",
    body: {
      ...params.others,
      type: params.isLogin ? 0 : 1,
    },
  }
}

export function unbindAccount(accountType: AccountType): Request {
  let source
  switch (accountType) {
    case AccountType.Alipay:
      source = "alipay"
      break
    default:
      source = ""
      break
  }
  return {
    url: `${API_PREFIX}/blade-user/user/unbind/${source}`,
    method: "POST",
    body: {},
  }
}

export function getScanUrl(): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/scanlogin/url`,
    method: "GET",
  }
}

export interface ScanQueryParams {
  uid: string
  sign: string
}

export function queryScanStatus(params: ScanQueryParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/scanlogin/get`,
    method: "POST",
    params,
  }
}

export function replaceToken(): Request {
  return {
    url: `${API_PREFIX}/blade-user/auth/token/change`,
    method: "GET",
  }
}

// 电子发票三方授权
export interface InvoiceTokenParams {
  code: string
  supplierId: string
  taxNo: string
  tenantId: string
}
export function invoiceToken(body: InvoiceTokenParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/dzfp/getToken`,
    method: 'POST',
    body,
  }
}