import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import { getDivisionManagementList, getDivisionManagementDelete } from './divisionManagementSlice'
import { EditDetailModal } from './editDetailModal'

export interface DivisionManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface DivisionManagementProps {
  tabKey?: any
}
const RealDivisionManagementLists = (
  { tabKey }: DivisionManagementProps,
  ref: React.Ref<DivisionManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isEditVisible, setIsEditVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [name, setName] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getDivisionManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        console.log(res, "323");

        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='部门管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row
              align='middle'
              justify='space-between'
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <Space>
                <Button
                  type='primary'
                  onClick={() => {
                    setIsEditVisible(true)
                    setId('')
                    setName('')
                  }}
                >
                  添加部门
                </Button>
              </Space>
            </Row>
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                switch (action) {
                  case 'edit':
                    setIsEditVisible(true)
                    setId(v.id)
                    setName(v?.name)
                    break
                  case 'delete':
                    dispatch(
                      getDivisionManagementDelete(v.id)
                    ).then(unwrapResult)
                      .then((res: any) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })
                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
          <EditDetailModal
            id={id}
            name={name}
            visible={isEditVisible}
            onCancel={() => {
              setIsEditVisible(false)
              setId('')
              getPage()
            }}
            onOk={() => {
              setIsEditVisible(false)
              setId('')
              getPage()
            }}
          />
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const DivisionManagementList = forwardRef(RealDivisionManagementLists)
