/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-04-13 14:20:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-27 17:10:34
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, notification, Radio, Row, Spin, Tabs } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from '../../../theme/ThemeContext'
import styles from './detail.module.css'
import { RootDispatch } from '../../../app/store'
import { FreePatientDetailList } from './detail/detailTable'
import { EditableList } from '../../../compnents/list/EditableList'
import { NavBar } from '../../../compnents/nav/NavBar'
import {
  getFreePatientsDetail,
  getFreeDetailTable,
  getFreeDetail,
  getFreeDetailTablePrint,
  getNum,
  getDetailExport
} from '../freePatientSlice'
import { PrintFrame } from '../../print/PrintFrame'

export const FreePatientDetailListMain = () => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const { TabPane } = Tabs

  const theme = useContext(ThemeContext)

  const [num, setNum] = useState<any>()

  const [params, setParams] = useState<any>()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const [pages, setPages] = useState<any>({
    items: [],
    total: 0,
  })

  const [printData, setPrintPage] = useState<any>({
    items: [],
    total: 0,
  })

  const [freeTreatmentResult, setFreeTreatmentResult] = useState<any>()

  const [selectedTab, setSelectedTab] = useState<any>('0')

  const [freeTreatmentSchedules, setFreeTreatmentSchedules] = useState<any>()

  const [bottomListParams, setBottomListParams] = useState<any>()

  // const [params, setParams] = useState<any>({
  //   current: 1,
  //   size: 10,
  // })

  useEffect(() => {
    if (location?.state?.id) {
      dispatch(getFreePatientsDetail(location?.state?.id))
        .then(unwrapResult)
        .then((v: any) => {
          setFreeTreatmentResult(v?.freeTreatmentResult)
          setPages({
            items: [v?.freeTreatmentResult],
            total: 0,
          })
          const m = v?.freeTreatmentSchedules?.filter((i: any) => {
            return i?.classes == 0
          })
          const a = v?.freeTreatmentSchedules?.filter((i: any) => {
            return i?.classes == 1
          })
          setFreeTreatmentSchedules(m?.length ? m : a)
          const tabList = m?.length ? m : a
          if (v?.freeTreatmentSchedules?.length) {
            if (selectedTab) {
              const list = tabList?.[Number(selectedTab)]
              console.log(selectedTab, list, m, 'selectedTab')
              setBottomListParams(list)
            }
          }
        })
    }
  }, [location?.state?.id, selectedTab])

  const getPage = () => {
    dispatch(
      getFreeDetailTable({
        current: 1,
        size: 1000,
        doctorId: bottomListParams?.docterId,
        treatmentId: bottomListParams?.treatmentId,
        ...params,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setPage({
          items: v.records,
          total: v.total,
        })
      })

    dispatch(
      getNum({
        doctorId: bottomListParams?.docterId,
        treatmentId: bottomListParams?.treatmentId,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setNum(v)
      })

    dispatch(
      getFreeDetailTablePrint({
        current: 1,
        size: 1000,
        doctorId: bottomListParams?.docterId,
        treatmentId: bottomListParams?.treatmentId,
        ...params,
      })
    )
      .then(unwrapResult)
      .then((v: any) => {
        setPrintPage({
          items: v?.ihTenantFreeTreatmentAppointmentList?.records,
          total: v?.ihTenantFreeTreatmentAppointmentList?.total,
          address: v?.address,
          title: v?.title,
          treatmentDate: v?.treatmentDate,
          timestr: v?.timestr,
          doctorName: v?.doctorname == '-' ? '' : v?.doctorname
          // doctorName:
          //   v?.ihTenantFreeTreatmentAppointmentList?.records?.[0]
          //     ?.appointmentDoctorName == '-'
          //     ? '                '
          //     : v?.ihTenantFreeTreatmentAppointmentList?.records?.[0]
          //         ?.appointmentDoctorName,
        })
      })
  }


  const detailExport = () => {
    dispatch(getDetailExport({ 
      current: 1,
      size: 1000,
      doctorId: bottomListParams?.docterId,
      treatmentId: bottomListParams?.treatmentId,
      ...params,
     }))
      .then(unwrapResult)
      .then((res: any) => {
        const content = res
        const blob = new Blob([content])
        const fileName = '义诊详情.xlsx'
        const selfURL = window[window.webkitURL ? 'webkitURL' : 'URL']
        const elink = document.createElement('a')
        if ('download' in elink) {
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = selfURL['createObjectURL'](blob)
          document.body.appendChild(elink)
          // 触发链接
          elink.click()
          selfURL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      })
      .then(() => {
        notification.success({
          message: '导出成功',
          duration: 3,
        })
      })
      .catch((res: any) => {
        notification.success({
          message: '导出失败',
          duration: 3,
        })
      })
  }

  useEffect(() => {
    console.log(bottomListParams, 'bottomListParams')
    // 底部tables数据
    if (bottomListParams?.docterId) {
      getPage()
    }
  }, [bottomListParams, params])

  return (
    <>
      <div className={styles?.container}>
        <Col
          className={styles.addOrEditDetailMain}
          style={{
            margin: '10px 0',
          }}
        >
          <>
            <NavBar
              where={['活动列表', '详情']}
              backtrace={{
                name: '活动管理',
                path: '/admin/freePatient',
                routeName: '活动管理',
              }}
            />
          </>
        </Col>
        <div className={styles.containerMain}>
          <div className={styles.oneBlock}>
            <div className={styles.oneBlockTitle}>活动收入情况</div>
            <div>
              <EditableList
                rowKey={(_, i) => `${i}}`}
                pagination={false}
                page={{
                  items: pages.items,
                  current: 1,
                  size: 10,
                  total: pages.total,
                }}
                loading={pageLoading}
                columns={[
                  {
                    title: '活动主题',
                    dataIndex: 'name',
                    align: 'center',
                    key: 'name',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.name ? r?.name : '-'
                    },
                  },

                  {
                    title: '服务人次',
                    dataIndex: 'treatmentCount',
                    align: 'center',
                    key: 'treatmentCount',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.treatmentCount > 0 ? r?.treatmentCount : '-'
                    },
                  },
                  {
                    title: '活动收入',
                    dataIndex: 'costAmount',
                    align: 'center',
                    key: 'costAmount',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.costAmount > 0
                        ? r?.costAmount?.toFixed(2)
                        : '0.00'
                    },
                  },
                  {
                    title: '活动  回流收入',
                    dataIndex: 'returnAmount',
                    align: 'center',
                    key: 'returnAmount',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.returnAmount > 0
                        ? r?.returnAmount?.toFixed(2)
                        : '0.00'
                    },
                  },
                  {
                    title: '活动费用（专家补贴）',
                    dataIndex: 'programAmount',
                    align: 'center',
                    key: 'programAmount',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.programAmount > 0
                        ? r?.programAmount?.toFixed(2)
                        : '0.00'
                    },
                  },
                  {
                    title: '活动外出人员（补贴）',
                    dataIndex: 'peopleAmount',
                    align: 'center',
                    key: 'peopleAmount',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.peopleAmount > 0
                        ? r?.peopleAmount?.toFixed(2)
                        : '0.00'
                    },
                  },
                  {
                    title: '备注',
                    dataIndex: 'remark',
                    align: 'center',
                    key: 'remark',
                    ellipsis: true,
                    render: (_, r: any) => {
                      return r?.remark ? r?.remark : '-'
                    },
                  },
                ]}
              />
            </div>
          </div>
          <div className={styles.threeBlock}>
            <div className={styles.oneBlockTitle}>义诊总结</div>
            <div className={styles.threeBlockContent}>
              {freeTreatmentResult?.summary || '-'}
            </div>
          </div>
          <div className={styles.twoBlock}>
            <div style={{ marginBottom: '20px' }}>
              <Tabs
                activeKey={selectedTab}
                onChange={(v) => {
                  setSelectedTab(v == undefined || v == null ? '0' : v)
                }}
              >
                {freeTreatmentSchedules?.map((v: any, index: any) => {
                  return (
                    <>
                      <TabPane
                        tab={
                          v?.docterName +
                          '（' +
                          (v?.departmentName ? v?.departmentName : '-') +
                          '）'
                        }
                        key={index}
                      >
                        <FreePatientDetailList
                          num={num}
                          selectTabKey={selectedTab}
                          paramsTag={(v) => {
                            setParams(v)
                          }}
                          refresh={() => {
                            getPage()
                          }}
                          page={page}
                          printDataList={printData}
                          detailExport={detailExport}
                        />
                      </TabPane>
                    </>
                  )
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
