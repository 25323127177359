/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-08 14:07:37
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getReleaseStateName } from '../../models/health'

type ActionType = 'edit' | 'publish' | 'delete' | 'withdraw' | 'detail'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
      width: '6rem',
      ellipsis: true,
      render: (_, r) => {
        return r.title ? r.title : '-'
      },
    },

    {
      title: '排序',
      dataIndex: 'sorter',
      align: 'center',
      ellipsis: true,
      width: '6rem',
      render: (_, r) => {
        return r.sorter ? r.sorter : '-'
      },
    },
    {
      title: '发布时间',
      dataIndex: 'pushTime',
      align: 'center',
      ellipsis: true,
      width: '12rem',
      render: (_, r) => {
        return r.pushTime ? r.pushTime : '-'
      },
    },
    {
      title: '发布状态',
      dataIndex: 'state',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'state',
      render: (_, r) => {
        return getReleaseStateName(r.state?.toString())
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      render: function UserSettings(_, t): ReactElement {
        return (
          <>
            {t.state == 0 || t.state == 2 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('publish', t)
                  }}
                >
                  发布
                </a>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('edit', t)
                  }}
                >
                  编辑
                </a>
              </>
            ) : (
              ''
            )}
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              详情
            </a>
            {t.state == 1 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('withdraw', t)
                  }}
                >
                  撤回
                </a>
              </>
            ) : t.state == 0 || t.state == 2 ? (
              <a
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  onClick('delete', t)
                }}
              >
                删除
              </a>
            ) : (
              ''
            )}
          </>
        )
      },
    },
  ]
}
