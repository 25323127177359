/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 09:32:03
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 10:55:53
 */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api, sendAsync } from '../../app/applicationSlice'
import { RootState, RootThunk, RootThunkApi } from '../../app/store'
import * as DT from '../../services/member'

export const getMemberList = createAsyncThunk<
  void,
  DT.MemberQueryParams,
  RootThunkApi<void>
>('clinic/getMemberList', async (params, api) => {
  return sendAsync(DT.memberList(params), api)
})

// 保存
export const getIntegralWriteOffSubmit = createAsyncThunk<
  void,
  DT.IntegralWriteOffParams,
  RootThunkApi<void>
>('clinic/getIntegralWriteOffSubmit', async (params, api) => {
  return sendAsync(DT.integralWriteOffDetailSubmit(params), api)
})
