/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-05-25 09:17:44
 * @LastEditors: linxi
 * @LastEditTime: 2023-05-11 11:56:09
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import {
  DateTimeFormat,
  DateTimeFormatSimple,
  DateTimeFormatSimpleOnly,
} from '../../models/datetime'
import { Dimen } from '../../models/dimen'
import { FreePatientStateName } from '../../models/freePatients'

type ActionType =
  | 'downLoad'
  | 'detail'
  | 'edit'
  | 'delete'
  | 'withdraw'
  | 'over'
  | 'stationFree'
  | 'publish'
  | 'stationFreeDetail'

export const Columns = (
  onClick: (action: ActionType, v: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
      width: '15rem',
      ellipsis: true,
      key: 'title',
      // render: (_, r) => {
      //   return r.title ? r.title : '-'
      // },
      render: function Title(_, r): ReactElement {
        return (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {r.title ? r.title : '-'}
          </div>
        )
      },
    },
    {
      title: '活动内容',
      key: 'content',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      dataIndex: 'content',
      render: (_, r) => {
        return r.content ? r.content : '-'
      },
    },

    {
      title: '活动日期',
      dataIndex: 'treatmentDate',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'treatmentDate',
      render: (_, r) => {
        return r.treatmentDate
          ? moment(r.treatmentDate).format(DateTimeFormatSimple)
          : '-'
      },
    },
    {
      title: '报名截止时间',
      dataIndex: 'deadline',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'deadline',
      render: (_, r) => {
        return moment(r.deadline).format(DateTimeFormat)
      },
    },
    {
      title: '活动名额',
      dataIndex: 'treatmentCount',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'treatmentCount',
      render: (_, r) => {
        return r.treatmentCount ? r.treatmentCount + '位' : '-'
      },
    },
    {
      title: '活动地址',
      dataIndex: 'address',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      key: 'address',
      render: (_, r) => {
        return r.address ? r.address : '-'
      },
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'phone',
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '活动医生',
      dataIndex: 'treatmentDocter',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      key: 'treatmentDocter',
      render: (_, r) => {
        return r.treatmentDocter ? r.treatmentDocter : '-'
      },
    },
    {
      title: '二维码',
      dataIndex: 'result',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'result',
      render: function DownLoad(_, t): ReactElement {
        return (
          <>
            {t?.status == 0 || t?.status == 5 ? (
              <>-</>
            ) : (
              <a
                href='javascript:void(0)'
                onClick={(e) => {
                  onClick('downLoad', t)
                }}
              >
                下载
              </a>
            )}
          </>
        )
      },
    },
    {
      title: '活动状态',
      dataIndex: 'status',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'status',
      render: (_, r) => {
        return FreePatientStateName(r.status?.toString())
      },
    },
    {
      title: '操作',
      dataIndex: 'status',
      align: 'center',
      width: '16rem',
      ellipsis: true,
      key: 'status',
      render: function DownLoad(_, t): ReactElement {
        return (
          <>
            <a
              style={{ marginRight: 10 }}
              onClick={(e) => {
                onClick('detail', t)
              }}
            >
              详情
            </a>
            {t.status != 4 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('edit', t)
                  }}
                >
                  编辑
                </a>

              </>
            ) : (
              ''
            )}
            {t.status == 0 || t.status == 5 ? (
              <a
                style={{ marginRight: 10 }}
                onClick={(e) => {
                  onClick('publish', t)
                }}
              >
                发布
              </a>
            ) : ('')}
            {t.status != 4 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('delete', t)
                  }}
                >
                  删除
                </a>
              </>
            ) : ('')}
            {t.status == 1 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('withdraw', t)
                  }}
                >
                  撤回
                </a>
              </>
            ) : ('')} 
            {t.status == 2 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('over', t)
                  }}
                >
                  完成
                </a>
              </>
            ) : ('')} 
            {t.status == 3 || t.status == 4 ? (
              <>
                <a
                  style={{ marginRight: 10 }}
                  onClick={(e) => {
                    onClick('stationFree', t)
                  }}
                >
                  填写义诊情况
                </a>
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ]
}

export const PrintColumns = (startIndex: number): TableColumnType<any>[] => {
  return [
    {
      title: '日期',
      dataIndex: 'date',
      align: 'center',
      key: 'date',
      ellipsis: true,
      render: (_, r: any) => {
        return r?.date ? r?.date : '-'
      },
    },
    {
      title: '时间',
      dataIndex: 'time',
      align: 'center',
      key: 'time',
      ellipsis: true,
      render: function getName(_, r): ReactElement {
        const timeList = r?.time?.split(',')
        console.log(timeList, 'timeList')

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              padding: '0 15px 0 0 ',
              width: '8rem',
              wordBreak: 'break-all',
            }}
          >
            <div>{timeList?.[0]}</div>
            <div>{timeList?.[1]}</div>
          </div>
        )
      },
    },
    {
      title: '主题',
      dataIndex: 'name',
      align: 'center',
      key: 'name',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.name ? r?.name : '-'}
          </div>
        )
      },
    },

    {
      title: '服务人次',
      dataIndex: 'treatmentCount',
      align: 'center',
      key: 'treatmentCount',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.treatmentCount > 0 ? r?.treatmentCount : '-'}
          </div>
        )
      },
    },
    {
      title: '活动收入',
      dataIndex: 'costAmount',
      align: 'center',
      key: 'costAmount',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.costAmount ? r?.costAmount?.toFixed(2) : '-'}
          </div>
        )
      },
    },
    {
      title: '活动回流收入',
      dataIndex: 'returnAmount',
      align: 'center',
      key: 'returnAmount',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.returnAmount ? r?.returnAmount?.toFixed(2) : '-'}
          </div>
        )
      },
    },
    {
      title: '活动费用(专家补贴)',
      dataIndex: 'programAmount',
      align: 'center',
      key: 'programAmount',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.returnAmount ? r?.returnAmount?.toFixed(2) : '-'}
          </div>
        )
      },
    },

    {
      title: '活动外出人员(补贴)',
      dataIndex: 'peopleAmount',
      align: 'center',
      key: 'peopleAmount',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-all',
            }}
          >
            {r?.peopleAmount ? r?.peopleAmount?.toFixed(2) : '-'}
          </div>
        )
      },
    },
    {
      title: '备注',
      dataIndex: 'remark',
      align: 'center',
      key: 'remark',
      render: function getName(_, r): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'pre-wrap',
              padding: '0 15px 0 0',
              wordBreak: 'break-all',
            }}
          >
            {r?.remark ? r?.remark : '-'}
          </div>
        )
      },
    },
  ]
}
