import axios, { Method } from 'axios'
import qs from 'qs'
import { useHistory } from 'react-router-dom'
import { ServiceError } from './errors'

export interface Request {
  url: string
  method: Method
  headers?: any
  params?: any
  body?: any
  responseType?: any
}

export const API_PREFIX = '/admin-api'

export const ContentType = 'content-type'

export const FormUrlEncoded = 'application/x-www-form-urlencoded'

export const MultiPartFormData = 'multipart/form-data'

export const TextPlain = 'text/plain'

function transform<T>(response?: Record<string, Record<string, unknown>>): T {
  if (!response) {
    throw new ServiceError(500, 'response is not defined.')
  }
  const data = response.data
  if (!response.data) {
    throw new ServiceError(500, 'response body is not defined.')
  }
  const code = data.code as number
  if (code !== 200) {
    throw new ServiceError(code, data.msg as string)
  }
  return data.data as T
}

export async function send<T>(request: Request): Promise<T> {
  let params
  let body

  const header = request.headers && request.headers[ContentType]
  switch (header) {
    case FormUrlEncoded:
      body = qs.stringify(request.params)
      break
    case MultiPartFormData:
      // eslint-disable-next-line no-case-declarations
      const formData = new FormData()
      Object.entries(request.params).forEach(([key, val]) => {
        // HACK - make type happy…
        const hackVal = val as string | File
        formData.append(key, hackVal)
      })
      body = formData
      break
    default:
      params = request.params
      body = request.body
  }

  return axios({
    url: request.url,
    method: request.method,
    headers: request.headers,
    params,
    data: body,
    responseType: request.responseType || 'json',
  })
    .then((resp) =>
      request.responseType === 'blob'
        ? resp.data
        : transform(
            (resp as unknown) as Record<string, Record<string, unknown>>
          )
    )
    .catch((e) => {
      const code = e.response?.status || 500
      const message = e.response?.data?.msg
      throw new ServiceError(code, message ? message : e.message)
    })
}
