/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-06 14:12:48
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, notification, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import styles from './accountManagement.module.css'
import { getEditPasswordSubmit } from './accountManagementSlice'

interface editUserPassWordModalProps {
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditUserPassWordModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: editUserPassWordModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  return (
    <Modal
      title='修改密码'
      width={600}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          console.log(values, 'values')
          if (values?.newPwd == values?.surePassword) {
            dispatch(
              getEditPasswordSubmit({
                oldPwd: values?.oldPwd,
                newPwd: values?.newPwd,
                userId: id,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                notification.success({
                  message: '操作成功',
                  duration: 3,
                })
                onOk()
                form.resetFields()
              })
          } else {
            notification.success({
              message: '新密码与确认密码不一致，请确认',
              duration: 3,
            })
          }
        }}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
      >
        <div style={{ height: '220px' }}>
          <Form.Item
            label='旧密码'
            name='oldPwd'
            style={{ marginRight: '55px' }}
            required
          >
            <Input style={{ width: '300px' }} autoComplete='off' />
          </Form.Item>
          <Form.Item
            label='新密码'
            name='newPwd'
            style={{ marginRight: '55px' }}
            required
            rules={[
              { required: true, message: '新密码不能为空' },
              {
                pattern: /^\w{5,17}$/,
                message: '请输入正确格式的密码',
              },
            ]}
          >
            <Input
              style={{ width: '300px' }}
              autoComplete='off'
              maxLength={12}
              placeholder='格式：英文字母、数字任选，6～12位'
            />
          </Form.Item>
          {/* <Col
            style={{
              color: '#666666',
              fontSize: '12px',
              margin: '0px 0 6px 124px',
            }}
          >
            
          </Col> */}
          <Form.Item
            label='确认密码'
            name='surePassword'
            style={{ marginRight: '55px' }}
            required
            rules={[
              { required: true, message: '确认密码不能为空' },
              {
                pattern: /^\w{5,17}$/,
                message: '请输入正确格式的密码',
              },
            ]}
          >
            <Input
              style={{ width: '300px' }}
              autoComplete='off'
              maxLength={12}
            />
          </Form.Item>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              paddingTop: '10px',
            }}
          >
            <Space>
              <Button
                onClick={() => {
                  onCancel()
                  form.resetFields()
                }}
              >
                取消
              </Button>
              <Button type='primary' htmlType='submit'>
                确定
              </Button>
            </Space>
          </div>
        </div>
      </Form>
    </Modal>
  )
}
