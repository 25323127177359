/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 09:48:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 14:23:27
 */
/* * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:00:20
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { Dimen } from '../../models/dimen'
import femaleHead from '../images/female.png'
import maleHead from '../images/male.png'

type ActionType = 'edit' | 'stateEdit' | 'isSuggestStateEdit' | 'delete'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
 
    {
      title: '部门名称',
      dataIndex: 'name',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '部门管理员',
      dataIndex: 'managers',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.managers ? r.managers : '-'
      },
    },

    {
      title: '操作',
      key: 'actions',
      width: '10rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('edit', u)}
              style={{ marginRight: '10px' }}
            >
              编辑
            </a>
            <a
              onClick={() => onClick('delete', u)}
            >
              删除
            </a>
          </>
        )
      },
    },
  ]
}
