/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 14:43:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-13 15:55:26
 */
/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-01-04 10:50:48
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/divisionManagement'

// 列表
export const getDivisionManagementList = createAsyncThunk<
  void,
  DT.DivisionManagementListPrams,
  RootThunkApi<void>
>('clinic/getDivisionManagementList', async (params, api) => {
  return sendAsync(DT.divisionManagementList(params), api)
})

// 部门人员列表
export const getDepartCountWithUserList = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getDepartCountWithUserList', async (params, api) => {
  return sendAsync(DT.departCountWithUserList(params), api)
})
// 详情
export const getDivisionManagementDetail = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getDivisionManagementDetail', async (params, api) => {
  return sendAsync(DT.divisionManagementDetail(params), api)
})

// 保存
export const getDivisionManagementEditSubmit = createAsyncThunk<
  void,
  DT.DivisionManagementEditPrams,
  RootThunkApi<void>
>('clinic/getDivisionManagementEditSubmit', async (params, api) => {
  return sendAsync(DT.divisionManagementEditSubmit(params), api)
})

//删除
export const getDivisionManagementDelete = createAsyncThunk<
  void,
  number,
  RootThunkApi<void>
  >('clinic/divisionManagementDelete', async (params, api) => {
  return sendAsync(DT.divisionManagementDelete(params), api)
})

