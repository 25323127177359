import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface PatientsManagementPrams {
  current: number
  size: number
  key?: string
  openid?: any
  patientPhonenumber?: any
}

// 列表
export function patientsManagementList(
  params: PatientsManagementPrams
): Request {
  return {
    url: `${API_PREFIX}/openUser/page`,
    method: 'GET',
    params,
  }
}

export interface EchartsPrams {
  dataType: any
  patientPhonenumber?: any
  endTime?: any
  ihPatientId?: any
  startTime?: any
}

// 列表
export function echartsList(body: EchartsPrams): Request {
  return {
    url: `${API_PREFIX}/openUser/getHealthChartDataByPatientInfo`,
    method: 'POST',
    body,
  }
}
