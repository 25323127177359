import { Department } from "../models/department";
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant";
import { Request } from "./request";

export interface GetDepartmentListParams {
  current: number;
  name?: string;
  size?: number;
  status?: number;
  suitForClinc?: number;
  tenantId?: string;
}

export function getDepartmentList(params: GetDepartmentListParams): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/list`
      : `${API_PREFIX}/blade-user/department/list`,
    method: "GET",
    params,
  };
}

export function getOutpatientDepartment(): Request {
  return {
    url: `${API_PREFIX}/blade-user/department/list`,
    method: "GET",
    params: {
      size: 1000,
      current: 0,
      status: 1,
      suitForClinc: 1,
    },
  };
}

export interface GetDepartmentDetailParams {
  departmentId: string;
  tenantId?: string;
}

export function getDepartmentDetail(
  params: GetDepartmentDetailParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/detail`
      : `${API_PREFIX}//blade-user/department/view`,
    method: params.tenantId ? "POST" : "GET",
    params: {
      departmentId: params.departmentId,
    },
  };
}
export interface getUserAddFnParams {
  id?: string
  tenantId?: string
}

export function getUserAddFn(body: getUserAddFnParams): Request {
  return {
    url: `${ADMIN_API_PREFIX}/blade-administer/tenant/user/firstGiveCodName`,
    method: 'POST',
    body,
  }
}

export interface CreateDepartmentParams {
  department: Department;
  tenantId?: string;
}

export function createOrUpdateDepartment(
  params: CreateDepartmentParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/save`
      : `${API_PREFIX}/blade-user/department/save`,
    method: 'POST',
    body: {
      tenantId: params.tenantId,
      id: params.department.id,
      name: params.department.name,
      code: params.department.code,
      category: params.department.category,
      subCategory: params.department.subCategory.join(','),
      showFlg: 1,
    },
  }
}

export interface ToggleDepartmentStateParams {
  departmentId: string;
  tenantId?: string;
}

export function toggleDepartmentState(
  params: ToggleDepartmentStateParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/tenant/department/logoutOrEnable`
      : `${API_PREFIX}/blade-user/department/logoutOrEnable`,
    method: "POST",
    params,
  };
}
