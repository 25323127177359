import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { } from 'antd'
import { freePatientQueryParams } from '../../services/freePatients'
import {
  FreePatientStateCategories,
  FreePatientStateName,
} from '../../models/freePatients'

export const Query = (props: {
  addFreePatient?: (v?: any) => void
  onValueChange: (queries: freePatientQueryParams) => void
  export: () => void
  print: () => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='title' noStyle>
              <Input
                autoComplete='off'
                placeholder='标题/医生'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Form.Item noStyle name='status'>
              <Select
                placeholder='义诊状态/全部'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {FreePatientStateCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {FreePatientStateName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                props.export && props.export()
              }}
            >
              导出
            </Button>
            <Button
              type='primary'
              onClick={() => {
                props.print && props.print()
              }}
            >
              打印
            </Button>
            <Button
              type='primary'
              onClick={() => {
                props.addFreePatient && props.addFreePatient()
              }}
            >
              新增活动
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
