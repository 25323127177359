/*
 * @Descripttion: 
 * @version: 
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-11 09:29:10
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-11 14:18:09
 */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/workOrderTemplate'

// 查询工单模板数据

export const getWorkTemplateData = createAsyncThunk<
  void,
  DT.WorkTemplatePrams,
  RootThunkApi<void>
>('clinic/getWorkTemplateData', async (params, api) => {
  return sendAsync(DT.workTemplateData(params), api)
})

//  创建修改工单模板
export const getWorkTemplateSaveorupdate = createAsyncThunk<
  void,
  DT.workTemplateSaveorupdateParams,
  RootThunkApi<void>
>('clinic/getWorkTemplateSaveorupdate', async (params, api) => {
  return sendAsync(DT.workTemplateSaveorupdate(params), api)
})

// 工单流程模板查询
export const getWorkTemplateTimeLineData = createAsyncThunk<
  void,
  any,
  RootThunkApi<void>
>('clinic/getWorkTemplateTimeLineData', async (params, api) => {
  return sendAsync(DT.workTemplateTimeLineData(params), api)
})

// 修改工单流程模板标题
export const getWorkTemplateTimeLineTitleEdit = createAsyncThunk<
  void,
  DT.workTemplateTimeLineTitleEditParams,
  RootThunkApi<void>
>('clinic/getWorkTemplateTimeLineTitleEdit', async (params, api) => {
  return sendAsync(DT.workTemplateTimeLineTitleEdit(params), api)
})


// saveorupdate  
export const getWorkTemplateChooseUserEdit = createAsyncThunk<
  void,
  DT.workTemplateChooseUserEditParams,
  RootThunkApi<void>
>('clinic/getWorkTemplateChooseUserEdit', async (params, api) => {
  return sendAsync(DT.workTemplateChooseUserEdit(params), api)
})





