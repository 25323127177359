/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 09:54:38
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 11:08:15
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement } from 'react'
import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../../compnents/form/DateSelect'
import {} from 'antd'

export const Query = (props: {
  onValueChange: (queries: any) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='type' noStyle>
              <Select
                placeholder='类型'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                <Option value={1} key={1}>
                  收入
                </Option>
                <Option value={2} key={2}>
                  支出
                </Option>
              </Select>
            </Form.Item>
            <DateSelect
              placeholder='创建时间'
              labelPrefix='创建时间-'
              namePrefix='time'
              style={{ width: '8.5rem' }}
              onChange={form.submit}
            />
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
