/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-08-27 11:16:55
 * @LastEditors: linxi
 * @LastEditTime: 2021-09-03 17:49:24
 */
export enum CommonCodeCategory {
  Usage = "YF",
  TcmUsage = "CYYF",
  Frequency = "PC",
  Unit = "DW",
  Durg = "YPFL",
  Dosago = "YWJX",
  KQJC = "KQKQJC",
  JYJC = "KQJYJC",
  ZLJH = "KQZLJH",
  KQCZ = "KQCZ",
  KQZL = "KQZLYJ",
}

export interface CommonCode {
  id: string;
  name: string;
  code: string;
}

export function fromJson(json: any): CommonCode {
  return {
    id: json.id?.toString() || "",
    name: json.codeName,
    code: json.mnemonicCode,
  };
}
