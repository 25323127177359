import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../../../app/store'
import { EditableList } from '../../../../compnents/list/EditableList'
import { ThemeContext } from '../../../../theme/ThemeContext'
import { PrintFrame, PrintFrameRef } from '../../../print/PrintFrame'
import {
  getFreeDetailTable,
  getNum,
  getSenFreeTreatmentSms,
  getRecallByApp
} from '../../freePatientSlice'
import { Columns, PrintColumns } from '../detail/column'
import styles from './detailContent.module.css'

export interface DetailListRef {
  selectTabKey?: any
}

interface DetailListProps {
  treatmentId?: any
  num?: any
  selectTabKey?: any
  paramsTag?: (v: any) => void
  page?: any
  refresh?: () => void
  printDataList?: any
  detailExport?: () => void
}
const RealDetailLists = (
  {
    selectTabKey,
    paramsTag,
    page,
    printDataList,
    treatmentId,
    num,
    detailExport,
    refresh,
  }: DetailListProps,
  ref: React.Ref<DetailListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const { Option } = Select

  const theme = useContext(ThemeContext)

  const printRef = useRef<PrintFrameRef>(null)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [params, setParams] = useState<any>({
    current: 1,
    size: 20,
  })

  useEffect(() => {
    if (params) {
      console.log(params, 'params')
      paramsTag && paramsTag(params)
    }
  }, [params])
  console.log(printDataList, 'printDataList')

  const date: any = new Date()

  const year = date.getFullYear() //获取完整的年份(4位)
  const printData = () => {
    return (
      <div>
        {/* <div
          style={{ fontSize: '30px', margin: '10px 0', textAlign: 'center' }}
        >
          浙江中医药大学名中医馆义诊活动登记表
        </div> */}
        <Row style={{ margin: '10px 0', lineHeight: '32px' }}>
          <Col style={{ marginRight: '20px' }}>
            <Row>
              <Col>{moment(printDataList?.treatmentDate).format('YYYY')}年</Col>
              <Col>{moment(printDataList?.treatmentDate).format('MM')}月</Col>
              <Col>{moment(printDataList?.treatmentDate).format('DD')}日</Col>
            </Row>
          </Col>
          <Col style={{ marginRight: '60px' }}>
            医生：{printDataList?.doctorName}
          </Col>
          <Col style={{ textAlign: 'right',marginRight: '40px' }}>
            {printDataList?.timestr}
          </Col>
          <Col style={{ marginRight: '20px' }}>
            活动名称：{printDataList?.title}
          </Col>
          <Col style={{ marginRight: '20px' }}>
            活动地点：{printDataList?.address}
          </Col>
        </Row>
        <EditableList
          className={styles.tableBorded}
          pagination={false}
          bordered
          rowKey={(_, i) => `${i}}`}
          page={{
            items: printDataList.items,
            current: params.current,
            size: params.size,
            total: printDataList.total,
          }}
          loading={pageLoading}
          columns={PrintColumns(params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </div>
    )
  }

  return (
    <Col
      style={{
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Form
          form={form}
          autoComplete='off'
          onFinish={(values) => {
            setParams({ ...params, ...values, current: 1 })
          }}
        >
          <Row
            align='middle'
            justify='space-between'
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            <Space>
              <Form.Item name='param' noStyle>
                <Input
                  placeholder='就诊人姓名'
                  style={{ width: 200 }}
                  prefix={<SearchOutlined color={theme.tc3} />}
                  allowClear
                  onChange={form.submit}
                />
              </Form.Item>
              <Form.Item name='classes' noStyle>
                <Select
                  placeholder='班次'
                  allowClear
                  style={{ width: 140 }}
                  onChange={() => form.submit()}
                >
                  <Option value={0}>上午</Option>                    
                  <Option value={1}>下午</Option>
                </Select>
              </Form.Item>
              <Button type='primary' onClick={form.submit}>
                查询
              </Button>
            </Space>
            <Space>
              <Col>上午：{num?.forenoon}人</Col>
              <Col style={{ marginLeft: '10px' }}>下午：{num?.afternoon}人</Col>
              <Button
                type='primary'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  printRef.current?.print()
                }}
              >
                打印
              </Button>
              <Button
                type='primary'
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  detailExport && detailExport()
                }}
              >
                导出
              </Button>
            </Space>
          </Row>
        </Form>
        <div className={styles.table}>
          <EditableList
            scroll={{ y: 400 }}
            rowKey={(_, i) => `${i}}`}
            page={{
              items: page.items,
              current: params.current,
              size: params.size,
              total: page.total,
            }}
            loading={pageLoading}
            columns={Columns((action: any, v: any) => {
              switch (action) {
                case 'send':
                  dispatch(
                    getSenFreeTreatmentSms({
                      address: v?.address,
                      appointmentTime: v?.appointmentDate,
                      patientId: v?.patientId,
                      phone: v?.patientPhone,
                      reviceName: v?.name,
                      treatmentAppointmentId: v?.id,
                      treatmentId: treatmentId,
                    })
                  )
                    .then(unwrapResult)
                    .then((v) => {
                      notification.success({
                        message: '操作成功',
                        duration: 3,
                      })
                      refresh && refresh()
                    })
                  break
              }
            }, (id) => {
              Modal.confirm({
                centered: true,
                title: '',
                content: <div>是否确认取消？</div>,
                okText: '确认',
                cancelText: '取消',
                icon: null,
                onOk: () => {
                  dispatch(getRecallByApp(id))
                    .then(unwrapResult)
                    .then(
                      () => {
                        refresh && refresh()
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                      }
                    )
                }
              })

            }, params.size * (params.current - 1))}
            onChangePage={(current, size) => {
              setParams({ ...params, current, size: size || 20 })
            }}
          />
        </div>
      </Col>

      <PrintFrame ref={printRef} title='浙江中医药大学名中医馆活动登记表' direction={'portrait'}>
        {printData()}
      </PrintFrame>
    </Col>
  )
}

export const FreePatientDetailList = forwardRef(RealDetailLists)
