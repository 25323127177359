/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 13:51:36
 */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Upload,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { RcFile } from 'antd/lib/upload'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getTinyUrl } from '../medicalGuide/medicalGuideSlice'
import styles from './divisionManagement.module.css'
import {
  getDivisionManagementDetail,
  getDivisionManagementEditSubmit,
} from './divisionManagementSlice'

interface editDetailModalProps {
  name?: any
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditDetailModal = ({
  name,
  id,
  visible,
  onCancel,
  onOk,
}: editDetailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [title, setTitle] = useState('')

  // useEffect(() => {
  //   if (visible) {
  //     dispatch(getDivisionManagementDetail(id))
  //       .then(unwrapResult)
  //       .then((v) => {
  //         setDetail(v)
  //       })
  //   }
  // }, [visible])

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: name,
      })
    }
  }, [visible, name])

    useEffect(() => {
      if (visible) {
        if (id) {
          setTitle('编辑部门')
        } else {
          setTitle('新增部门')
        }
      }
    }, [visible, id])

  return (
    <Modal
      title={title}
      width={600}
      visible={visible}
      // destroyOnClose
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          dispatch(
            getDivisionManagementEditSubmit({
              ...values,
              id: id ?id :undefined,
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              form.resetFields()
            })
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Form.Item
          label='部门名称'
          name='name'
          rules={[{ required: true, message: '请输入部门名称' }]}
        >
          <Input style={{ width: '300px' }} />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
          <Space>
            <Button onClick={() => onCancel()}>取消</Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
