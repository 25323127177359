/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 11:02:37
 */
export enum ReleaseStateCategory {
  DFB = '0',
  YFB = '1',
  YCH = '2',
}
// 全部
export const ReleaseStateCategories = [
  ReleaseStateCategory.DFB,
  ReleaseStateCategory.YFB,
  ReleaseStateCategory.YCH,
]

export function getReleaseStateName(state: ReleaseStateCategory): string {
  switch (state) {
    case '0':
      return '待发布'
    case '1':
      return '已发布'
    case '2':
      return '已撤回'
  }
  return ''
}
