import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import {
  getDepartmentManagementList,
  getDepartmentManagementUpload,
  getIsSuggestDepartmentManagementUpload,
} from './departmentManagementSlice'
import { EditDetailModal } from './editDetailModal'

export interface DepartmentManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface DepartmentManagementProps {
  tabKey?: any
}
const RealDepartmentManagements = (
  { tabKey }: DepartmentManagementProps,
  ref: React.Ref<DepartmentManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isEditRemarkVisible, setIsEditRemarkVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [name, setName] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getDepartmentManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='科室管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '0px 15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Form
              form={form}
              autoComplete='off'
              onFinish={(values) => {
                setParams({ ...params, ...values, current: 1 })
              }}
            >
              <Row
                align='middle'
                justify='space-between'
                style={{ marginTop: 10, marginBottom: 20 }}
              >
                <Space>
                  <Form.Item noStyle name='departmentName'>
                    <Input style={{ width: '300px' }} placeholder='科室' />
                  </Form.Item>
                  <Button type='primary' onClick={form.submit}>
                    查询
                  </Button>
                </Space>
              </Row>
            </Form>
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any, e: any) => {
                switch (action) {
                  case 'edit':
                    setIsEditRemarkVisible(true)
                    setId(v.id)
                    setName(v?.hisDepartmentName)
                    break
                  case 'stateEdit':
                    dispatch(
                      getDepartmentManagementUpload({
                        id: v.id,
                        flag: e,
                      })
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })

                    break
                  case 'isSuggestStateEdit':
                    dispatch(
                      getIsSuggestDepartmentManagementUpload({
                        id: v.id,
                        isSuggest: e,
                      })
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      })

                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
          <EditDetailModal
            id={id}
            name={name}
            visible={isEditRemarkVisible}
            onCancel={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
            onOk={() => {
              setIsEditRemarkVisible(false)
              setId('')
              getPage()
            }}
          />
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const DepartmentManagementList = forwardRef(RealDepartmentManagements)
