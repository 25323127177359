import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { columns } from './columns'
import {
  getChangeStateSubmit,
  getDeleteSubmit,
  getHealthList,
} from './healthSlice'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { useHistory } from 'react-router-dom'
import { HealthDetailModal } from './healthDetailModal'

export interface HealthRef {
  refresh?: () => void
  tabKey?: any
}

interface HealthProps {
  tabKey?: any
}
const RealHealths = (
  { tabKey }: HealthProps,
  ref: React.Ref<HealthRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const history: any = useHistory()

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [isHealthDetailVisible, setIsHealthDetailVisible] = useState(false)

  const [id, setId] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getHealthList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (params) {
      getPage()
    }
  }, [params])

  return (
    <TabBar style={{ margin: '10px 20px' }}>
      <TabPane key='0' tab='文章管理'>
        <Col
          style={{
            height: 'calc(100% - 30px)',
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            margin: ' 10px 0',
            flexDirection: 'column',
          }}
        >
          <Col
            style={{
              flex: 1,
              backgroundColor: theme.pn,
              borderRadius: 10,
              padding: '15px  15px 0 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Query
              onValueChange={(v: any) => {
                setParams({
                  ...params,
                  current: 1,
                  ...v,
                  pushTimeStart: v?.pushTimeHead,
                  pushTimeEnd: v?.pushTimeTail,
                  pushTimeHead: undefined,
                  pushTimeTail: undefined,
                })
              }}
              addArticle={() => {
                history.push({
                  pathname: '/admin/health/addOrEditHealth',
                })
              }}
            />
            <EditableList
              rowKey={(_, i) => `${i}}`}
              page={{
                items: page.items,
                current: params.current,
                size: params.size,
                total: page.total,
              }}
              loading={pageLoading}
              columns={columns((action: any, v: any) => {
                switch (action) {
                  case 'withdraw':
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认撤回？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(getChangeStateSubmit({ id: v?.id, state: 2 }))
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                            getPage()
                          })
                      },
                    })
                    break
                  case 'publish':
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认发布？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(getChangeStateSubmit({ id: v?.id, state: 1 }))
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                            getPage()
                          })
                      },
                    })
                    break
                  case 'delete':
                    Modal.confirm({
                      centered: true,
                      title: '',
                      content: <div>是否确认删除？</div>,
                      okText: '确认',
                      cancelText: '取消',
                      icon: null,
                      onOk: () => {
                        dispatch(getDeleteSubmit({ id: v?.id }))
                          .then(unwrapResult)
                          .then((v) => {
                            notification.success({
                              message: '操作成功',
                              duration: 3,
                            })
                            getPage()
                          })
                      },
                    })
                    break
                  case 'detail':
                    setIsHealthDetailVisible(true)
                    setId(v?.id)
                    break
                  case 'edit':
                    setId(v.id)
                    history.push({
                      pathname: '/admin/health/addOrEditHealth',
                      state: {
                        id: v?.id,
                      },
                    })
                    break
                }
              }, params.size * (params.current - 1))}
              onChangePage={(current, size) => {
                console.log(params, 'change')
                setParams({ ...params, current, size: size || 10 })
              }}
            />
          </Col>
          <HealthDetailModal
            id={id}
            visible={isHealthDetailVisible}
            onCancel={() => {
              setIsHealthDetailVisible(false)
              setId('')
              getPage()
            }}
            onOk={() => {
              setIsHealthDetailVisible(false)
              setId('')
              getPage()
            }}
          />
        </Col>
      </TabPane>
    </TabBar>
  )
}

export const HealthList = forwardRef(RealHealths)
