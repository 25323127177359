/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-06 15:01:50
 */

import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
} from 'antd'
import _ from 'lodash'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { constructMenuLayers } from '../../models/menu'
import styles from './accountManagement.module.css'
import {
  getAccountManagementDetail,
  getAccountManagementEditSubmit,
  getMenuList,
} from './accountManagementSlice'
import { type } from 'os'

interface addOrEditDetailModalProps {
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const AddOrEditDetailModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: addOrEditDetailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [granted, setGranted] = useState<any>()

  const [menuList, setMenuList] = useState<any>()

  const [menuIds, setMenuIds] = useState<any>()

  useEffect(() => {
    if (visible) {
      setGranted([])
      setMenuIds([])
      dispatch(getMenuList())
        .then(unwrapResult)
        .then((v) => {
          const menus = constructMenuLayers(v.records)
          setMenuList(menus)
        })
    }
  }, [visible])

  useEffect(() => {
    console.log(granted, 'granted')
  }, [granted])

  const getDetail = () => {
    dispatch(getAccountManagementDetail(id))
      .then(unwrapResult)
      .then((v: any) => {
        setDetail(v)
        setGranted(v?.menuList?.split(','))
      })
  }

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        menuList: granted,
      })
    }
  }, [visible, granted])

  useEffect(() => {
    if (visible)
      if (id) {
        getDetail()
      }else{
        setDetail({})
      }
  }, [visible, id])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      })
    }
  }, [detail])

  const grant = (ids: string[], parentId: string, selected: boolean) => {
    if (selected) {
      const menuIds = _.chain(granted)
        .concat(ids)
        .concat(parentId)
        .filter((id) => !!id && id !== '-1')
        .uniq()
        .value()
      setMenuIds(menuIds)
      setGranted(menuIds)
    } else {
      let menuIds = _.chain(granted).difference(ids).value()
      setMenuIds(menuIds)
      const parent = menuList?.find((m: any) => m?.id === parentId)
      if (parent) {
        const noChild = parent.subMenus.reduce(
          (b: any, m: any) => b && !menuIds?.includes(m.id),
          true
        )
        if (noChild) {
          menuIds = menuIds.filter((id) => id !== parentId)
        }
      }
      setGranted(menuIds)
      console.log(menuIds, 'menuIds')
    }
  }

  return (
    <Modal
      title={id ? '账号编辑' : '账号新增'}
      width={700}
      visible={visible}
      onCancel={() => {
        onCancel()
        form.resetFields()
        setDetail({})
      }}
      centered
      footer={null}
      className={styles.editRemark}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        className={styles.form}
        form={form}
        onFinish={(values) => {
          console.log(values, 'values')
          dispatch(
            getAccountManagementEditSubmit({
              name: values?.name ? values?.name : detail?.name,
              id: detail?.id ? detail?.id : undefined,
              phone: values?.phone ? values?.phone : detail?.phone,
              menuList: granted?.join(','),
            })
          )
            .then(unwrapResult)
            .then((v) => {
              notification.success({
                message: '操作成功',
                duration: 3,
              })
              onOk()
              form.resetFields()
              setDetail({})
            })
        }}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 20,
        }}
      >
        <Row>
          <Col span={19}>
            <Form.Item
              label='姓名'
              name='name'
              rules={[
                {
                  required: true,
                  message: '请输入姓名',
                },
              ]}
            >
              <Input autoComplete='off' maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={19}>
            <Form.Item
              label='手机号'
              name='phone'
              rules={[
                {
                  required: true,
                  message: '请输入手机号',
                },
                {
                  pattern: /^1\d{10}$/,
                  message: '请输入正确手机号',
                },
              ]}
            >
              <Input autoComplete='off' disabled={id ? true : false} />
            </Form.Item>
          </Col>
          <Col span={5} style={{ padding: '5px 0 0 10px' }}>
            作为登陆的账号
          </Col>
        </Row>
        <Row>
          <Col span={19}>
            <Form.Item
              label='权限设置'
              name='menuList'
              style={{ marginRight: '10px' }}
              rules={[
                {
                  required: true,
                  message: '请勾选权限设置',
                },
              ]}
            >
              {menuList?.map((p: any) => {
                const allSubmenuSelected =
                  p?.subMenus.reduce(
                    (b: any, m: any) => b && granted?.includes(m.id),
                    true
                  ) && granted?.includes(p.id)

                const anySubmenuSelected = p?.subMenus.reduce(
                  (b: any, m: any) => b || granted?.includes(m.id),
                  false
                )
                console.log(
                  !allSubmenuSelected && anySubmenuSelected,
                  'allSubmenuSelected'
                )
                return (
                  <div key={p.id}>
                    <Row
                      align='middle'
                      style={{
                        marginTop: 12,
                        padding: '0 20px',
                      }}
                    >
                      <Checkbox
                        disabled={detail?.role == 1 ? true : false}
                        indeterminate={
                          !allSubmenuSelected && anySubmenuSelected
                        }
                        checked={allSubmenuSelected}
                        onChange={(e) => {
                          grant(
                            p?.subMenus?.map((m: any) => m.id),
                            p.id,
                            e.target.checked
                          )
                        }}
                      >
                        {p.name}
                      </Checkbox>
                    </Row>
                    <Row
                      style={{
                        borderTop: 'none',
                        padding: '0 48px 0px 48px',
                      }}
                    >
                      {p?.subMenus?.map((m: any) => (
                        <Col key={m.id}>
                          <Checkbox
                            disabled={detail?.role == 1 ? true : false}
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              marginTop: 15,
                              whiteSpace: 'nowrap',
                            }}
                            checked={granted?.includes(m.id)}
                            onChange={(e) =>
                              grant([m.id], m.parentId, e.target.checked)
                            }
                          >
                            {m.name}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )
              })}
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '30px',
          }}
        >
          <Space>
            <Button
              onClick={() => {
                onCancel()
                form.resetFields()
              }}
            >
              取消
            </Button>
            <Button type='primary' htmlType='submit'>
              确定
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  )
}
