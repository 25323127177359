export enum TenantCategory {
  All = 0,
  Pharmacy = 1,
  Clinic = 2,
}

export const TenantCategories = [
  TenantCategory.All,
  TenantCategory.Pharmacy,
  TenantCategory.Clinic,
];

export function getTenantCategoryName(category: TenantCategory): string {
  if (category === TenantCategory.Pharmacy) {
    return "药店";
  } else if (category === TenantCategory.Clinic) {
    return "卫生院";
  }
  return "全部";
}
export enum platformType {
  HYD = 0,
  JW = 1,
}

export const platformTypes = [platformType.HYD, platformType.JW]

export function getPlatformTypeName(num: platformType): string {
  switch (num) {
    case platformType.HYD:
      return '好医点'
    case platformType.JW:
      return '聚网'
    default:
      return '-'
  }
}
export enum teantType {
  XY = 0,
  ZY = 1,
  KQ = 2,
  YM = 3,
  YK = 4,
  TY = 5,
  QT = 6,
  ZK = 7
  // ZW = -1,
}

export const teantTypes = [
  teantType.XY,
  teantType.ZY,
  teantType.KQ,
  teantType.YM,
  teantType.YK,
  teantType.TY,
  teantType.QT,
  teantType.ZK,
  // teantType.ZW,
]

export function getTeantTypeName(teant: teantType): string {
  switch (teant) {
    case teantType.XY:
      return '西医'
    case teantType.ZY:
      return '中医'
    case teantType.KQ:
      return '口腔'
    case teantType.YM:
      return '医美'
    case teantType.YK:
      return '眼科'
    case teantType.TY:
      return '通用'
    case teantType.ZK:
      return '专科'
    case teantType.QT:
      return '其他'
    default:
      return '-'
  }
}


export enum TenantLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
  Level4 = 4,
  Level5 = 5,
  Level6 = 6,
}

export const TenantLevels = [
  TenantLevel.Level1,
  TenantLevel.Level2,
  TenantLevel.Level3,
  TenantLevel.Level4,
  TenantLevel.Level5,
  TenantLevel.Level6,
]

export const TenantLevelNames = [
  '一级',
  '二级',
  '三级',
  '四级',
  '五级',
  '未定级',
]

export function getTenantLevelName(level: TenantLevel): string {
  if (level <= 0 || level > TenantLevels.length) {
    return ''
  }
  return TenantLevelNames[level - 1]
}

export const HospitalLevels = [
  TenantLevel.Level1,
  TenantLevel.Level2,
  TenantLevel.Level3,
  TenantLevel.Level6,
]

export interface Tenant {
  id: string
  name: string
  shortName: string
  status: number
  tenantCategory: TenantCategory
  tenantPhone: string
  hospitalCode: string
  legalPerson: string
  licenceCategory: string
  licenceDeadline: string
  licenceNumber: string
  managerCompany: string
  medicalOrganCode: string
  medicalOrganLevel: number
  organCode: number
  organLevel: TenantLevel
  contactName: string
  phone: string
  postcode: string
  address: string
  labelIds: string
  labelList: any[]
  createTime: string
  startTime: string
  endTime: string
  platformId?: string | number
  platformName: string
  registeredFund: string
  nationUniqueCode?: string
  lastLoginTime?: string
  provinceName?: string
  cityName?: string
  countyName?: string
  openType?: number
  provinceCode: string
  cityCode: string
  countyCode: string
  tenantType: number
  signedType: number
  edition?: number
  hosType?: any
  hosDistinctCode?: any
}

export function fromJson(json: any): Tenant {
  return {
    id: json.id?.toString() || '',
    name: json.name,
    shortName: json.shortName,
    status: json.status,
    tenantCategory: json.tenantCategory,
    tenantPhone: json.tenantPhone,
    hospitalCode: json.hospitalCode,
    legalPerson: json.legalPerson,
    licenceCategory: json.licenceCategory,
    licenceDeadline: json.licenceDeadline,
    licenceNumber: json.licenceNumber,
    managerCompany: json.managerCompany,
    medicalOrganCode: json.medicalOrganCode,
    medicalOrganLevel: json.medicalOrganLevel,
    organCode: json.organCode,
    organLevel: json.organLevel,
    contactName: json.contactName,
    phone: json.phone,
    postcode: json.postCode,
    address: json.address,
    labelIds: json.labelIds,
    labelList: json.labelList,
    createTime: json.createTime,
    startTime: json.startTime,
    endTime: json.endTime,
    registeredFund: json.registeredFund == -1 ? '' : json.registeredFund,
    nationUniqueCode: json.nationUniqueCode,
    provinceName: json.provinceName,
    cityName: json.cityName,
    countyName: json.countyName,
    openType: json.openType,
    platformId: json.platformId,
    platformName: json.platformName,
    provinceCode: json.provinceCode,
    cityCode: json.cityCode,
    countyCode: json.countyCode,
    tenantType: json.tenantType,
    signedType: json.signedType,
    lastLoginTime: json.lastLoginTime,
    edition: json.edition,
    hosType: json.hosType,
    hosDistinctCode: json.hosDistinctCode,
  }
}

export function toJson(tenant: Tenant): any {
  return {
    id: tenant.id,
    name: tenant.name,
    shortName: tenant.shortName,
    status: tenant.status,
    tenantCategory: tenant.tenantCategory,
    hospitalCode: tenant.hospitalCode,
    legalPerson: tenant.legalPerson,
    tenantPhone: tenant.tenantPhone,
    licenceCategory: tenant.licenceCategory,
    licenceDeadline: tenant.licenceDeadline,
    licenceNumber: tenant.licenceNumber,
    managerCompany: tenant.managerCompany,
    medicalOrganCode: tenant.medicalOrganCode,
    medicalOrganLevel: tenant.medicalOrganLevel,
    organCode: tenant.organCode,
    organLevel: tenant.organLevel,
    contactName: tenant.contactName,
    phone: tenant.phone,
    postCode: tenant.postcode,
    address: tenant.address,
    labelIds: tenant.labelIds,
    labelList: tenant.labelList,
    createTime: tenant.createTime,
    startTime: tenant.startTime,
    endTime: tenant.endTime,
    registeredFund: tenant.registeredFund == '-1' ? '' : tenant.registeredFund,
    nationUniqueCode: tenant.nationUniqueCode,
    provinceName: tenant.provinceName,
    cityName: tenant.cityName,
    countyName: tenant.countyName,
    platformId: tenant.platformId,
    openType: tenant.openType,
    provinceCode: tenant.provinceCode,
    cityCode: tenant.cityCode,
    countyCode: tenant.countyCode,
    tenantType: tenant.tenantType,
    signedType: tenant.signedType,
    lastLoginTime: tenant.lastLoginTime,
    edition: tenant.edition,
    hosType: tenant.hosType,
    hosDistinctCode: tenant.hosDistinctCode,
  }
}