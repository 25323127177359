/* * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 16:20:35
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:00:20
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { Dimen } from '../../models/dimen'
import femaleHead from '../images/female.png'
import maleHead from '../images/male.png'

type ActionType = 'edit' | 'stateEdit' | 'resetSecret' | 'delete'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  const [checked, setChecked] = useState<any>()
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: Dimen.Num,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },

    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      align: 'center',
      ellipsis: true,
      render: (_, r) => {
        return r.createTime ? r.createTime : '-'
      },
    },
    {
      title: '状态',
      dataIndex: '',
      align: 'center',
      width: '10rem',
      ellipsis: true,
      render: function UserSettings(_, d): ReactElement {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {d.state == 0 ? '已注销' : d.state == 1 ? '正常' : ''}

            <Switch
              checked={Boolean(d.state)}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                setChecked(e)
                onClick('stateEdit', d, e)
              }}
            />
          </div>
        )
      },
    },
    {
      title: '操作',
      key: 'actions',
      width: '12rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('resetSecret', u)}
              style={{ marginRight: '10px' }}
            >
              重置密码
            </a>
            <a style={{ marginRight: '10px' }} onClick={() => onClick('edit', u)}>编辑</a>
            <a onClick={() => onClick('delete', u)}>删除</a>
          </>
        )
      },
    },
  ]
}
