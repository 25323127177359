/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 17:16:18
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 17:20:42
 */
import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { ThemeContext } from '../../theme/ThemeContext'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { Query } from './Query'
import { columns } from './column'
import { getChecklistManagementList } from './checklistManagementSlice'
import { FilenameListModal } from './lookMorePicModal'

export interface ChecklistManagementRef {
  refresh?: () => void
  tabKey?: any
}

interface ChecklistManagementProps {
  tabKey?: any
}
const RealChecklistManagement = (
  { tabKey }: ChecklistManagementProps,
  ref: React.Ref<ChecklistManagementRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [filenameList, setFilenameList] = useState<string>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const [isFilenameListVisible, setIsFilenameListVisible] = useState(false)

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getChecklistManagementList({
        ...params,
        current: params.current,
        size: params.size,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [params, tabKey])

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        margin: ' 10px',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '15px  15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          onValueChange={(v: any) => {
            setParams({
              ...params,
              ...v,
              current: 1,
              startTime: v?.timeHead,
              endTime: v?.timeTail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        />
        <EditableList
          rowKey={(_, i) => `${i}}`}
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns((action: any, v: any) => {
            switch (action) {
              case 'lookMore':
                setFilenameList(v?.filename)
                setIsFilenameListVisible(true)
                break
            }
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            console.log(params, 'change')
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>
      <FilenameListModal
        filename={filenameList}
        visible={isFilenameListVisible}
        onCancel={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
          getPage()
        }}
        onOk={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
          getPage()
        }}
      />
    </Col>
  )
}

export const ChecklistManagementList = forwardRef(RealChecklistManagement)
