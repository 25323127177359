/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-04 14:34:15
 */
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { getAge } from '../../utils/StringUtils'
import styles from './health.module.css'
import maleHead from '../images/male.png'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { getMedicalGuideDetail } from '../medicalGuide/medicalGuideSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getHealthDetail } from './healthSlice'

export const HealthDetailModal = (props: {
  id?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  useEffect(() => {
    if (props?.visible) {
      dispatch(getHealthDetail(props?.id))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
        })
    }
  }, [props?.visible])

  const labelStyle = {
    style: {
      fontSize: '1rem',
      textAlign: 'left',
      marginBottom: 6,
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      fontSize: '1rem',
      marginBottom: 4,
    } as React.CSSProperties,
  }
  return (
    <div>
      <Modal
        title='详情'
        visible={props.visible}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
        width={1000}
      >
        <Row gutter={20} style={{ marginLeft: '10px' }}>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>标题：</Col>
              <Col {...valueStyle} span={22}>
                {detail?.title}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>图片：</Col>
              <Col {...valueStyle} span={22}>
                <img
                  src={detail?.knowledgeImg}
                  alt=''
                  style={{ width: '200px' }}
                ></img>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>导语：</Col>
              <Col {...valueStyle} span={22}>
                {detail?.preface}
              </Col>
            </Row>
          </Col>
          {detail?.knowledgeVideo ? (
            <Col span={24}>
              <Row>
                <Col {...labelStyle}>视频：</Col>
                <Col {...valueStyle} span={22}>
                  <video
                    style={{ width: '300px' }}
                    controls
                    autoPlay
                    loop
                    muted
                    src={detail?.knowledgeVideo}
                    poster={detail?.knowledgeVideo}
                  ></video>
                </Col>
              </Row>
            </Col>
          ) : (
            ''
          )}

          <Col span={24}>
            <Row>
              <Col {...labelStyle}>内容：</Col>
              <Col {...valueStyle} span={22}>
                <div
                  className={styles.contentMain}
                  dangerouslySetInnerHTML={{ __html: detail?.content }}
                ></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
