/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-18 11:34:25
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Form, Modal, Row, Timeline } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getWorkOrderStatus } from '../../../models/workOrder'
import { FilenameListModal } from '../maintenanceWorkOrder/lookPhotoModal'

import styles from './demandWorkOrder.module.css'
import { getDemandWorkOrderDetail } from './demandWorkOrderSlice'

interface detailModalProps {
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const DetailModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: detailModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [filenameList, setFilenameList] = useState<string>()

  const [isFilenameListVisible, setIsFilenameListVisible] = useState(false)

  const firstColumnLayout = {
    style: {
      marginBottom: 6,
      fontSize: '1rem',
    } as React.CSSProperties,
  }
  useEffect(() => {
    if (visible) {
      if (id) {
        dispatch(getDemandWorkOrderDetail(id))
          .then(unwrapResult)
          .then((v) => {
            setDetail(v)
          })
      }
    }
  }, [visible, id])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
      })
    }
  }, [detail])

  return (
    <>
      <Modal
        title='工单详情'
        width={700}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
          setDetail({})
        }}
        centered
        footer={null}
        className={styles.editRemark}
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <div>
          <Row {...firstColumnLayout}>
            <Col style={{ fontWeight: 'bold' }}>
              {detail?.submiter}发起的{detail?.name}
            </Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col span={12}>紧急程度：{detail?.ermerge || '-'}</Col>
            <Col span={12}>需求主题：{detail?.topics || '-'}</Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col span={24}>需求日期：{detail?.sheetTime || '-'}</Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col span={24}>需求位置：{detail?.location || '-'}</Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col span={24}>需求描述：{detail?.describes || '-'}</Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col
              span={24}
              onClick={() => {
                setFilenameList(detail?.urls)
                setIsFilenameListVisible(true)
              }}
            >
              图片/视频：<a>查看</a>
            </Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col style={{ fontWeight: 'bold', margin: '20px 0 10px 0' }}>
              流程
            </Col>
          </Row>
          <Row {...firstColumnLayout}>
            <Col span={24}>
              <Timeline mode='left'>
                {detail?.proclist?.map((v: any) => {
                  return (
                    <>
                      <Timeline.Item
                        color={v?.status == 0 ? '#fde6da' : '#FF8345'}
                      >
                        <div className={styles.titleMain}>
                          <div className={styles.title}>{v?.name}</div>
                          <div className={styles.title}>{v?.updateTime}</div>
                        </div>
                        <div className={styles.titleName}>
                          {v?.grantername || '-'}
                          {v?.status?.toString() != '0' && v?.seq != 1 ? (
                            <span
                              style={{
                                color: v?.status == 7 ? 'red' : '#333333',
                                marginLeft: '6px',
                              }}
                            >
                              ({getWorkOrderStatus(v?.status?.toString())})
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        {/* 申请部门负责人2 ,信息部门反馈 5 的已拒绝 */}
                        {v.describes && v?.status == 7 ? (
                          <>
                            <div className={styles.reasonContent}>
                              拒绝原因：{v?.describes || '-'}
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        {/* 信息部门反馈 5 的已同意 */}
                        {v?.seq == 5 && v?.status == 5 ? (
                          <>
                            <div className={styles.reasonContent}>
                              <div>处理结果反馈：{v?.handle || '-'}</div>
                              <div>处理结果说明：{v?.describes || '-'}</div>
                              <div>处理时间：{v?.updateTime || '-'}</div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                        {/* 信息部门反馈 5 的已同意 */}
                        {v.seq == '6' && v.status == 5 ? (
                          <>
                            <div className={styles.reasonContent}>
                              <div>改造费用：{v?.describes || '-'}</div>
                              <div
                                onClick={() => {
                                  setFilenameList(v?.urls)
                                  setIsFilenameListVisible(true)
                                }}
                              >
                                处改造方案（附件）：<a>查看</a>
                              </div>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </Timeline.Item>
                    </>
                  )
                })}
              </Timeline>
            </Col>
          </Row>
        </div>
      </Modal>
      <FilenameListModal
        filename={filenameList}
        visible={isFilenameListVisible}
        onCancel={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
        }}
        onOk={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
        }}
      />
    </>
  )
}
