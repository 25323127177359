/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:04:21
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-27 19:36:13
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface ChecklistManagementPrams {
  current: number
  size: number
  startTime: string
  endTime: string
}

// 列表
export function ChecklistManagementList(
  params: ChecklistManagementPrams
): Request {
  return {
    url: `${API_PREFIX}/openUser/checkPage`,
    method: 'GET',
    params,
  }
}
