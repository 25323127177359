/* eslint-disable no-dupe-else-if */
import React, { useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Row,
  Select,
  Table,
  TimePicker,
} from 'antd'
import styles from './freePatients.module.css'

import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { DateTimeFormat, DateTimeFormatSimple } from '../../models/datetime'
import moment from 'moment'
import { EditableList } from '../../compnents/list/EditableList'
import {
  getDoctorList,
  getDoctorManagementList,
} from '../DoctorManagement/doctorManagementSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  getFreePatientDetailModal,
  getFreePatientsSubmit,
} from './freePatientSlice'
import { sum } from 'lodash'
import { formatTimeStr } from 'antd/lib/statistic/utils'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const firstColumnLayout = {
  labelCol: { flex: '8.5rem' },
  wrapperCol: { span: 19 },
}

const firstColumnLayoutTwo = {
  labelCol: { flex: '10rem' },
  wrapperCol: { span: 19 },
}

const { TextArea } = Input

export const AddOrEditFreePatientModal = (props: {
  id: any
  status: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const [title, setTitle] = useState('新增')

  const [time, setTime] = useState<any>()

  const [selectDoctorList, setSelectDoctorList] = useState<any>([])

  const [newMorningData, setNewMorningData] = useState<any>()

  const [newAfternoonData, setNewAfternoonData] = useState<any>()

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [names, setNames] = useState<any>('')

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [tableData, setTableData] = useState<any>([]) //表格数据

  const [loading, setLoading] = useState(false)

  const [columns, setColumns] = useState<any>()

  const [showFlag, setShowFlag] = useState(true)

  const [deleteDateTableFlag, setDeleteDateTableFlag] = useState(false)

  const [tableSubmitParams, setTableSubmitParams] = useState<any>()

  const [deleteId, setDeleteId] = useState<any>()

  const [detailId, setDetailId] = useState<any>()

  const [treatmentCount, setTreatmentCount] = useState<any>()

  const [morningTimes, setMorningTimes] = useState<any>()

  const [classesOne, setClassesOne] = useState<any>()

  const [classesTwo, setClassesTwo] = useState<any>()

  const [afternoonTimes, setAfternoonTimes] = useState<any>()

  const [status, setStatus] = useState<any>()

  useEffect(() => {
    const a = tableData?.map((v: any) => {
      if (classesOne && !classesTwo) {
        return [
          {
            id: v?.id,
            classes: 0,
            appointmentCount: v?.appointmentCount > 0 ? v?.appointmentCount : 0,
            appointmentCounts:
              v?.appointmentCounts > 0 ? v?.appointmentCounts : 0,
            count: v?.morning ? v?.morning : 0,
            docterId: v?.docterId,
            docterName: v?.name,
            treatmentDate: time,
            departmentId: v?.departmentId,
            departmentName: v?.departmentName,
            ...morningTimes,
          },
        ]
      } else if (classesTwo && !classesOne) {
        return [
          {
            id: v?.ids,
            classes: 1,
            appointmentCount: v?.appointmentCount > 0 ? v?.appointmentCount : 0,
            appointmentCounts:
              v?.appointmentCounts > 0 ? v?.appointmentCounts : 0,
            count: v?.afternoon ? v?.afternoon : 0,
            docterId: v?.docterId,
            docterName: v?.name,
            treatmentDate: time,
            departmentId: v?.departmentId,
            departmentName: v?.departmentName,
            ...afternoonTimes,
          },
        ]
      } else if (classesOne && classesTwo) {
        return [
          {
            id: v?.id,
            classes: 0,
            appointmentCount: v?.appointmentCount > 0 ? v?.appointmentCount : 0,
            appointmentCounts:
              v?.appointmentCounts > 0 ? v?.appointmentCounts : 0,
            count: v?.morning ? v?.morning : 0,
            docterId: v?.docterId,
            docterName: v?.name,
            treatmentDate: time,
            departmentId: v?.departmentId,
            departmentName: v?.departmentName,
            ...morningTimes,
          },
          {
            id: v?.ids,
            classes: 1,
            appointmentCount: v?.appointmentCount > 0 ? v?.appointmentCount : 0,
            appointmentCounts:
              v?.appointmentCounts > 0 ? v?.appointmentCounts : 0,
            count: v?.afternoon ? v?.afternoon : 0,
            docterId: v?.docterId,
            docterName: v?.name,
            treatmentDate: time,
            departmentId: v?.departmentId,
            departmentName: v?.departmentName,
            ...afternoonTimes,
          },
        ]
      } else if (!classesOne && !classesTwo) {
        return []
      }
    })
    setTableSubmitParams(a.flat())

    const allDataNum = a?.flat()?.map((v: any) => {
      return Number(v?.count)
    })
    console.log(allDataNum, 'allDataNum')

    setTreatmentCount(sum(allDataNum) ? sum(allDataNum) : 0)
    form.setFieldsValue({
      treatmentCount: sum(allDataNum) ? sum(allDataNum) : 0,
    })
  }, [tableData, morningTimes, afternoonTimes, time])

  useEffect(() => {
    if (classesOne == false) {
      setTableData(
        tableData?.map((v: any) => {
          return {
            ...v,
            morning: '',
          }
        })
      )
      setNewMorningData({ ...newMorningData, morning: '' })
    }

    if (classesTwo == false) {
      setTableData(
        tableData?.map((v: any) => {
          return {
            ...v,
            afternoon: '',
          }
        })
      )
      setNewAfternoonData({ ...newAfternoonData, afternoon: '' })
    }
  }, [classesTwo, classesOne])

  useEffect(() => {
    console.log(classesOne, classesTwo, tableData, 'classesTwo')
  }, [classesOne, classesTwo, tableData])

  useEffect(() => {
    setStatus(0)
    console.log(status, 'qqqqqqq')
  }, [props?.status])

  useEffect(() => {
    if (tableData?.length)
      setTableData(
        tableData?.map((v: any, index: any) => {
          return {
            ...v,
            morning:
              v?.docterId == newMorningData?.docterId
                ? newMorningData?.morning
                : v?.morning || '',
            afternoon:
              newAfternoonData?.docterId == v?.docterId
                ? newAfternoonData?.afternoon
                : v?.afternoon || '',
          }
        })
      )
  }, [newMorningData, newAfternoonData])

  console.log(tableData, 'tableDatatableData')

  useEffect(() => {
    if (classesOne && classesTwo && (time || selectDoctorList?.length)) {
      setShowFlag(true)
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          //   // {
          //   //   pattern: new RegExp(/^(([1-9]\d*)|(0{1}))(\.\d{0,5})?$/),
          //   //   message: '请输入正确的格式，最多保留小数点后5位',
          //   // },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, morning: next.morning }
            const data = { ...prev, morning: prev.morning }
            console.log(prev, 'prevprev')

            // if (props?.id && prev.tag) {
            //   if (Number(next?.morning) >= Number(prev?.appointmentCount)) {
            //     setNewMorningData(old)
            //   } else {
            //     setNewMorningData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewMorningData(old)
            // }
          },
        },
        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, afternoon: next.afternoon }
            const data = { ...prev, afternoon: prev.afternoon }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.afternoon) >= Number(prev?.appointmentCounts)) {
            //     setNewAfternoonData(old)
            //   } else {
            //     setNewAfternoonData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewAfternoonData(old)
            // }
          },
        },
      ])
    } else if (
      !classesOne &&
      !classesTwo &&
      !time &&
      !selectDoctorList?.length
    ) {
      setShowFlag(false)
      setColumns([])
    } else if (classesOne && classesTwo && !time && !selectDoctorList?.length) {
      setShowFlag(true)
      setColumns([
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, morning: next.morning }
            const data = { ...prev, morning: prev.morning }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.morning) >= Number(prev?.appointmentCount)) {
            //     setNewMorningData(old)
            //   } else {
            //     setNewMorningData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewMorningData(old)
            // }
          },
        },
        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, afternoon: next.afternoon }
            const data = { ...prev, afternoon: prev.afternoon }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.afternoon) >= Number(prev?.appointmentCounts)) {
            //     setNewAfternoonData(old)
            //   } else {
            //     setNewAfternoonData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewAfternoonData(old)
            // }
          },
        },
      ])
    } else if (
      !classesOne &&
      !classesTwo &&
      (time || selectDoctorList?.length)
    ) {
      setShowFlag(true)
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
      ])
    } else if (
      classesOne &&
      (time || selectDoctorList?.length) &&
      !classesTwo
    ) {
      setShowFlag(true)
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, morning: next.morning }
            const data = { ...prev, morning: prev.morning }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.morning) >= Number(prev?.appointmentCount)) {
            //     setNewMorningData(old)
            //   } else {
            //     setNewMorningData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewMorningData(old)
            // }
          },
        },
      ])
    } else if (
      classesTwo &&
      (time || selectDoctorList?.length) &&
      !classesOne
    ) {
      setShowFlag(true)

      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, afternoon: next.afternoon }
            const data = { ...prev, afternoon: prev.afternoon }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.afternoon) >= Number(prev?.appointmentCounts)) {
            //     setNewAfternoonData(old)
            //   } else {
            //     setNewAfternoonData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewAfternoonData(old)
            // }
          },
        },
      ])
    } else if (
      classesOne &&
      !time &&
      !selectDoctorList?.length &&
      !classesTwo
    ) {
      setShowFlag(true)
      setColumns([
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, morning: next.morning }
            const data = { ...prev, morning: prev.morning }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.morning) >= Number(prev?.appointmentCount)) {
            //     setNewMorningData(old)
            //   } else {
            //     setNewMorningData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewMorningData(old)
            // }
          },
          // onSave: (prev: any, next: any) => {
          //   const old = { ...prev, morning: next.morning }
          //   setNewMorningData(old)
          // },
        },
      ])
    } else if (
      classesTwo &&
      !time &&
      !selectDoctorList?.length &&
      !classesOne
    ) {
      setShowFlag(true)
      setColumns([
        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
          editable: true,
          // rules: [
          //   {
          //     required: true,
          //     message: '请输入',
          //   },
          // ],
          onSave: (prev: any, next: any) => {
            const old = { ...prev, afternoon: next.afternoon }
            const data = { ...prev, afternoon: prev.afternoon }
            // if (props?.id && prev.tag) {
            //   if (Number(next?.afternoon) >= Number(prev?.appointmentCounts)) {
            //     setNewAfternoonData(old)
            //   } else {
            //     setNewAfternoonData(data)
            //     notification.info({
            //       message: '义诊名额不能小于已预约数',
            //       duration: 3,
            //     })
            //   }
            // } else {
            setNewAfternoonData(old)
            // }
          },
        },
      ])
    }
  }, [classesOne, classesTwo, time, selectDoctorList])

  useEffect(() => {
    //获取详情数据
    if (props?.visible)
      if (props?.id)
        dispatch(getFreePatientDetailModal(props?.id))
          .then(unwrapResult)
          .then((res: any) => {
            console.log(res, 'resd')
            setStatus(res?.status)
            setDetailId(res?.id)
            const m = res?.schedules?.filter((v: any) => {
              return v?.classes == 0
            })
            const a = res?.schedules?.filter((v: any) => {
              return v?.classes == 1
            })
            console.log(m, a, 'ddddddddddd')

            setClassesTwo(a?.length ? true : false)
            setClassesOne(m?.length ? true : false)

            form.setFieldsValue({
              title: res?.title,
              content: res?.content,
              treatmentCount: res?.treatmentCount,
              address: res?.address,
              phone: res?.phone,
              remark: res?.remark,
              treatmentDate: res?.treatmentDate
                ? moment(res?.treatmentDate)
                : '',
              deadline: res?.deadline ? moment(res?.deadline) : '',
              treatmentDocter: res?.treatmentDocter
                ?.split(',')
                ?.map((v: any) => v)?.[0],
              classesOneTimes: m?.length
                ? [
                  moment(m?.[0]?.classesStartTime, 'HH:mm'),
                  moment(m?.[0]?.classesEndTime, 'HH:mm'),
                ]
                : [],
              classesTwoTimes: a?.length
                ? [
                  moment(a?.[0]?.classesStartTime, 'HH:mm'),
                  moment(a?.[0]?.classesEndTime, 'HH:mm'),
                ]
                : [],
            })
            const mStartTime = m?.[0]?.classesStartTime
            const mEndTime = m?.[0]?.classesEndTime
            const aStartTime = a?.[0]?.classesStartTime
            const aEndTime = a?.[0]?.classesEndTime
            console.log(m?.[0], mStartTime, mEndTime, 'qqqqqqqq')
            setMorningTimes({
              classesStartTime: mStartTime || '8:30',
              classesEndTime: mEndTime || '12:00',
            })
            setAfternoonTimes({
              classesStartTime: aStartTime || '13:30',
              classesEndTime: aEndTime || '16:30',
            })

            setTime(res?.treatmentDate)
            const tableDataM = m?.map((v: any) => {
              return {
                ...v,
                id: v?.id, //上午id
                docterId: v?.docterId,
                name: v?.docterName,
                departmentId: v?.departmentId,
                departmentName: v?.departmentName,
                morning: v?.count > 0 ? v?.count : 0,
                afternoon:
                  a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                    ?.count > 0
                    ? a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                      ?.count
                    : 0,
                ids: a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]?.id, //下午id
                appointmentCount: v?.appointmentCount, //上午
                appointmentCounts:
                  a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                    ?.appointmentCount || 0, //下午
                tag: true,
              }
            })
            const tableDataA = a?.map((v: any) => {
              return {
                ...v,
                ids: v?.id, //下午id
                docterId: v?.docterId,
                name: v?.docterName,
                departmentId: v?.departmentId,
                departmentName: v?.departmentName,
                afternoon: v?.count > 0 ? v?.count : 0,
                morning:
                  m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                    ?.count > 0
                    ? m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                      ?.count
                    : 0,
                id: m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]?.id, //上午id
                appointmentCounts: v?.appointmentCount, //上午
                appointmentCount: m?.filter(
                  (i: any) => i?.docterId == v?.docterId
                )?.[0]?.appointmentCount, //下午
                tag: true,
              }
            })

            console.log(m, a, 'tableDataM')
            const tableData = m?.length ? tableDataM : tableDataA
            setTableData(tableData)
            setSelectDoctorList(
              res?.treatmentDocter ? res?.treatmentDocter?.split(',') : []
            )
            console.log(tableData, 'tableData')
          })
  }, [props?.visible])

  useEffect(() => {
    if (selectDoctorList?.length) {
      console.log(1111111111)
    } else {
      setTableData([])
    }
  }, [selectDoctorList])

  useEffect(() => {
    //获取医生数据
    if (props?.visible)
      dispatch(
        getDoctorList({
          size: 1000,
          current: 1,
          param: names,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setDoctorList(
            res?.records?.map((v: any) => {
              return { ...v, name: v?.name ? v?.name : '-' }
            })
          ) // 筛选已注销科室的医生
        })
  }, [props?.visible, names])

  useEffect(() => {
    if (props?.visible) {
      console.log(props?.id, 'props?.id')
      setTableData([])
      setNewAfternoonData({})
      setNewMorningData({})
      if (props?.id) {
        setTitle('编辑')
      } else {
        setTitle('新增')
      }
    }
  }, [props?.id, props?.visible])

  useEffect(() => {
    if (props?.visible)
      form.setFieldsValue({
        schedules: [0],
        classesOne: true,
        classesTwo: true,
        classesOneTimes: [moment('8:30', 'HH:mm'), moment('12:00', 'HH:mm')],
        classesTwoTimes: [moment('13:30', 'HH:mm'), moment('16:30', 'HH:mm')],
      })
    setClassesTwo(true)
    setClassesOne(true)
    setShowFlag(false)
    setSelectDoctorList([])
    setMorningTimes({
      classesStartTime: morningTimes?.classesStartTime || '8:30',
      classesEndTime: morningTimes?.classesEndTime || '12:00',
    })
    setAfternoonTimes({
      classesStartTime: afternoonTimes?.classesStartTime || '13:30',
      classesEndTime: afternoonTimes?.classesEndTime || '16:30',
    })
  }, [props?.visible])

  useEffect(() => {
    if (props?.visible) {
      setDetailId('')
    }
  }, [props?.visible])
  useEffect(() => {
    if (!selectDoctorList?.length) {
      form.setFieldsValue({
        treatmentDocter: '',
      })
    }
  }, [selectDoctorList])

  return (
    <div>
      <Modal
        className={styles.addOrEditModalContent}
        title={title + '活动'}
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        width='800px'
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        footer={null}
      >
        <Form
          autoComplete='off'
          form={form}
          name='basic'
          {...layout}
          onFinish={(fs) => {
            setLoading(true)
            console.log(fs, tableSubmitParams, 'fs')

            const tableSubmitParamsSubmit = tableSubmitParams?.map((v: any) => {
              return {
                ...v,
                appointmentCount: undefined,
                appointmentCounts: undefined,
              }
            })
            const paramsData = {
              title: fs?.title,
              content: fs?.content,
              deadline: fs?.deadline
                ? moment(fs?.deadline).format(DateTimeFormat)
                : undefined,
              address: fs?.address,
              phone: fs?.phone,
              remark: fs?.remark,
              treatmentDate: time,
              schedules: tableSubmitParamsSubmit,
              treatmentDocter: selectDoctorList?.join(','),
              treatmentCount: treatmentCount,
              id: detailId ? detailId : '',
            }
            console.log(
              classesOne,
              classesTwo,
              tableSubmitParamsSubmit,
              'paramsData'
            )

            console.log(tableSubmitParams, 'paramsData')

            dispatch(getFreePatientsSubmit(paramsData))
              .then(unwrapResult)
              .then((v) => {
                setTableSubmitParams([])
                setTableData([])
                setTime('')
                setMorningTimes([])
                setAfternoonTimes([])
                notification.success({
                  message: '操作成功',
                  duration: 3,
                })
                setLoading(false)
                form.resetFields()
                props?.onOk()
                setDetailId('')
              })
              .catch((v) => {
                setLoading(false)
              })
          }}
        >
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动标题'
                name='title'
                rules={[{ required: true, message: '请选择义诊标题' }]}
              >
                <TextArea maxLength={50} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                {...firstColumnLayout}
                label='活动内容'
                name='content'
                rules={[
                  { required: true, message: '请选择义诊内容' },
                  {
                    max: 200,
                    message: '最长100位',
                  },
                ]}
              >
                <TextArea maxLength={200} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name='treatmentDocter'
                label='活动医生'
                {...firstColumnLayout}
                rules={[{ required: true, message: '请选择义诊医生' }]}
              >
                <Select
                  showSearch
                  allowClear
                  onSearch={(v: any) => {
                    console.log(v, 'vvvvvvvv')
                    setNames(v)
                  }}
                  disabled={status != 0 && status != 5}
                  filterOption={false}
                  placeholder='请选择义诊医生'
                  maxLength={20}
                  style={{
                    width: '33%',
                    height: '32px',
                    marginRight: '10px',
                  }}
                  onChange={(v: any) => {
                    setNames(undefined)
                    console.log(v, 'vvvvvvvvvv')
                    const name: any = doctorList?.filter(
                      (i: any) => i.id == v
                    )?.[0]?.name
                    console.log(
                      v,
                      name,
                      selectDoctorList,
                      doctorList?.filter((i: any) => i.id == v)?.[0]
                        ?.hisDepartmentName,
                      '请选择医生'
                    )
                    if (!selectDoctorList?.find((d: any) => d == name)) {
                      if (name) {
                        setSelectDoctorList(selectDoctorList?.concat(name))
                        setTableData([
                          ...tableData,
                          {
                            name: name,
                            docterId: doctorList?.filter(
                              (i: any) => i.id == v
                            )?.[0]?.hisDoctorId,
                            departmentId: doctorList?.filter(
                              (i: any) => i.id == v
                            )?.[0]?.hisDepartmentId,
                            departmentName: doctorList?.filter(
                              (i: any) => i.id == v
                            )?.[0]?.hisDepartmentName,
                          },
                        ])
                      } else {
                        setTableData(tableData)
                        setSelectDoctorList(selectDoctorList)
                      }
                    }
                  }}
                  onBlur={(v: any) => {
                    setNames(undefined)
                    const name = doctorList?.filter((i: any) => i.id == v)?.[0]
                      ?.name
                    if (!selectDoctorList?.find((d: any) => d == name)) {
                      if (name) {
                        setSelectDoctorList(selectDoctorList?.concat(name))
                        setTableData([
                          ...tableData,
                          {
                            name: name,
                            id: v,
                            departmentId: doctorList?.filter(
                              (i: any) => i.id == v
                            )?.[0]?.hisDepartmentId,

                            departmentName: doctorList?.filter(
                              (i: any) => i.id == v
                            )?.[0]?.hisDepartmentName,
                          },
                        ])
                      } else {
                        setSelectDoctorList(selectDoctorList)
                        setTableData(tableData)
                      }
                    }
                  }}
                >
                  {doctorList.map((d: any) => (
                    <Select.Option key={d.id} value={d.id}>
                      {d.name || ''}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {selectDoctorList?.length != 0 && (
            <Row className={styles.selectDoctorListMain}>
              {selectDoctorList?.length != 0 && (
                <div>
                  {selectDoctorList?.map((d: any) => (
                    <div key={d} className={styles.listColor}>
                      <div className={styles.listColorMain}>
                        <div className={styles.listColorMainName}>{d}</div>
                        <div className={styles.closeIcon}>
                          <CloseOutlined
                            style={{
                              color: '#fff',
                              cursor: 'pointer',
                              fontSize: '8px',
                            }}
                            onClick={() => {
                              if (status == 0 || status == 5) {
                                setDeleteDateTableFlag(true)
                                setDeleteId(d)
                                setSelectDoctorList(
                                  selectDoctorList.filter((i: any) => i !== d)
                                )
                                setTableData(
                                  tableData.filter((i: any) => i.name !== d)
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Row>
          )}
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动日期'
                name='treatmentDate'
                rules={[{ required: true, message: '请选择义诊日期' }]}
              >
                <DatePicker
                  placeholder='请选择时间'
                  style={{ width: '50%' }}
                  onChange={(v) => {
                    console.log(v, '义诊日期')
                    setTime(
                      v ? moment(v).format(DateTimeFormatSimple) : undefined
                    )
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item
                {...firstColumnLayout}
                label='活动班次'
                name='classesOne'
                rules={[{ required: true, message: '请选择义诊班次' }]}
              >
                {/* <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    setClassesValue(e)
                  }}
                > */}
                <Checkbox
                  checked={classesOne}
                  disabled={status != 0 && status != 5}
                  onChange={(v) => {
                    setClassesOne(v?.target?.checked)
                    console.log(v?.target?.checked, 'vvvvvvvvvv')
                  }}
                >
                  上午
                </Checkbox>
                {/* </Checkbox.Group> */}
              </Form.Item>
            </Col>
            <Col span={5} style={{ marginLeft: '-20px' }}>
              <Form.Item {...firstColumnLayout} name='classesOneTimes'>
                <TimePicker.RangePicker
                  format='HH:mm'
                  style={{ width: '200px' }}
                  disabled={status != 0 && status != 5}
                  onChange={(v) => {
                    console.log(v, '222222222222')
                    const mStartTime = moment(v?.[0]).format('HH:mm')
                    const mEndTime = moment(v?.[1]).format('HH:mm')
                    setMorningTimes({
                      classesStartTime: mStartTime,
                      classesEndTime: mEndTime,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6} style={{ marginLeft: '100px' }}>
              <Form.Item
                {...firstColumnLayout}
                label=''
                name='classesTwo'
                rules={[{ required: true, message: '请选择义诊班次' }]}
              >
                <Checkbox
                  checked={classesTwo}
                  disabled={status != 0 && status != 5}
                  onChange={(v) => {
                    setClassesTwo(v?.target?.checked)
                    console.log(v?.target?.checked, 'vvvvvvvvvv')
                  }}
                >
                  下午
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6} style={{ marginLeft: '-120px' }}>
              <Form.Item {...firstColumnLayout} name='classesTwoTimes'>
                <TimePicker.RangePicker
                  disabled={status != 0 && status != 5}
                  format='HH:mm'
                  style={{ width: '200px' }}
                  onChange={(v) => {
                    console.log(v, '222222222222')
                    const aStartTime = moment(v?.[0]).format('HH:mm')
                    const aEndTime = moment(v?.[1]).format('HH:mm')
                    setAfternoonTimes({
                      classesStartTime: aStartTime,
                      classesEndTime: aEndTime,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {showFlag && (
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  name='schedules'
                  label='活动名额分布'
                  {...firstColumnLayoutTwo}
                  rules={[{ required: true, message: '请选择义诊名额分布' }]}
                >
                  <EditableList
                    bordered
                    pagination={false}
                    dataSource={tableData}
                    columns={columns}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动总额'
                name='treatmentCount'
                rules={[{ required: true, message: '请选择义诊班次' }]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='报名截止时间'
                name='deadline'
                rules={[{ required: true, message: '请选择报名截止时间' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder='请选择时间'
                  format='YYYY-MM-DD HH:mm:ss'
                  showTime={{ format: 'HH:mm:ss' }}
                  onChange={(v) => {
                    console.log(v, 'vv')
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动地址'
                name='address'
                rules={[
                  { required: true, message: '请选择义诊地址' },
                  {
                    max: 200,
                    message: '最长500位',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动电话'
                name='phone'
                rules={[
                  {
                    required: true,
                    message: '请输入联系电话',
                  },
                  // {
                  //   pattern: /^1\d{10}$/,
                  //   message: '请输入正确手机号',
                  // },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='服务通知备注内容'
                name='remark'
                rules={[
                  {
                    required: true,
                    message: '请输入服务通知备注内容',
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                style={{ float: 'right' }}
                onClick={() => {
                  form.resetFields()
                  props.onCancel()
                }}
              >
                取消
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
                style={{ float: 'left' }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
