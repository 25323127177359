import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface StatisticPrams {
    current: number
    size: number
    startTime: string
    endTime: string
    queryType: number
}

// 图表
export function StatisticApi(): Request {
    return {
        url: `${API_PREFIX}/stat/all`,
        method: 'GET',
    }
}

// 折线图
export function getZhexianDataApi(params: any): Request {
    return {
        url: `${API_PREFIX}/stat/yunyin`,
        method: 'GET',
        params
    }
}

//列表
export function getTableDataApi(params: any): Request {
    return {
        url: `${API_PREFIX}/stat/yunyinminxi`,
        method: 'GET',
        params
    }
}
