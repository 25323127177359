import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Menu,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Divider,
} from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './medicalGuide.module.css'
import { RootDispatch } from '../../app/store'
import { ThemeContext } from '../../theme/ThemeContext'
import { NavBar } from '../../compnents/nav/NavBar'
import { Editor } from '@tinymce/tinymce-react'
import {
  getMedicalGuideDetail,
  getMedicalGuideUpload,
  getTinyUrl,
} from './medicalGuideSlice'
const { Option } = Select

export const buttonSize = 'large'

export const MedicalGuideAddOrEditDetail = () => {
  const dispatch = useDispatch<RootDispatch>()

  const location: any = useLocation()

  const theme = useContext(ThemeContext)

  const [form] = Form.useForm()

  const history = useHistory()

  const [ContentBody, setContentBody] = useState<any>()

  const [detail, setDetail] = useState<any>()

  const rowProps = {
    gutter: 4,
  }

  const colProps = { span: 8 }

  const firstColumnLayout = {
    labelCol: { flex: '6.5rem' },
    wrapperCol: { span: 19 },
  }

  const handleImageUpload: any = (blobInfo: any, progress: any) =>
    new Promise((resolve, reject) => {
      console.log(blobInfo.blob(), 'blobInfo')
      const formData = new FormData()
      formData.append('file', blobInfo.blob())
      dispatch(getTinyUrl(formData))
        .then(unwrapResult)
        .then((v) => {
          console.log(v, 'blobInfo')
          resolve(v)
        })
        .catch(() => {
          reject('上传失败，请重新上传')
        })
    })

  useEffect(() => {
    if (location?.state?.id) {
      dispatch(getMedicalGuideDetail(location?.state?.id))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
          setContentBody(v?.content)
          form.setFieldsValue({
            ...v,
          })
        })
    }
  }, [location?.state?.id])


  return (
    <>
      <Col
        className={styles.addOrEditDetailMain}
        style={{
          margin: '10px',
        }}
      >
        <>
          {location.state?.id ? (
            <NavBar
              where={['就医指南', '编辑']}
              backtrace={{
                name: '就医指南',
                path: '/admin/medicalGuideList',
                routeName: '就医指南',
              }}
            />
          ) : (
            <NavBar
              where={['就医指南', '新增']}
              backtrace={{
                name: '就医指南',
                path: '/admin/medicalGuideList',
                routeName: '就医指南',
              }}
            />
          )}
        </>
      </Col>
      <div className={styles.form}>
        <Form
          name='basic'
          form={form}
          labelCol={{
            span: 3,
          }}
          wrapperCol={{
            span: 21,
          }}
          onFinish={(values) => {
            console.log(values, 'values')
            dispatch(
              getMedicalGuideUpload({
                ...detail,
                ...values,
                content: values?.content?.level?.content,
              })
            )
              .then(unwrapResult)
              .then((v) => {
                history.push({
                  pathname: '/admin/medicalGuideList',
                })
                notification.success({
                  message: '操作成功',
                  duration: 3,
                  // onClose: () => {
                  // },
                })
              })
          }}
        >
          <Row {...rowProps}>
            <Col {...colProps} span={12}>
              <Form.Item
                {...firstColumnLayout}
                name='title'
                label='标题'
                required
                rules={[{ required: true, message: '标题不能为空' }]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col span={24}>
              <Form.Item
                {...firstColumnLayout}
                name='content'
                label='内容'
                required
                rules={[{ required: true, message: '内容不能为空' }]}
              >
                <Editor
                  id={'tincyEditor'}
                  apiKey={'p3ro6wyg9qkte1x1gsubztktrsp7b0oanq16112u99carvxt'}
                  value={ContentBody}
                  inline={false}
                  onChange={(e: any) => {
                    const value = e.level.content
                    console.log(e.level.content, ' e.level.content')
                    setContentBody(value)
                  }}
                  init={{
                    selector: '#textarea' as any,
                    language: 'zh_CN',
                    height: '500px',
                    images_upload_handler: handleImageUpload,
                    convert_urls: false,
                    plugins:
                      'initEditor  preview searchreplace autolink directionality visualblocks visualchars fullscreen image link template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave bdmap indent2em autoresize formatpainter axupimgs',
                    toolbar:
                      'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                    table image pageembed  charmap emoticons hr pagebreak insertdatetime  preview | fullscreen | bdmap indent2em lineheight formatpainter axupimgs',
                    toolbar_sticky: true,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowProps}>
            <Col span={12}>
              <Form.Item
                {...firstColumnLayout}
                name='sorter'
                label='排序'
                rules={[
                  {
                    pattern: /^(\d?[1-9]|[1-9]0)$/,
                    message: '请输入1-99的数字',
                  },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
            </Col>
            <Col span={12} className={styles.tip}>
              1～99，数字越小排在越前面
            </Col>
          </Row>

          <Row className={styles.buttonMain}>
            <Button
              type='primary'
              ghost
              style={{
                float: 'left',
                marginLeft: '115px',
                color: '#999',
                borderColor: '#999',
              }}
              onClick={() => {
                history.push({
                  pathname: '/admin/medicalGuideList',
                })
              }}
            >
              取消
            </Button>
            <Button
              type='primary'
              style={{
                float: 'left',
                marginLeft: '20px',
              }}
              onClick={() => {
                form.submit()
              }}
            >
              保存
            </Button>
          </Row>
        </Form>
      </div>
    </>
  )
}
