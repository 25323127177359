/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-15 16:42:24
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-28 11:33:01
 */
import { API_PREFIX, Request, TextPlain } from './request'

export interface urlLinkParams {
  fileName?: any
  bucketName?: any
}
export function getUrlLink(params: urlLinkParams): Request {
  return {
    url: `${API_PREFIX}/blade-user/oss/endpoint/file-link`,
    method: 'GET',
    params,
  }
}

//右侧详情
export function hospitalIntroduceDetail(): Request {
  return {
    url: `${API_PREFIX}/adminTenant/detail`,
    method: 'GET',
  }
}

//右侧详情
export function advertisingDetail(): Request {
  return {
    url: `${API_PREFIX}/advert/page`,
    method: 'GET',
  }
}


export interface HospitalIntroduceSubmitParams {
  hosHeaderFigure: any
  introduction: any
}

// 保存
export function hospitalIntroduceSubmit(
  body: HospitalIntroduceSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/adminTenant/saveOrUpdate`,
    method: 'POST',
    body,
  }
}


export interface AdvertisingParams {
  isShow?: any
  pic: any
  url?: any
  // id?:any
}

// 保存
export function advertisingSubmit(body: AdvertisingParams): Request {
  return {
    url: `${API_PREFIX}/advert/submit`,
    method: 'POST',
    body,
  }
}


