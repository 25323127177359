import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { api } from '../app/applicationSlice'
import { RootState, RootThunk } from '../app/store'
import { constructMenuLayers, Menu, MenuCategory } from '../models/menu'
import { getUserMenus } from '../services/config'

const BreadcrumbKey = 'breadcrumbs'

export interface Breadcrumb<S = unknown> {
  name: string
  path: string
  query?: string
  state?: S
  params?: any
}

interface LayoutState {
  menus: Menu[]
  fixed: Menu[]
  breadcrumbs: Breadcrumb[]
  current?: Breadcrumb
  tipText?: any
  shade?: any
}

const initialState: LayoutState = {
  fixed: [],
  menus: [],
  breadcrumbs: [],
  tipText: false,
  shade: '',
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    traceRoute: (state, action: PayloadAction<Breadcrumb>) => {
      let breadcrumb = state.breadcrumbs.find(
        (m) => m.name === action.payload.name
      )
      if (!breadcrumb) {
        breadcrumb = state.fixed.find((m) => m.name === action.payload.name)
      }
      if (breadcrumb) {
        breadcrumb.path = action.payload.path
        breadcrumb.query = action.payload.query
        breadcrumb.state = action.payload.state
        breadcrumb.params = action.payload.params
      } else {
        state.breadcrumbs.push(action.payload)
      }
      sessionStorage.setItem(BreadcrumbKey, JSON.stringify(state.breadcrumbs))
      state.current = breadcrumb || action.payload
    },
    untraceRoute: (state, action: PayloadAction<Breadcrumb>) => {
      let current = state.current
      if (action.payload.name === state.current?.name) {
        current = undefined
      }
      state.breadcrumbs = _.chain(state.breadcrumbs)
        .filter((b) => b.name !== action.payload.name)
        .value()
      if (!current) {
        current = _.chain(state.breadcrumbs).last().value()
      }
      if (!current && state.fixed.length > 0) {
        current = state.fixed[0]
      }
      sessionStorage.setItem(BreadcrumbKey, JSON.stringify(state.breadcrumbs))
      state.current = current
    },
    traceHint: (state, action: PayloadAction<string>) => {
      if (
        state.current ||
        (state.fixed.length === 0 && state.breadcrumbs.length === 0)
      ) {
        return
      }
      const breadcrumb =
        state.fixed.find((b) => b.path === action.payload) ||
        state.breadcrumbs.find((b) => b.path === action.payload)
      state.current = breadcrumb
    },
    removeBreadcrumbs: (state, action: PayloadAction<void>) => {
      state.breadcrumbs = []
      state.current = undefined
      sessionStorage.removeItem(BreadcrumbKey)
    },
    setMenus: (state, action: PayloadAction<Menu[]>) => {
      state.current = undefined
      const json = sessionStorage.getItem(BreadcrumbKey) || '[]'
      state.breadcrumbs = JSON.parse(json)
      state.menus = action.payload
      if (action.payload.length > 0) {
        let home = action.payload[0]
        while (home.subMenus.length > 0) {
          home = home.subMenus[0]
        }
        state.fixed = [home]
        if (state.breadcrumbs.length == 0) {
          state.current = home
        }
      }
    },
    setAppShadeTipText(state, action) {
      state.tipText = action.payload
    },
    setAppShadeIsModal(state, action) {
      state.shade = action.payload
    },
  },
})

export const {
  traceRoute,
  untraceRoute,
  traceHint,
  removeBreadcrumbs,
  setMenus,
  setAppShadeTipText,
  setAppShadeIsModal,
} = layoutSlice.actions

export function getMenus(): RootThunk {
  return (dispatch, getState, args) => {
    api(getUserMenus(), (data: any, dispatch) => {
      const menus = constructMenuLayers(data.records)
      dispatch(setMenus(menus))
    })(dispatch, getState, args)
  }
}

export const selectMenus = (state: RootState): Menu[] => state.layout.menus

export const selectFixedMenus = (state: RootState): Menu[] => state.layout.fixed

export const selectBreadcrumbs = (state: RootState): Breadcrumb[] =>
  state.layout.breadcrumbs

export const selectCurrentRoute = (state: RootState): Breadcrumb | undefined =>
  state.layout.current

export const selectAppShadeTipText = (state: RootState) => state.layout.tipText

export const selectAppShadeIsModal = (state: RootState) => state.layout.shade


export default layoutSlice.reducer
