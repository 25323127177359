export interface Disease {
  id: string;
  name: string;

  // 助记码
  mnemonicCode: string;

  // icds
  icds: string;

  // 空或者0:普病；1:特病；2:慢病
  category: number;
}

export function fromJson(
  json: { id?: number } & Record<string, never>
): Disease {
  return {
    id: json.id?.toString() || "",
    name: json.name,
    mnemonicCode: json.mnemonicCode,
    icds: json.icds,
    category: json.categorym,
  };
}
