/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-17 15:53:16
 */
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import form from 'antd/lib/form'
import { getMedicalGuideDetail } from './medicalGuideSlice'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'

export const MedicalGuideDetailModal = (props: {
  id?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const labelStyle = {
    style: {
      fontSize: '1rem',
      textAlign: 'left',
      marginBottom: 6,
    } as React.CSSProperties,
  }

  const valueStyle = {
    flex: 1,
    style: {
      fontSize: '1rem',
      marginBottom: 4,
    } as React.CSSProperties,
  }

  useEffect(() => {
    if (props?.visible) {
      dispatch(getMedicalGuideDetail(props?.id))
        .then(unwrapResult)
        .then((v: any) => {
          setDetail(v)
        })
    }
  }, [props?.visible])

  return (
    <div>
      <Modal
        title='详情'
        visible={props.visible}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
        width={1000}
      >
        <Row gutter={20} style={{ marginLeft: '10px' }}>
          <Col span={24} style={{ marginBottom: '10px' }}>
            <Row>
              <Col {...labelStyle}>标题：</Col>
              <Col {...valueStyle} span={22}>
                {detail?.title}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col {...labelStyle}>内容：</Col>
              <Col {...valueStyle} span={22}>
                <div
                  dangerouslySetInnerHTML={{ __html: detail?.content }}
                ></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
