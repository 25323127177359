/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-04-07 15:05:46
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 11:02:37
 */
export enum FreePatientStateCategory {
  WFB = '0',
  YYZ = '1',
  DYZ = '2',
  // YZZ = '3',
  YJS = '4',
  YCH = '5',
}
// 全部
export const FreePatientStateCategories = [
  FreePatientStateCategory.WFB,
  FreePatientStateCategory.YYZ,
  FreePatientStateCategory.DYZ,
  // FreePatientStateCategory.YZZ,
  FreePatientStateCategory.YJS,
  FreePatientStateCategory.YCH,
]

export function FreePatientStateName(state: FreePatientStateCategory): string {
  switch (state) {
    case '0':
      return '待发布'
    case '1':
      return '预约中'
    case '2':
      return '待义诊'
    // case '3':
    //   return '义诊中'
    case '4':
      return '已结束'
    case '5':
      return '已撤回'
  }
  return '-'
}
