import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface WorkTemplatePrams {
  current: number
  size: number
}

// 列表
export function workTemplateData(params: WorkTemplatePrams): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheettemplate/list`,
    method: 'GET',
    params,
  }
}

export interface workTemplateSaveorupdateParams {
  id?: any
  ermerge?: any
  location?: any
  handle?: any
}

// 保存
export function workTemplateSaveorupdate(
  body: workTemplateSaveorupdateParams
): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheettemplate/saveorupdate`,
    method: 'POST',
    body,
  }
}

// 流程设计
export function workTemplateTimeLineData(templateid: any): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheetprocesstemplate/queryByTemplateId`,
    method: 'GET',
    params: {
      templateid,
    },
  }
}

export interface workTemplateTimeLineTitleEditParams {
  id?: any
  name?: any
}

// 保存

export function workTemplateTimeLineTitleEdit(
  body: workTemplateTimeLineTitleEditParams
): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheetprocesstemplate/update`,
    method: 'POST',
    body,
  }
}

export interface workTemplateChooseUserEditParams {
  id?:any
  granters?: any
}

export function workTemplateChooseUserEdit(
  body: workTemplateChooseUserEditParams
): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheetprocesstemplate/updateGranters`,
    method: 'POST',
    body,
  }
}



