import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  TimePicker,
} from 'antd'
import styles from './freePatients.module.css'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'

import moment from 'moment'
import { EditableList } from '../../compnents/list/EditableList'
import { getDoctorManagementList } from '../DoctorManagement/doctorManagementSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { getFreePatientDetailModal } from './freePatientSlice'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const firstColumnLayout = {
  labelCol: { flex: '6.5rem' },
  wrapperCol: { span: 19 },
}

const { TextArea } = Input

export const FreePatientDetailModal = (props: {
  id: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const [title, setTitle] = useState('新增')

  const [time, setTime] = useState<any>()

  const [selectDoctorList, setSelectDoctorList] = useState<any>([])

  const [doctorList, setDoctorList] = useState<any>([]) //  医生数据

  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [tableData, setTableData] = useState<any>([]) //表格数据

  const [classesOneFlag, setClassesOneFlag] = useState<any>() //活动班次

  const [classesTwoFlag, setClassesTwoFlag] = useState<any>() //活动班次

  const [columns, setColumns] = useState<any>()

  const [morningValue, setMorningValue] = useState<any>([])

  const [afternoonValue, setAfternoonValue] = useState<any>([])

  const [showFlag, setShowFlag] = useState(true)

  console.log(selectDoctorList, 'selectDoctorList') 

  useEffect(() => {
    //获取医生数据
    if (props?.visible)
      dispatch(
        getDoctorManagementList({
          size: 1000,
          current: 1,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setDoctorList(res?.records.filter((v: any) => v?.isDeleted != 1)) // 筛选已注销科室的医生
        })
  }, [props?.visible])

  useEffect(() => {
    if (classesTwoFlag && classesOneFlag) {
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
        },
        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
        },
      ])
    } else if (classesTwoFlag && !classesOneFlag) {
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },

        {
          title: '下午',
          dataIndex: 'afternoon',
          width: '6rem',
          align: 'center',
        },
      ])
    } else if (!classesTwoFlag && classesOneFlag) {
      setColumns([
        {
          title: time,
          dataIndex: 'name',
          width: '6rem',
          align: 'center',
        },
        {
          title: '上午',
          dataIndex: 'morning',
          width: '6rem',
          align: 'center',
        },
      ])
    }
  }, [time, classesTwoFlag, classesOneFlag])

  useEffect(() => {
    //获取数据
    if (props?.visible)
      if (props?.id)
        dispatch(getFreePatientDetailModal(props?.id))
          .then(unwrapResult)
          .then((res: any) => {
            form.setFieldsValue({
              title: res?.title,
              content: res?.content,
              treatmentCount: res?.treatmentCount,
              address: res?.address,
              phone: res?.phone,
              treatmentDate: res?.treatmentDate
                ? moment(res?.treatmentDate)
                : '',
              deadline: res?.deadline ? moment(res?.deadline) : '',
            })

            setTime(res?.treatmentDate)
            const m = res?.schedules?.filter((v: any) => {
              return v?.classes == 0
            })
            const a = res?.schedules?.filter((v: any) => {
              return v?.classes == 1
            })
            setClassesOneFlag(m?.length)
            setClassesTwoFlag(a?.length)
            setMorningValue([
              m?.[0]?.classesStartTime
                ? moment(m?.[0]?.classesStartTime, 'HH:mm')
                : moment('8:30', 'HH:mm'),
              m?.[0]?.classesEndTime
                ? moment(m?.[0]?.classesEndTime, 'HH:mm')
                : moment('12:00', 'HH:mm'),
            ])

            setAfternoonValue([
              a?.[0]?.classesStartTime
                ? moment(a?.[0]?.classesStartTime, 'HH:mm')
                : moment('13:30', 'HH:mm'),
              a?.[0]?.classesEndTime
                ? moment(a?.[0]?.classesEndTime, 'HH:mm')
                : moment('16:30', 'HH:mm'),
            ])
            const tableDataM = m?.map((v: any) => {
              return {
                ...v,
                id: v?.id,
                docterId: v?.docterId,
                name: v?.docterName,
                departmentId: v?.departmentId,
                departmentName: v?.departmentName,
                morning: v?.count > 0 ? v?.count : 0,
                afternoon:
                  a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                    ?.count > 0
                    ? a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                      ?.count
                    : 0,
                ids: a?.filter((i: any) => i?.docterId == v?.docterId)?.[0]?.id,
              }
            })
            const tableDataA = a?.map((v: any) => {
              return {
                ...v,
                ids: v?.id,
                docterId: v?.docterId,
                name: v?.docterName,
                departmentId: v?.departmentId,
                departmentName: v?.departmentName,
                afternoon: v?.count > 0 ? v?.count : 0,
                morning:
                  m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                    ?.count > 0
                    ? m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]
                      ?.count
                    : 0,
                id: m?.filter((i: any) => i?.docterId == v?.docterId)?.[0]?.id,
              }
            })

            console.log(tableDataM, tableDataA, 'tableDataM')
            const tableData = m?.length ? tableDataM : tableDataA
            setTableData(tableData)
            setSelectDoctorList(
              res?.treatmentDocter ? res?.treatmentDocter?.split(',') : []
            )
          })
  }, [props?.visible])

  return (
    <div>
      <Modal
        className={styles.addOrEditModalContent}
        title={'活动详情'}
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        width='800px'
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        footer={null}
      >
        <Form autoComplete='off' form={form} name='basic' {...layout}>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item {...firstColumnLayout} label='活动标题' name='title'>
                <Input disabled={props?.id ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item {...firstColumnLayout} label='活动内容' name='content'>
                <TextArea disabled={props?.id ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                name='docterId'
                label='活动医生'
                {...firstColumnLayout}
              >
                {selectDoctorList?.length ? (
                  <Row className={styles.selectDoctorListMains}>
                    {/* <div> */}
                    {selectDoctorList &&
                      selectDoctorList?.map((d: any) => (
                        <div key={d} className={styles.listColors}>
                          <div className={styles.listColorMains}>
                            <div className={styles.listColorMainName}>{d}</div>
                          </div>
                        </div>
                      ))}
                    {/* </div> */}
                  </Row>
                ) : (
                  ''
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动日期'
                name='treatmentDate'
              >
                <DatePicker
                  disabled={props?.id ? true : false}
                  placeholder='请选择时间'
                  style={{ width: '50%' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                {...firstColumnLayout}
                label='活动班次'
                name='schedules'
              >
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    console.log(e, '11111111111')
                  }}
                >
                  <Row>
                    {classesOneFlag ? (
                      <Col span={12}>
                        <div>
                          <>
                            <span
                              style={{ marginRight: '5px', marginLeft: '5px' }}
                            >
                              上午
                            </span>
                            <TimePicker.RangePicker
                              disabled={props?.id ? true : false}
                              format='HH:mm'
                              style={{ width: '200px' }}
                              defaultValue={[
                                moment('8:30', 'HH:mm'),
                                moment('12:00', 'HH:mm'),
                              ]}
                              value={morningValue}
                            />
                          </>
                        </div>
                      </Col>
                    ) : (
                      ''
                    )}
                    {classesTwoFlag ? (
                      <Col span={12}>
                        <div>
                          <>
                            <span
                              style={{ marginRight: '5px', marginLeft: '5px' }}
                            >
                              下午
                            </span>
                            <TimePicker.RangePicker
                              disabled={props?.id ? true : false}
                              format='HH:mm'
                              style={{ width: '200px' }}
                              defaultValue={[
                                moment('13:30', 'HH:mm'),
                                moment('16:30', 'HH:mm'),
                              ]}
                              value={afternoonValue}
                            />
                          </>
                        </div>
                      </Col>
                    ) : (
                      ''
                    )}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          {showFlag && (
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  name='schedules'
                  label='活动名额分布'
                  {...firstColumnLayout}
                >
                  <EditableList
                    bordered
                    pagination={false}
                    dataSource={tableData}
                    columns={columns}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='活动总额'
                name='treatmentCount'
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item
                {...firstColumnLayout}
                label='报名截止时间'
                name='deadline'
              >
                <DatePicker
                  disabled={props?.id ? true : false}
                  style={{ width: '100%' }}
                  placeholder='请选择时间'
                  format='YYYY-MM-DD HH:mm:ss'
                  showTime={{ format: 'HH:mm:ss' }}
                  onChange={(v) => {
                    console.log(v, 'vv')
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item {...firstColumnLayout} label='活动地址' name='address'>
                <Input disabled={props?.id ? true : false} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={16}>
              <Form.Item {...firstColumnLayout} label='联系电话' name='phone'>
                <Input disabled={props?.id ? true : false} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
