import { API_PREFIX } from './constant'
import { Request } from './request'

// 核销列表
export interface MemberHexiaoListParams {
  current: number
  size?: number
  param?: string
  reason?: any
  startTime?: any
  endTime?: any
}

export function memberHexiaoList(params: MemberHexiaoListParams): Request {
  return {
    url: `${API_PREFIX}/openUser/searchMemberPointsRecordByParam`,
    method: 'GET',
    params,
  }
}
