/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-06 16:12:24
 */

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Input, Modal, Space } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserId } from '../../app/applicationSlice'
import { RootDispatch } from '../../app/store'
import styles from './accountManagement.module.css'
import { getAccountManagementDetail } from './accountManagementSlice'
import { EditUserNameModal } from './editUserNameModal'
import { EditUserPassWordModal } from './editUserPassWordModal'

interface editUserMessageModalProps {
  id: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const EditUserMessageModal = ({
  id,
  visible,
  onCancel,
  onOk,
}: editUserMessageModalProps): ReactElement => {
  const userId = useSelector(selectUserId)

  const [form] = Form.useForm()

  const [isUserNameModalVisible, setIsUserNameModalVisible] = useState<any>(
    false
  )

  const [isUserPassWordModalVisible, setIsUserPassWordModalVisible] = useState(
    false
  )

  const dispatch = useDispatch<RootDispatch>()

  const [detail, setDetail] = useState<any>()

  const [name, setName] = useState('')

  useEffect(() => {
    if (visible) {
      dispatch(getAccountManagementDetail(id))
        .then(unwrapResult)
        .then((v) => {
          setDetail(v)
        })
    }
  }, [visible])

  useEffect(() => {
    if (detail) {
      setName(detail?.name)
    }
  }, [detail])

  useEffect(() => {
    if (detail) {
      form.setFieldsValue({
        ...detail,
        password: '******',
      })
    }
  }, [detail])

  return (
    <>
      <Modal
        title='个人信息'
        width={600}
        visible={visible}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        centered
        footer={null}
        className={styles.editRemark}
        maskStyle={{
          overflow: 'hidden',
        }}
      >
        <Form
          className={styles.form}
          form={form}
          onFinish={(values) => {
            return
          }}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
        >
          <div style={{ height: '160px' }}>
            <div style={{ display: 'flex' }}>
              <Form.Item
                label='姓名'
                name='name'
                style={{ marginRight: '55px' }}
              >
                <Input style={{ width: '300px' }} disabled={true} />
              </Form.Item>
              <Button
                type='primary'
                style={{ width: '80px', height: '30px' }}
                size='middle'
                onClick={() => {
                  setIsUserNameModalVisible(true)
                }}
              >
                修改
              </Button>
            </div>
            <div style={{ display: 'flex', marginLeft: '-3px' }}>
              <Form.Item label='手机号' name='phone'>
                <Input style={{ width: '300px' }} disabled={true} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
              <Form.Item
                label='密码'
                name='password'
                style={{ marginRight: '55px' }}
              >
                <Input style={{ width: '300px' }} disabled={true} />
              </Form.Item>
              <Button
                type='primary'
                style={{ width: '80px', height: '30px' }}
                size='middle'
                onClick={() => {
                  setIsUserPassWordModalVisible(true)
                }}
              >
                修改
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
      <EditUserNameModal
        refresh={() => {
          dispatch(getAccountManagementDetail(id))
            .then(unwrapResult)
            .then((v) => {
              setDetail(v)
            })
        }}
        name={name}
        visible={isUserNameModalVisible}
        id={userId}
        onCancel={() => {
          setName('')
          dispatch(getAccountManagementDetail(id))
            .then(unwrapResult)
            .then((v) => {
              setDetail(v)
            })
          setIsUserNameModalVisible(false)
        }}
        onOk={() => {
          setIsUserNameModalVisible(false)
          onOk()
          setName('')
        }}
      />
      <EditUserPassWordModal
        visible={isUserPassWordModalVisible}
        id={userId}
        onCancel={() => {
          setIsUserPassWordModalVisible(false)
        }}
        onOk={() => {
          setIsUserPassWordModalVisible(false)
          onOk()
        }}
      />
    </>
  )
}
