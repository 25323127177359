/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-27 17:24:07
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-13 15:57:08
 */

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Timeline,
  Checkbox
} from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { getDepartCountWithUserList } from '../../divisionManagement/divisionManagementSlice'
import { getWorkTemplateChooseUserEdit } from '../workOrderTemplateSlice'
import styles from './maintenanceWorkOrderTemplate.module.css'

interface editTimeLineTitleModalProps {
  grantersId?: any
  id: any
  name?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const ChooseApproverModal = ({
  grantersId,
  id,
  name,
  visible,
  onCancel,
  onOk,
}: editTimeLineTitleModalProps): ReactElement => {
  const [form] = Form.useForm()

  const dispatch = useDispatch<RootDispatch>()

  const [radioTypeId, setRadioTypeId] = useState<any>('')

  const [radioTypeIdList, setRadioTypeIdList] = useState<any>([])

  const [departmentList, setDepartmentList] = useState<any>()

  const [key, setKey] = useState<any>('')

  useEffect(() => {
    if (visible) {
      dispatch(getDepartCountWithUserList(key))
        .then(unwrapResult)
        .then((v: any) => {
          const list = v?.map((t: any) => {
            return { ...t, open: true }
          })
          setDepartmentList(list)
        })
    }
  }, [visible, name, key])

  useEffect(() => {
    if (visible) {
      console.log(grantersId, 'grantersId')
      // setRadioTypeId(Number(grantersId))
      const ids = grantersId?.split(',')?.map((v: string) => Number(v))
      setRadioTypeIdList(ids)
    } else {
      // setRadioTypeId('')
      setRadioTypeIdList([])
    }
  }, [grantersId, visible])

  return (
    <Modal
      title={'选择人员'}
      width={500}
      visible={visible}
      onOk={() => {
        form.validateFields().then((values) => {
          console.log(values, 'values')
          // if (radioTypeIdList?.length > 2) {
          //   notification.error({ message: '审批人员不能多于2个', duration: 3 })
          //   return
          // }
          dispatch(
            getWorkTemplateChooseUserEdit({
              id: id,
              granters: radioTypeIdList.join(','),
            })
          )
            .then(unwrapResult)
            .then(() => {
              notification.success({ message: '保存成功', duration: 3 })
              form.resetFields()
              onOk()
            })
            .catch(() => {
              notification.error({ message: '保存失败', duration: 3 })
              form.resetFields()
            })
        })
      }}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      className={styles.chooseApproverModal}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <Form
        name='dynamic_form_nest_item'
        form={form}
        onFinish={(v) => {
          form.resetFields()
          console.log(v, 'values')
        }}
        autoComplete='off'
      >
        <Form.Item label='' name='key'>
          <Input
            placeholder='搜索用户'
            style={{
              width: '250px',
            }}
            onChange={(v) => {
              setKey(v)
              console.log(v, 'ccccccccccccc')
            }}
          />
        </Form.Item>
        <Checkbox.Group
          value={radioTypeIdList}
          style={{ width: '100%' }}
          onChange={(list: CheckboxValueType[]) => {
            // setRadioTypeId(e?.target?.value)
            setRadioTypeIdList(list);
            console.log(list, '111111111111')
          }}
        >
          <div className={styles.openQuerymain}>
            {departmentList?.map((v: any, index?: any) => {
              return (
                <div key={index}>
                  <div
                    className={styles.openQueryTitle}
                    onClick={() => {
                      setDepartmentList(
                        departmentList?.map((t: any) => {
                          if (t.id == v.id) {
                            return {
                              ...t,
                              open: !t.open,
                            }
                          } else {
                            return t
                          }
                        })
                      )
                    }}
                  >
                    <div className={styles.openQueryTitleMain}>
                      <div className={styles.openQueryTitleText}>
                        <span>
                          {v?.name} ({v?.users?.length})
                        </span>
                      </div>
                      <div style={{ margin: '10px 0' }}>
                        {v?.open ? (
                          <DownOutlined
                            style={{
                              width: '18px',
                              height: '18px',
                              color: ' #C4C4C4',
                            }}
                          />
                        ) : (
                          <RightOutlined
                            style={{
                              width: '14px',
                              height: '14px',
                              fontSize: '14px',
                              color: ' #C4C4C4',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    {v?.open &&
                      v?.users &&
                      v?.users?.map((n: any) => {
                        return (
                          <>
                            <Space direction='vertical'>
                              <Checkbox value={n?.id}>{n?.name}</Checkbox>
                            </Space>
                          </>
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
        </Checkbox.Group>
      </Form>
    </Modal>
  )
}
