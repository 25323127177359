import { SearchOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
} from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { TabBar, TabPane } from '../../compnents/widgets/TabBar'
import { ThemeContext } from '../../theme/ThemeContext'

export const ThirdReportSearch = (): ReactElement => {
  return (

    <div style={{position: "relative",width:"100vw",height:"100vh"}}>
        <iframe
            src={"http://cmlabs.com.cn:8020/"}
            style={{ width: "100%", height: "100%" }}
            scrolling='auto'
            frameBorder='0'
        ></iframe>
    </div>
  )
}

