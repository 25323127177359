import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/statistic'

// 列表
export const getStatistic = createAsyncThunk<
    any, void, RootThunkApi<void>
    >('clinic/getStatistic', async (_, api) => {
    return sendAsync(DT.StatisticApi(), api)
})

//折线图
export const getZhexianData = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('clinic/getZhexianData', async (params, api) => {
        return sendAsync(DT.getZhexianDataApi(params), api)
})

//列表
export const getTableData = createAsyncThunk<
    void,
    any,
    RootThunkApi<void>
    >('clinic/getTableDataApi', async (params, api) => {
        return sendAsync(DT.getTableDataApi(params), api)
})
