/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:16:15
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-13 15:56:56
 */

import { unwrapResult } from '@reduxjs/toolkit'
import { Button, Col, Form, Modal, notification } from 'antd'
import React, {
  forwardRef,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../../app/store'
import { EditableList } from '../../../compnents/list/EditableList'
import { ThemeContext } from '../../../theme/ThemeContext'
import { Query } from './Query'
import { getDemandWorkOrderList, deleteListFn } from './demandWorkOrderSlice'
import { columns } from './column'
import { DetailModal } from './detailModal'
import moment from 'moment'
import { DateTimeFormat, DateTimeFormatSimple } from '../../../models/datetime'
import { getWorkOrderStatus } from '../../../models/workOrder'
import { getDepartCountWithUserList } from '../../divisionManagement/divisionManagementSlice'
import { FilenameListModal } from '../maintenanceWorkOrder/lookPhotoModal'
import { getWorkTemplateData } from '../../workOrderTemplate/workOrderTemplateSlice'

export interface DemandWorkOrderListRef {
  tabKey?: any
}

interface DemandWorkOrderProps {
  tabKey?: any
}
const RealDemandWorkOrderList = (
  { tabKey }: DemandWorkOrderProps,
  ref: React.Ref<DemandWorkOrderListRef>
): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const theme = useContext(ThemeContext)

  const [id, setId] = useState('')

  const [isDetailVisible, setIsDetailVisible] = useState(false)

  const [filenameList, setFilenameList] = useState<string>()

  const [isFilenameListVisible, setIsFilenameListVisible] = useState(false)

  const [pageLoading, setPageLoading] = useState(false) //表格 loading

  const [departmentList, setDepartmentList] = useState<any>()

  const [data, setData] = useState<any>()

  const [locationList, setLocationList] = useState<any>()
  
  const [ermergesList, setErmergesList] = useState<any>()

  const [params, setParams] = useState<any>({
    current: 1,
    size: 10,
  })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const getQueryPage = () => {
    dispatch(getWorkTemplateData({ current: 1, size: 10000 }))
      .then(unwrapResult)
      .then((v: any) => {
        setData(v?.records)
      })
  }

  useEffect(() => {
    if (data?.length) {
      const locations = data?.filter((v: any) => {
        return v?.name == '信息化需求工单'
      })?.[0]?.location

      const ermerges = data?.filter((v: any) => {
        return v?.name == '信息化需求工单'
      })?.[0]?.ermerge

      setLocationList(locations?.split(','))
      setErmergesList(ermerges?.split(','))
    }
  }, [data])

  useEffect(() => {
    if (tabKey) {
      dispatch(getDepartCountWithUserList(''))
        .then(unwrapResult)
        .then((v) => {
          setDepartmentList(v)
        })
    }
  }, [])

  const getPage = () => {
    setPageLoading(true)
    console.log(params, 'params')
    dispatch(
      getDemandWorkOrderList({
        ...params,
        current: params.current,
        size: params.size,
        name: '信息化需求工单',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    if (tabKey == '0') {
      getPage()
      getQueryPage()
      console.log(tabKey, 'tabKey')
    }
  }, [params, tabKey])

  const exportList = () => {
    const theader = [
      '工单编号',
      '审批部门',
      '申请人',
      '紧急程度',
      '需求日期',
      '需求位置',
      '需求描述',
      '提交时间',
      '状态',
    ]
    const filterVal = [
      'id',
      'departName',
      'submiter',
      'ermerge',
      'sheetTime',
      'location',
      'describes',
      'updateTime',
      'status',
    ]
    dispatch(
      getDemandWorkOrderList({
        ...params,
        current: 1,
        size: 10000,
        name: '信息化需求工单',
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        const data = res.records
          .map((v: any) => ({
            ...v,
            sheetTime: v.sheetTime
              ? moment(v?.sheetTime).format(DateTimeFormatSimple)
              : '-',
            updateTime: v.updateTime
              ? moment(v?.updateTime).format(DateTimeFormatSimple)
              : '-',
            status: v.status?.toString()
              ? getWorkOrderStatus(v.status?.toString())
              : '-',
          }))
          ?.map((s: any) => filterVal?.map((j: any) => s[j]))
        import('../../../ExportXLSX/Export2Excel').then((excel) => {
          excel.export_json_to_excel({
            header: theader,
            data,
            filename: '信息化需求工单',
            autoWidth: true,
          })
        })
      })
  }

  return (
    <Col
      style={{
        height: 'calc(100% - 20px)',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Col
        style={{
          flex: 1,
          backgroundColor: theme.pn,
          borderRadius: 10,
          padding: '15px  15px 0 15px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Query
          ermergesList={ermergesList}
          locationList={locationList}
          departmentList={departmentList}
          export={() => {
            exportList()
          }}
          onValueChange={(v: any) => {
            console.log(v,"yyyyyyyyyyyy");
            
            setParams({
              ...params,
              ...v,
              current: 1,
              startTime: v?.timeHead,
              endTime: v?.timeTail,
              timeHead: undefined,
              timeTail: undefined,
            })
          }}
        />
        <EditableList
          rowKey={(_: any, i: any) => `${i}}`}
          page={{
            items: page.items,
            current: params.current,
            size: params.size,
            total: page.total,
          }}
          loading={pageLoading}
          columns={columns((action: any, v: any) => {
            switch (action) {
              case 'lookDetail':
                setIsDetailVisible(true)
                setId(v.id)
                break
              case 'lookPhoto':
                setFilenameList(v?.urls)
                setIsFilenameListVisible(true)
                break
              case 'delete':
                Modal.confirm({
                  content: `是否删除？`,
                  cancelText: '取消',
                  okText: '确认',
                  onOk: () =>
                    dispatch(
                      deleteListFn(v?.id)
                    )
                      .then(unwrapResult)
                      .then((v) => {
                        notification.success({
                          message: '操作成功',
                          duration: 3,
                        })
                        getPage()
                      }),
                })

                break
            }
          }, params.size * (params.current - 1))}
          onChangePage={(current, size) => {
            console.log(params, 'change')
            setParams({ ...params, current, size: size || 10 })
          }}
        />
      </Col>
      <DetailModal
        id={id}
        visible={isDetailVisible}
        onCancel={() => {
          setIsDetailVisible(false)
          setId('')
          getPage()
        }}
        onOk={() => {
          setIsDetailVisible(false)
          setId('')
          getPage()
        }}
      />
      <FilenameListModal
        filename={filenameList}
        visible={isFilenameListVisible}
        onCancel={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
          getPage()
        }}
        onOk={() => {
          setIsFilenameListVisible(false)
          setFilenameList('')
          getPage()
        }}
      />
    </Col>
  )
}

export const DemandWorkOrderList = forwardRef(RealDemandWorkOrderList)
