/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 10:14:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-18 11:54:16
 */
import { API_PREFIX } from './constant'
import { Request } from './request'

export interface freePatientQueryParams {
  current: number
  size?: number
  param?: string
}

// 列表
export function freePatientList(params: freePatientQueryParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/page`,
    method: 'GET',
    params,
  }
}

// 详情
export function freePatientsDetail(id: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/detail`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export interface freeDetailTableParams {
  current: number
  size?: number
  param?: string
  doctorId?: any
  treatmentId?: any
}
export function freeDetailTable(params: freeDetailTableParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/appointmentPage`,
    method: 'GET',
    params,
  }
}

export function freeDetailTablePrint(params: freeDetailTableParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/appointmentPagePrint`,
    method: 'GET',
    params,
  }
}

export interface printParams {
  treatmentIdList: string[]
}
export function printData(body: printParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/getPrintData`,
    method: 'POST',
    body,
  }
}

export function freePatientDetailModal(id: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/treatmentDetail`,
    method: 'GET',
    params: { id },
  }
}

// 发布
export function freePatientsPublish(ids: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/publish`,
    method: 'POST',
    params: {
      ids,
    },
  }
}

// 撤回
export function freePatientsRecall(ids: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/recall`,
    method: 'POST',
    params: {
      ids,
    },
  }
}

export interface numParams {
  treatmentId: string
  doctorId?: string
}

// 获取时间
export function num(params:numParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/appointmentCount`,
    method: 'GET',
    params
  }
}

export interface senFreeTreatmentSmsParams {
  address: string
  appointmentTime?: string
  patientId?: string
  phone?: any
  reviceName?: any
  treatmentAppointmentId?: any
  treatmentId?: any
}
// 保存义诊情况
export function senFreeTreatmentSms(body: senFreeTreatmentSmsParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/senFreeTreatmentSms`,
    method: 'POST',
    body,
  }
}

// 完成
export function freePatientsFinish(id: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/tofinish`,
    method: 'GET',
    params: {
      id,
    },
  }
}

export interface freePatientsFinishSubmitParams {
  treatmentCount: number
  costAmount?: number
  returnAmount?: string
  programAmount?: any
  peopleAmount?: any
  remark?: any
  summary?: any
}
// 保存义诊情况
export function freePatientsFinishSubmit(
  body: freePatientsFinishSubmitParams
): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/finish`,
    method: 'POST',
    body,
  }
}

// 详情
export function freeDetail(treatmentId: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/resultDetail`,
    method: 'POST',
    params: {
      treatmentId,
    },
  }
}

// 删除
export function freePatientsDelete(ids: string): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/remove`,
    method: 'POST',
    params: {
      ids,
    },
  }
}

export interface FreePatientsSubmitParams {
  title?: any
  content?: string
  deadline?: string
  address?: string
  phone?: string
}

export function freePatientsSubmit(body: FreePatientsSubmitParams): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/save`,
    method: 'POST',
    body,
  }
}

// 下载
export function freePatientDownLoad(freeId: string): Request {
  return {
    url: `${API_PREFIX}/wechat/getFreeTreatmentQrCode`,
    method: 'GET',
    params: {
      freeId,
    },
  }
}

// 义诊导出
export function getPageExportFn(
  body: any
): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/pageexport`,
    method: 'POST',
    responseType: 'blob',
    body
  }
}
// 详情导出
export function getDetailExportFn(
  params: any
): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/detailexport`,
    method: 'GET',
    responseType: 'blob',
    params
  }
}
// 详情取消
export function getRecallByAppFn(
  id: number
): Request {
  return {
    url: `${API_PREFIX}/adminFreeTreatment/recallByApp?id=${id}`,
    method: 'POST',
  }
}