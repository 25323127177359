/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-16 16:46:42
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 17:22:36
 */
import {
  Button,
  Col,
  Tabs,
  Row,
  TableColumnType,
  Form,
  Space,
  Input,
  Select,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import {
  DateRangeType,
  DateSelect,
  getDateRange,
} from '../../compnents/form/DateSelect'
import { freePatientQueryParams } from '../../services/freePatients'
import {
  getReleaseStateName,
  ReleaseStateCategories,
} from '../../models/health'

export const Query = (props: {
  onValueChange: (queries: freePatientQueryParams) => void
  addArticle: () => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='title' noStyle>
              <Input
                autoComplete='off'
                placeholder='标题'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <DateSelect
              placeholder='发布时间'
              labelPrefix='发布时间-'
              namePrefix='pushTime'
              style={{ width: '8.5rem' }}
              // initialValue={DateRangeType.Today}
              onChange={form.submit}
            />
            <Form.Item noStyle name='state'>
              <Select
                placeholder='发布状态'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {ReleaseStateCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getReleaseStateName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
          <Space>
            <Button
              type='primary'
              onClick={() => {
                props?.addArticle && props?.addArticle()
              }}
            >
              新增文章
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}

export const HealthHotStatisticsQuery = (props: {
  onValueChange: (queries: freePatientQueryParams) => void
}): ReactElement => {
  const [form] = Form.useForm()
  const { Option } = Select

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='title' noStyle>
              <Input
                autoComplete='off'
                placeholder='标题'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>

            <Form.Item noStyle name='state'>
              <Select
                placeholder='发布状态/全部'
                allowClear
                style={{ width: 140 }}
                onChange={() => form.submit()}
              >
                {ReleaseStateCategories?.map((v) => {
                  return (
                    <Option value={v} key={v}>
                      {getReleaseStateName(v)}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
