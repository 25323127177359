import React, { useEffect, useState } from 'react'
import { CaretDownFilled, QuestionCircleTwoTone } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Row,
  Select,
} from 'antd'
import styles from './freePatients.module.css'

import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { getFreeDetail, getFreePatientsFinishSubmit } from './freePatientSlice'

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
}

const firstColumnLayout = {
  labelCol: { flex: '10rem' },
  wrapperCol: { span: 19 },
}

const lastColumnLayout = {
  labelCol: { flex: '10rem' },
  wrapperCol: { span: 20 },
}

const { Option } = Select

const { TextArea } = Input

export const StationOfFreeModal = (props: {
  disabled?: any
  id?: any
  visible: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  const [title, setTitle] = useState('义诊情况')

  const [form] = Form.useForm()

  const [detail, setDetail] = useState<any>()

  const dispatch = useDispatch<RootDispatch>()

  console.log(props?.id, 'id')

  useEffect(() => {
    if (props?.visible) {
      if (props?.id)
        dispatch(getFreeDetail(props?.id))
          .then(unwrapResult)
          .then((v: any) => {
            setDetail(v)
            form.setFieldsValue({
              ...v,
              costAmount:
                v?.costAmount < 0 ? '0.00' : v?.costAmount?.toFixed(2),
              returnAmount:
                v?.returnAmount < 0 ? '0.00' : v?.returnAmount?.toFixed(2),
              programAmount:
                v?.programAmount < 0 ? '0.00' : v?.programAmount?.toFixed(2),
              peopleAmount:
                v?.peopleAmount < 0 ? '0.00' : v?.peopleAmount?.toFixed(2),
              treatmentCount: v?.treatmentCount < 0 ? '0' : v?.treatmentCount,
            })
          })
    }
  }, [props?.visible])

  return (
    <div>
      <Modal
        title={title}
        visible={props.visible}
        okText='确认'
        cancelText='取消'
        width='800px'
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          form.resetFields()
          props.onCancel()
        }}
        footer={null}
      >
        <Form
          autoComplete='off'
          form={form}
          name='basic'
          {...layout}
          onFinish={(fs) => {
            dispatch(
              getFreePatientsFinishSubmit({
                ...fs,
                treatmentId: props?.id,
                id: detail?.id ? detail?.id : undefined,
              })
            )
              .then(unwrapResult)
              .then((v: any) => {
                notification.success({
                  message: '操作成功',
                  duration: 3,
                })
                props?.onOk()
                form.resetFields()
              })
          }}
        >
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item {...firstColumnLayout} label='义诊主题' name='name'>
                <Input.TextArea
                  disabled={props?.disabled}
                  maxLength={20}
                  showCount
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='服务人次'
                name='treatmentCount'
                rules={[
                  {
                    pattern: /^[+]{0,1}(\d+)$/,
                    message: '请输入正整数',
                  },
                ]}
              >
                <Input
                  type='number'
                  disabled={props?.disabled}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.tip}>
              次
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='义诊收入'
                name='costAmount'
                rules={[
                  {
                    pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/,
                    message: '请输入正数',
                  },
                ]}
              >
                <Input
                  type='number'
                  disabled={props?.disabled}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.tip}>
              元
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='义诊回流收入'
                name='returnAmount'
                rules={[
                  {
                    pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/,
                    message: '请输入正数',
                  },
                ]}
              >
                <Input
                  type='number'
                  disabled={props?.disabled}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.tip}>
              元
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='活动费用（专家补贴）'
                name='programAmount'
                rules={[
                  {
                    pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/,
                    message: '请输入正数',
                  },
                ]}
              >
                <Input
                  type='number'
                  disabled={props?.disabled}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.tip}>
              元
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={18}>
              <Form.Item
                {...firstColumnLayout}
                label='活动外出人员（补贴）'
                name='peopleAmount'
                rules={[
                  {
                    pattern: /^(0|[1-9][0-9]*)(\.\d+)?$/,
                    message: '请输入正数',
                  },
                ]}
              >
                <Input
                  type='number'
                  disabled={props?.disabled}
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={6} className={styles.tip}>
              元
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={23}>
              <Form.Item {...lastColumnLayout} label='备注' name='remark'>
                <TextArea disabled={props?.disabled} maxLength={300} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={23}>
              <Form.Item {...lastColumnLayout} label='义诊总结' name='summary'>
                <TextArea disabled={props?.disabled} maxLength={800} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Button
                style={{ float: 'right' }}
                onClick={() => {
                  form.resetFields()
                  props.onCancel()
                }}
              >
                取消
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ float: 'left' }}
              >
                确认
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
