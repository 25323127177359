/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-14 17:59:29
 */
import { Space, Switch, TableColumnType } from 'antd'
import React, { ReactElement, useState } from 'react'
import { getReleaseStateName } from '../../models/health'
import { getGenderName } from '../../models/user'
import { getAge } from '../../utils/StringUtils'

export const columns = (
    onClick: (action: any, v?: any) => void,
    startIndex: number,
): TableColumnType<any>[] => {
    return [
        {
            title: '序号',
            key: 'order',
            align: 'center',
            width: '3rem',
            ellipsis: true,
            render: (_1, _2, index) => `${startIndex + index + 1}`,
        },
        {
            title: '日期',
            dataIndex: 'value',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r) => {
                return r.value ? r.value : '-'
            },
        },
        {
            title: '新增用户',
            dataIndex: 'userCount',
            align: 'center',
            width: '4rem',
            ellipsis: true,
            render: (_, r) => {
                return r.userCount
            },
        },
        {
            title: '新增就诊人',
            dataIndex: 'patientCount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r) => {
                return r.patientCount
            },
        },
        {
            title: '挂号预约数量',
            dataIndex: 'appointCount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r) => {
                return r.appointCount
            },
        },
        {
            title: '活动预约数量',
            dataIndex: 'freeAppointCount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r) => {
                return r.freeAppointCount
            },
        },
        {
            title: '活动参与人数',
            dataIndex: 'treatmentCount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r) => {
                return r?.treatmentCount > 0 ? r?.treatmentCount : '-'
            },
        },
        {
            title: '活动收入',
            dataIndex: 'costAmount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: (_, r: any) => {
                return r?.costAmount > 0
                    ? r?.costAmount?.toFixed(2)
                    : '0.00'
            },
        },
        {
            title: '活动回流收入',
            dataIndex: 'returnAmount',
            align: 'center',
            width: '5rem',
            ellipsis: true,
            render: function getName(_, r): ReactElement {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-all',
                        }}
                    >
                        {r?.returnAmount ? r?.returnAmount?.toFixed(2) : '-'}
                    </div>
                )
            },
        },
        {
            title: '活动费用（专家补贴）',
            dataIndex: 'programAmount',
            align: 'center',
            width: '8rem',
            ellipsis: true,
            render: (_, r: any) => {
                return r?.programAmount > 0
                    ? r?.programAmount?.toFixed(2)
                    : '0.00'
            },
        },
        {
            title: '活动外出人员（补贴）',
            dataIndex: 'peopleAmount',
            align: 'center',
            width: '8rem',
            ellipsis: true,
            render: (_, r: any) => {
                return r?.peopleAmount > 0
                    ? r?.peopleAmount?.toFixed(2)
                    : '0.00'
            },
        },
        {
            title: '在线客服服务数量',
            dataIndex: 'kefuCount',
            align: 'center',
            width: '6rem',
            ellipsis: true,
            render: (_, r) => {
                return r.kefuCount
            },
        }
    ]
}
