/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 09:32:03
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 11:28:36
 */
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { EditableList } from '../../compnents/list/EditableList'
import { useHistory } from 'react-router-dom'

import { getMemberList } from './memberSlice'
import { Query } from './Query'
import styles from './member.module.css'
import { Columns } from './columns'
import { unwrapResult } from '@reduxjs/toolkit'
import { IntegralWriteOffModal } from './IntegralWriteOffModal'

export const MemberList = (): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const history: any = useHistory()

  const [pageLoading, setPageLoading] = useState<any>(false) //表格 loading

  const [queries, setQueries] = useState<any>({ current: 1, size: 10 })

  const [page, setPage] = useState({
    items: [],
    total: 0,
  })

  const [isIntegralWriteOffVisible, setIsIntegralWriteOffVisible] = useState(
    false
  )

  const [id, setId] = useState<any>('')

  const [name, setName] = useState<any>('')

  const [phone, setPhone] = useState<any>('')

  const [points, setPoints] = useState<any>('')

  const getPage = () => {
    setPageLoading(true)
    dispatch(getMemberList({ ...queries }))
      .then(unwrapResult)
      .then((res: any) => {
        setPage({
          items: res.records,
          total: res.total,
        })
      })
      .finally(() => setPageLoading(false))
  }

  useEffect(() => {
    getPage()
  }, [queries])

  return (
    <>
      <div className={styles.content}>
        <Query
          onValueChange={(values) => {
            setQueries({
              ...queries,
              ...values,
              current: 1,
            })
          }}
        />
        <EditableList
          loading={pageLoading}
          rowKey={(v, i) => v.id}
          page={{
            items: page.items,
            current: queries.current,
            size: queries.size,
            total: page.total,
          }}
          onChangePage={(current, size) => {
            setQueries({ ...queries, current, size: size || 10 })
          }}
          columns={Columns((action: any, v: any) => {
            switch (action) {
              case 'detail':
                history.push({
                  pathname: '/admin/memberList/integralDetail',
                  state: {
                    memberId: v.id,
                  },
                })
                break
              case 'setting':
                setIsIntegralWriteOffVisible(true)
                setId(v?.id)
                setName(v?.name)
                setPhone(v?.phone)
                setPoints(v?.points)
                break
            }
          }, queries.size * (queries.current - 1))}
        />
        <IntegralWriteOffModal
          name={name}
          phone={phone}
          points={points}
          id={id}
          visible={isIntegralWriteOffVisible}
          onCancel={() => {
            setIsIntegralWriteOffVisible(false)
            setId('')
            getPage()
          }}
          onOk={() => {
            setIsIntegralWriteOffVisible(false)
            setId('')
            getPage()
          }}
        />
      </div>
    </>
  )
}
