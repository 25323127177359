/*
 * @Descripttion:
 * @version:
 * @Author: leon
 * @email: leon0023@zoho.com.cn
 * @Date: 2022-07-14 14:35:08
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-17 15:09:17
 */
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { getAge } from '../../utils/StringUtils'
import styles from './patientsManagement.module.css'
import { LineCharts } from '../../compnents/lineCharts/LineCharts'
import { DataCard } from '../../compnents/Card/Card'
import { unwrapResult } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../../app/store'
import { setPageLoading } from '../freePatients/freePatientSlice'
import { getEchartsList } from './patientsManagementSlice'
import { getGenderName } from '../../models/user'
import moment from 'moment'
import { DateTimeFormatSimple } from '../../models/datetime'
import noData from './images/noecharts.jpg'

export const PatientMessageModal = (props: {
  message?: any
  id?: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}): ReactElement => {
  const dispatch = useDispatch<RootDispatch>()

  const lineChartsData = [1, 200, 345, 4356, 2345, 2345]

  const dataTwo = [200, 1000, 30, 498, 39, 456]

  const [visibles, setVisibles] = useState('false')

  const [oneParams, setOneParams] = useState<any>({})

  const [oneXData, setOneXData] = useState<any>()

  const [oneYData, setOneYData] = useState<any>()

  const [twoParams, setTwoParams] = useState<any>({})

  const [twoXData, setTwoXData] = useState<any>()

  const [twoYData, setTwoYData] = useState<any>()

  const [threeParams, setThreeParams] = useState<any>({})

  const [threeXData, setThreeXData] = useState<any>()

  const [threeYData, setThreeYData] = useState<any>()

  const [fourParams, setFourParams] = useState<any>({})

  const [fourXData, setFourXData] = useState<any>()

  const [fourYData, setFourYData] = useState<any>()

  const [fiveParams, setFiveParams] = useState<any>({})

  const [fiveXData, setFiveXData] = useState<any>()

  const [fiveOneYData, setFiveOneYData] = useState<any>()

  const [fiveTwoYData, setFiveTwoYData] = useState<any>()
  const labelStyle = {
    style: {
      fontSize: '1rem',
      textAlign: 'left',
      color: '#101010',
      marginBottom: 6,
      marginLeft: '20px',
    } as React.CSSProperties,
  }
  useEffect(() => {
    if (!props?.visible) {
      setOneParams({})
      setTwoParams({})
      setThreeParams({})
      setFourParams({})
      setFiveParams({})
    }
  }, [props?.visible])

  useEffect(() => {
    console.log(oneParams, 'oneParams')
    // 步数
    if (props?.visible && oneParams?.startTime) {
      dispatch(
        getEchartsList({
          ihPatientId: props?.id,
          patientPhonenumber: props?.message?.patientPhonenumber,
          ...oneParams,
          identityCard: props?.message?.identityCard,
          dataType: 1,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setOneXData(
            res?.timeSlice?.map((v: any) => {
              return moment(v).format('MM-DD')
            })
          )
          setOneYData(
            res?.trendChartDataVOList?.map((v: any) => {
              return Number(v.val)
            })
          )
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible, oneParams])

  // 体温
  useEffect(() => {
    console.log(twoParams, 'oneParams')

    if (props?.visible && twoParams?.startTime) {
      dispatch(
        getEchartsList({
          ihPatientId: props?.id,
          patientPhonenumber: props?.message?.patientPhonenumber,
          ...twoParams,
          identityCard: props?.message?.identityCard,
          dataType: 2,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setTwoXData(
            res?.timeSlice?.map((v: any) => {
              return moment(v).format('MM-DD')
            })
          )
          setTwoYData(
            res?.trendChartDataVOList?.map((v: any) => {
              return Number(v.val)
            })
          )
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible, twoParams])

  // 体重
  useEffect(() => {
    console.log(threeParams, 'threeParams')

    if (props?.visible && threeParams?.startTime) {
      dispatch(
        getEchartsList({
          ihPatientId: props?.id,
          patientPhonenumber: props?.message?.patientPhonenumber,
          ...threeParams,
          identityCard: props?.message?.identityCard,
          dataType: 3,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setThreeXData(
            res?.timeSlice?.map((v: any) => {
              return moment(v).format('MM-DD')
            })
          )
          setThreeYData(
            res?.trendChartDataVOList?.map((v: any) => {
              return Number(v.val)
            })
          )
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible, threeParams])

  // 心率
  useEffect(() => {
    console.log(fourParams, 'fourParams')
    if (props?.visible && fourParams?.startTime) {
      dispatch(
        getEchartsList({
          ihPatientId: props?.id,
          patientPhonenumber: props?.message?.patientPhonenumber,
          ...fourParams,
          identityCard: props?.message?.identityCard,
          dataType: 4,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          setFourXData(
            res?.timeSlice?.map((v: any) => {
              return moment(v).format('MM-DD')
            })
          )
          setFourYData(
            res?.trendChartDataVOList?.map((v: any) => {
              return Number(v.val)
            })
          )
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible, fourParams])

  // 血压
  useEffect(() => {
    console.log(fiveParams, 'fiveParams')
    if (props?.visible && fiveParams?.startTime) {
      dispatch(
        getEchartsList({
          ihPatientId: props?.id,
          patientPhonenumber: props?.message?.patientPhonenumber,
          ...fiveParams,
          identityCard: props?.message?.identityCard,
          dataType: 5,
        })
      )
        .then(unwrapResult)
        .then((res: any) => {
          const val = res?.trendChartDataVOList?.map((v: any) => {
            return v?.val?.split('-')
          })
          console.log(val, '11111111111111')

          setFiveXData(
            res?.timeSlice?.map((v: any) => {
              return moment(v).format('MM-DD')
            })
          )
          setFiveOneYData(
            val?.map((value: any) => {
              return Number(value?.[0])
            })
          )
          setFiveTwoYData(
            val?.map((value: any) => {
              return Number(value?.[1])
            })
          )
        })
        .finally(() => setPageLoading(false))
    }
  }, [props?.visible, fiveParams])

  console.log(fiveTwoYData, 'fiveTwoYData')
  

  useEffect(() => {
    console.log(props?.visible, 'props?.visible')
    if (props?.visible) {
      setVisibles('true')
    } else {
      setVisibles('false')
    }
  }, [props?.visible])

  useEffect(() => {
    console.log(oneYData, 'oneYData')
  }, [oneYData])

  return (
    <div>
      <Modal
        title='就诊人信息'
        visible={props.visible}
        onOk={() => {
          props.onOk()
        }}
        onCancel={() => {
          props.onCancel()
        }}
        footer={null}
        width={1085}
      >
        <Row gutter={20}>
          <Col {...labelStyle}>
            姓名：{props?.message?.name + '  '}
            {getGenderName(props?.message?.sex) + '  '}
            {props?.message?.ageYear > 0
              ? props?.message?.ageYear + '岁'
              : props?.message?.ageMonth + '个月'}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col {...labelStyle}>身份证号：{props?.message?.identityCard}</Col>
        </Row>
        <Row gutter={20}>
          <Col {...labelStyle}>
            手机号：{props?.message?.phone}
          </Col>
        </Row>
        <div className={styles.visibleContent}>
          <DataCard
            changeTime={(v) => {
              console.log(v, 'changeTimechangeTimechangeTime')
              setOneParams({
                startTime: moment(v?.[0])?.format(DateTimeFormatSimple),
                endTime: moment(v?.[1])?.format(DateTimeFormatSimple),
              })
            }}
            visible={visibles}
            title='步数'
            style={{ marginRight: '31px', marginBottom: '16px' }}
          >
            {oneYData?.length ? (
              <LineCharts
                xData={oneXData}
                data={oneYData}
                title='单位（步）'
                style={{ height: '227px', cursor: 'pointer' }}
              ></LineCharts>
            ) : (
              <img
                src={noData}
                alt=''
                style={{ width: '227px', height: '227px' }}
              ></img>
            )}
          </DataCard>
          <DataCard
            changeTime={(v) => {
              console.log(v, 'changeTime')
              setTwoParams({
                startTime: moment(v?.[0])?.format(DateTimeFormatSimple),
                endTime: moment(v?.[1])?.format(DateTimeFormatSimple),
              })
            }}
            title='体温'
            style={{ marginBottom: '16px' }}
            visible={visibles}
          >
            {twoXData?.length ? (
              <LineCharts
                xData={twoXData}
                data={twoYData}
                title='单位（℃）'
                style={{ height: '227px', cursor: 'pointer' }}
              ></LineCharts>
            ) : (
              <img
                src={noData}
                alt=''
                style={{ width: '227px', height: '227px' }}
              ></img>
            )}
          </DataCard>
          <DataCard
            changeTime={(v) => {
              console.log(v, 'changeTime')
              setThreeParams({
                startTime: moment(v?.[0])?.format(DateTimeFormatSimple),
                endTime: moment(v?.[1])?.format(DateTimeFormatSimple),
              })
            }}
            title='体重'
            visible={visibles}
            style={{ marginRight: '31px', marginBottom: '16px' }}
          >
            {threeXData?.length ? (
              <LineCharts
                xData={threeXData}
                data={threeYData}
                title='单位（kg）'
                style={{ height: '227px', cursor: 'pointer' }}
              ></LineCharts>
            ) : (
              <img
                src={noData}
                alt=''
                style={{ width: '227px', height: '227px' }}
              ></img>
            )}
          </DataCard>
          <DataCard
            changeTime={(v) => {
              console.log(v, 'changeTime')
              setFourParams({
                startTime: moment(v?.[0])?.format(DateTimeFormatSimple),
                endTime: moment(v?.[1])?.format(DateTimeFormatSimple),
              })
            }}
            title='心率'
            style={{ marginBottom: '16px' }}
            visible={visibles}
          >
            {fourXData?.length ? (
              <LineCharts
                xData={fourXData}
                data={fourYData}
                title='单位（bpm）'
                style={{ height: '227px', cursor: 'pointer' }}
              ></LineCharts>
            ) : (
              <img
                src={noData}
                alt=''
                style={{ width: '227px', height: '227px' }}
              ></img>
            )}
          </DataCard>
          <DataCard
            changeTime={(v) => {
              console.log(v, 'changeTime')
              setFiveParams({
                startTime: moment(v?.[0])?.format(DateTimeFormatSimple),
                endTime: moment(v?.[1])?.format(DateTimeFormatSimple),
              })
            }}
            title='血压'
            style={{ marginBottom: '16px' }}
            visible={visibles}
          >
            {fiveTwoYData?.length && fiveOneYData?.length ? (
              <LineCharts
                tag={true}
                dataTwo={fiveTwoYData}
                xData={fiveXData}
                data={fiveOneYData}
                title='单位（mmHg）'
                style={{ height: '227px', cursor: 'pointer' }}
              ></LineCharts>
            ) : (
              <img
                src={noData}
                alt=''
                style={{ width: '227px', height: '227px' }}
              ></img>
            )}
          </DataCard>
        </div>
      </Modal>
    </div>
  )
}
