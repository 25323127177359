/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 16:00:30
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-06 14:19:46
 */
import { Button, Col, Tabs, Row, Form, Space, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { ReactElement, useEffect } from 'react'
import { freePatientQueryParams } from '../../services/freePatients'

export const Query = (props: {
  onValueChange: (queries: freePatientQueryParams) => void
}): ReactElement => {
  const [form] = Form.useForm()

  return (
    <>
      <Form
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        form={form}
        onFinish={(vs) => props.onValueChange(vs)}
      >
        <Row
          style={{ marginBottom: 10, width: '100%' }}
          align='middle'
          justify='space-between'
        >
          <Space>
            <Form.Item name='name' noStyle>
              <Input
                autoComplete='off'
                placeholder='用户名/手机号'
                style={{ width: 300 }}
                onChange={() => form.submit()}
                prefix={<SearchOutlined />}
                allowClear
              />
            </Form.Item>
            <Button
              type='primary'
              onClick={() => props.onValueChange(form.getFieldsValue())}
            >
              查询
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  )
}
