/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import EChartsReact from 'echarts-for-react'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'


interface pieData {
  name: string
  value: number
}

export interface PieChartsProps {
  PieSeriesName?: string
  PieData?: pieData[]
  startIndex?: number
  show?: boolean
  roseType?: string
  radius?: string
  style?: React.CSSProperties
}

export const PieCharts: React.FC<PieChartsProps> = ({
  PieSeriesName = '',
  PieData,
  radius = "60%",
  roseType = "",
  show = false,
  startIndex = 0,
  style,
}): ReactElement => {
  const [option, setOption] = useState<any>()
  const List = [
    "#006FFF",
    "#0D85D5",
    "#2E5BEE",
    "#66AFEE",
    "#2929D5",
    "#099297",
    "#C8C852",
    "#2248DF",
    "#0D9CDC",
    "#5031CF",
    "#2B61DF",
  ]
  useEffect(() => {
    if (PieData) {
      const PieOption = {
        tooltip: {
          trigger: "item",
          itemStyle: {
            fontSize: '20px',
          },
          confine: true,
        },
        legend: {
          show: show,
          orient: "vertical",
          x: '2%',
          y: 'center',
          itemWidth: 30,
          itemHeight: 16,
          itemGap: 5,
          textStyle: {
            align: "right",
            fontSize: 14,
            // color: "#fff",
            rich: {
              percent: {
                padding: [0, 0, 0, 10],
              },
            },
          },
        },
        series: [
          {
            name: PieSeriesName,
            type: "pie",
            radius: radius,
            roseType: roseType,
            center: show ? ["60%", "50%"] : "50%",
            data: PieData,
            label: {
              // shadowColor: "#fff",
              // color: "#fff",
              fontSize: 14
            },
            textStyle: {
              // color: "#fff",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,.5)",
              },
              textStyle: {
                // color: "#fff",
              },
            },
            color: List.slice(startIndex % List.length).concat(
              List.slice(0, startIndex % List.length)
            ),
          },
        ],
      }
      setOption(PieOption)
    }
  }, [PieData])
  return (
    <>
      {option && <EChartsReact option={option} style={style} notMerge={true} />}
    </>
  )
}
