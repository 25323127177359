/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2022-12-26 15:31:12
 * @LastEditors: linxi
 * @LastEditTime: 2022-12-27 17:19:22
 */
import React, { ReactElement, useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styles from './maintenanceWorkOrder.module.css'
interface filenameListModalProps {
  filename?: any
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
export const FilenameListModal = ({
  filename,
  visible,
  onCancel,
  onOk,
}: filenameListModalProps): ReactElement => {
  const [filenameList, setFilenameList] = useState<any>()

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (visible) {
      setFilenameList(filename?.split(','))
    }
  }, [visible])

  useEffect(() => {
    setIndex(0)
    console.log(filenameList, filenameList?.length - 1, index, 'filenameList')
  }, [filenameList])

  return (
    <Modal
      title='图片/视频'
      width={1000}
      visible={visible}
      onCancel={() => {
        onCancel()
      }}
      centered
      footer={null}
      maskStyle={{
        overflow: 'hidden',
      }}
    >
      <>
        <div className={styles.modalContent}>
          {index != 0 ? (
            <div className={styles.iconContent}>
              <div
                className={styles.iconContentBack}
                onClick={() => {
                  setIndex(index - 1)
                }}
              >
                <LeftOutlined style={{ fontSize: '26px' }} />
              </div>
            </div>
          ) : (
            <div className={styles.iconContent}>
              <div className={styles.iconContentBack}>
                <LeftOutlined style={{ fontSize: '26px' }} />
              </div>
            </div>
          )}

          {filenameList?.[index]?.includes('png') ||
          filenameList?.[index]?.includes('tiff') ||
          filenameList?.[index]?.includes('jpg') ||
          filenameList?.[index]?.includes('jpeg') ||
          filenameList?.[index]?.includes('bmp') ||
          filenameList?.[index]?.includes('heic') ||
          filenameList?.[index]?.includes('webp') ? (
            <>
              <div className={styles.modalContentImg}>
                <img
                  src={filenameList?.[index]}
                  alt=''
                  style={{ height: '600px' }}
                ></img>
              </div>
            </>
          ) : filenameList?.[index]?.includes('mov') ||
            filenameList?.[index]?.includes('mp4') ||
            filenameList?.[index]?.includes('webm') ||
            filenameList?.[index]?.includes('mp3') ||
            filenameList?.[index]?.includes('flv') ? (
            <>
              <div className={styles.modalContentImg}>
                <video
                  controls
                  autoPlay
                  loop
                  muted
                  src={filenameList?.[index]}
                  style={{ height: '600px' }}
                ></video>
              </div>
            </>
          ) : (
            <></>
          )}

          {index != filenameList?.length - 1 ? (
            <div
              className={styles.iconContent}
              onClick={() => {
                setIndex(index + 1)
              }}
            >
              <div className={styles.iconContentBack}>
                <RightOutlined style={{ fontSize: '26px' }} />
              </div>
            </div>
          ) : (
            <div className={styles.iconContent}>
              <div className={styles.iconContentBack}>
                <RightOutlined style={{ fontSize: '26px' }} />
              </div>
            </div>
          )}
        </div>
        <div className={styles.bottomNum}>
          {index + 1}/{filenameList?.length}
        </div>
      </>
    </Modal>
  )
}
