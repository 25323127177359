/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-06-05 14:31:36
 * @LastEditors: linxi
 * @LastEditTime: 2023-06-05 15:13:04
 */
import { TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'

export const Columns = (
  onClick: (v: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      width: '3rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '昵称',
      dataIndex: 'name',
      align: 'center',
      width: '15rem',
      ellipsis: true,
      key: 'treatmentDate',
      // render: (_, r) => {
      //   return r.title ? r.title : '-'
      // },
      render: function Title(_, r): ReactElement {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '手机号',
      key: 'phone',
      width: '14rem',
      ellipsis: true,
      align: 'center',
      dataIndex: 'content',
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },

    {
      title: '积分换礼',
      dataIndex: 'reason',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      key: 'points',
      render: (_, r) => {
        return r.reason ? r.reason : '-'
      },
    },
    {
      title: '核销积分',
      dataIndex: 'value',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      key: 'address',
      render: (_, r) => {
        return r.value ? r.value : '-'
      },
    },
    {
      title: '核销时间',
      dataIndex: 'createTime',
      width: '10rem',
      ellipsis: true,
      align: 'center',
      key: 'address',
      render: (_, r) => {
        return r.createTime ? r.createTime : '-'
      },
    },
  ]
}
