/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-07-10 10:40:31
 * @LastEditors: linxi
 * @LastEditTime: 2023-07-10 15:33:16
 */
import { API_PREFIX } from './constant'
import { ContentType, MultiPartFormData, Request } from './request'

export interface MaintenanceWorkOrderPrams {
  current: number
  size: number
  name?: any
}

// 列表
export function maintenanceWorkOrderList(params: MaintenanceWorkOrderPrams): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheet/page`,
    method: 'GET',
    params,
  }
}

// 详情
export function maintenanceWorkOrderDetail(id: any): Request {
  return {
    url: `${API_PREFIX}/ihtenantsheet/detail`,
    method: 'GET',
    params: {
      id,
    },
  }
}
