/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-01-04 09:45:53
 * @LastEditors: linxi
 * @LastEditTime: 2023-03-16 16:50:06
 */

import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootThunkApi } from '../../app/store'
import { sendAsync } from '../../app/applicationSlice'
import * as DT from '../../services/health'

// 文章列表
export const getHealthList = createAsyncThunk<
  void,
  DT.HealthListPrams,
  RootThunkApi<void>
>('clinic/getHealthList', async (params, api) => {
  return sendAsync(DT.healthList(params), api)
})


// 列表
export const getHealthHotList = createAsyncThunk<
  void,
  DT.HealthListPrams,
  RootThunkApi<void>
>('clinic/getHealthList', async (params, api) => {
  return sendAsync(DT.healthHotList(params), api)
})


// 详情
export const getHealthDetail = createAsyncThunk<void, any, RootThunkApi<void>>(
  'clinic/getHealthDetail',
  async (params, api) => {
    return sendAsync(DT.healthDetail(params), api)
  }
)

// 保存
export const getHealthEditSubmit = createAsyncThunk<
  void,
  DT.HealthEditPrams,
  RootThunkApi<void>
>('clinic/getHealthEditSubmit', async (params, api) => {
  return sendAsync(DT.healthEditSubmit(params), api)
})

// 撤回,发布
export const getChangeStateSubmit = createAsyncThunk<
  void,
  DT.changeStateParams,
  RootThunkApi<void>
>('clinic/getReservationChannelUpload', async (params, api) => {
  return sendAsync(DT.changeStateSubmit(params), api)
})

// 删除
export const getDeleteSubmit = createAsyncThunk<
  void,
  DT.deleteParams,
  RootThunkApi<void>
>('clinic/getReservationChannelUpload', async (params, api) => {
  return sendAsync(DT.deleteSubmit(params), api)
})


