/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2023-03-17 15:59:04
 * @LastEditors: linxi
 * @LastEditTime: 2023-04-10 14:31:43
 */
import { Space, Switch, TableColumnType } from 'antd'
import moment from 'moment'
import React, { ReactElement, useState } from 'react'

type ActionType = 'lookDetail'

export const columns = (
  onClick: (action: ActionType, v: any, state?: any) => void,
  startIndex: number
): TableColumnType<any>[] => {
  return [
    {
      title: '序号',
      key: 'order',
      align: 'center',
      width: '4rem',
      ellipsis: true,
      render: (_1, _2, index) => `${startIndex + index + 1}`,
    },
    {
      title: '用户名',
      dataIndex: 'name',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.name ? r.name : '-'
      },
    },
    {
      title: '身份证号',
      dataIndex: 'identityCard',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.identityCard ? r.identityCard : '-'
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.phone ? r.phone : '-'
      },
    },
    {
      title: '会员',
      dataIndex: 'count',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, { memberInfo }) => {
        return memberInfo?.memberLevelName ? memberInfo?.memberLevelName : '-'
      },
    },
    {
      title: '有效期',
      dataIndex: 'count',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, { memberInfo }) => {
        return memberInfo?.levelTag == 0
          ? '永久'
          : memberInfo?.endTime
          ? moment(memberInfo?.endTime).format('YYYY/MM/DD')
          : '-'
      },
    },
    {
      title: '预约次数',
      dataIndex: 'count',
      align: 'center',
      width: '8rem',
      ellipsis: true,
      render: (_, r) => {
        return r.count
      },
    },
    {
      title: '健康数据',
      key: 'actions',
      width: '6rem',
      align: 'center',
      render: function UserSettings(_, u): ReactElement {
        return (
          <>
            <a
              onClick={() => onClick('lookDetail', u)}
              style={{ marginRight: '4px' }}
            >
              查看
            </a>
          </>
        )
      },
    },
  ]
}
