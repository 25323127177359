import { PrintTemplate, PrintTemplateCategory } from "../models/print";
import { ADMIN_API_PREFIX, API_PREFIX } from "./constant";
import { ContentType, MultiPartFormData, Request } from './request'

export interface GetPrintTemplateListParams {
  current?: number
  size?: number
  state?: number
  tenantId?: string
  templateCategory?: PrintTemplateCategory
}

export function getPrintTemplateList(
  params: GetPrintTemplateListParams
): Request {
  return {
    url: params.tenantId
      ? `${ADMIN_API_PREFIX}/printtemplate/list`
      : `${API_PREFIX}/blade-user/tenantprinttemplate/list`,
    method: 'GET',
    params: {
      ...params,
    },
  }
}

export function getTenantPrintTemplateList(tenantId: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/list`,
    method: 'GET',
    params: {
      current: 1,
      size: 1000,
      tenantId,
    },
  }
}

export interface SelectPrintTemplateParams {
  templateCategory?: PrintTemplateCategory
}

export function selectPrintTemplate(
  params: SelectPrintTemplateParams
): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/selectTemplate`,
    method: 'POST',
    body: params,
  }
}

export function assginPrintTemplate(template: PrintTemplate): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/batchsave`,
    method: 'POST',
    body: [template],
  }
}

export function removePrintTemplate(id: string): Request {
  return {
    url: `${ADMIN_API_PREFIX}/tenant/print/template/batchremove`,
    method: 'POST',
    body: {
      ids: [id],
    },
  }
}

export function setPrintTemplateState(template: PrintTemplate): Request {
  return {
    url: `${API_PREFIX}/blade-user/tenantprinttemplate/update`,
    method: 'POST',
    body: template,
  }
}
// 删除模板
export interface deleteTemplateFnParams {
  id?: string
  file?: any
}
export function deleteTemplateFn(params: deleteTemplateFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/printtemplate/removeTemplatePreviewByid`,
    method: 'GET',
    params,
  }
}

// 获取预览模板路劲
export function getImgUrlFn(params: deleteTemplateFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/printtemplate/getTemplateByid`,
    method: 'GET',
    params,
  }
}
// 上传预览图
export function getUploadImgFn(params: deleteTemplateFnParams): Request {
  return {
    url: `${API_PREFIX}/blade-administer/printtemplate/uploadTemplatePreview`,
    headers: {
      [ContentType]: MultiPartFormData,
    },
    method: 'POST',
    params,
  }
}

export interface GetPrintSourceParams {
  templateCode: string;
  leftOffset: number;
  topOffset: number;
  registrationId?: string;
  treatmentId?: string;
  recipeId?: string;
  paymentId?: string;
  preview?: boolean;
  printer?: string;
}

export function getPrintSource(params: GetPrintSourceParams): Request {
  return {
    url: `${API_PREFIX}/blade-outpatient/lodopprint/doPrint`,
    method: "POST",
    body: params,
  };
}
